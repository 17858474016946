import {
  ArchiveIcon,
  ForbiddenIcon,
  LockOpenIcon,
  EditIcon,
} from '@nimbus-ds/icons';
import { Status as StatusEnum } from '@tiendanube/common/src/enums';
import { useNavegate } from 'App/hooks';
import { ActionProp } from 'commons/components';
import { useAsyncFunc } from 'commons/hooks';
import { useHasPermission } from 'domains/Auth/hooks';
import { useIsEditOrdersEnabled } from 'domains/Orders/Orders/hooks';
import { trackingOrderEditClick } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import useBuildPrintLabelAction from '../usePrintLabelAction/useBuildPrintLabelAction';

// eslint-disable-next-line max-statements
const useOrderActions = (
  orderDetails,
  openModal,
  onArchiveSuccess,
  onOpenSuccess,
  openOrder,
  archiveOrder,
  onError,
) => {
  const t = useTranslationOrders();

  const isOrderEditEnabled = useIsEditOrdersEnabled();
  const { goTo } = useNavegate();
  const canCancelOrder = useHasPermission('cancel_order');
  const canReopenOrder = useHasPermission('reopen_order');

  const [handleArchiveOrder] = useAsyncFunc(
    async () => archiveOrder(),
    onArchiveSuccess,
    onError,
  );

  const [handleOpenOrder] = useAsyncFunc(
    async () => openOrder(),
    onOpenSuccess,
    onError,
  );

  const printLabelAction = useBuildPrintLabelAction(orderDetails, t);

  const getActions = (): ActionProp[] => {
    const isOpen = orderDetails?.status === StatusEnum.OPEN;

    if (isOpen) {
      return [
        ...(canCancelOrder
          ? [
              {
                children: `${t('detail.action.cancel')}`,
                onClick: openModal,
                icon: ForbiddenIcon,
              },
            ]
          : []),
        {
          children: `${t('detail.action.archive')}`,
          onClick: handleArchiveOrder,
          icon: ArchiveIcon,
        },
      ];
    }

    return canReopenOrder
      ? [
          {
            children: `${t('detail.action.reOpen')}`,
            onClick: handleOpenOrder,
            icon: LockOpenIcon,
          },
        ]
      : [];
  };

  const isEditOrderButtonDisabled =
    orderDetails?.inconsistentProducts ||
    (orderDetails?.fulfillmentOrders
      ? !orderDetails.fulfillmentOrders.some(
          (fulfillment) => fulfillment.status === StatusEnum.UNPACKED,
        )
      : orderDetails?.fulfillment.status !== StatusEnum.UNPACKED);

  const editOrderAction: ActionProp[] =
    isOrderEditEnabled && orderDetails?.status === StatusEnum.OPEN
      ? [
          {
            children: t(`detail.editOrder`),
            onClick: () => {
              trackingOrderEditClick();
              goTo(`/orders/${orderDetails.id}/edit`);
            },
            icon: EditIcon,
            disabled: isEditOrderButtonDisabled,
            tooltip: isEditOrderButtonDisabled
              ? orderDetails.inconsistentProducts
                ? t('editOrders.disabledTooltipInconsitentOrder')
                : t('editOrders.disabledTooltip')
              : undefined,
          },
        ]
      : [];

  const getHeaderActionsDesktop = (): ActionProp[] => {
    const baseActions = getActions();

    return [...editOrderAction, ...printLabelAction, ...baseActions];
  };

  const actions = getHeaderActionsDesktop();
  return {
    actions,
  };
};

export default useOrderActions;
