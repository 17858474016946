import * as Yup from 'yup';
import { Country } from '@tiendanube/common/src/enums';
import { validateCountryZipCode } from 'domains/Shipping/ShippingMethods/shippingService/shippingService';
import {
  COUNTRY_WITH_MANDATORY_ZIPCODE,
  COUNTRY_WITH_OPTIONAL_LOCALITY,
  ZIPCODE_VALUE_LENGTH,
} from '../../constants';

export const validationSchema = Yup.object().shape({
  locationName: Yup.string().required('locations.form.errors.name'),
  document: Yup.string(),
  zipcode: Yup.string()
    .when('country', (code, schema) => {
      if (code in COUNTRY_WITH_MANDATORY_ZIPCODE)
        return schema.required('locations.form.errors.zipcode');
      return schema;
    })
    .test(
      'zipcode-is-valid',
      'locations.form.errors.zipcodeInvalid',
      async (value, obj) => {
        if (!value && !(obj.parent.country in COUNTRY_WITH_MANDATORY_ZIPCODE)) {
          return true;
        }
        try {
          if (
            obj.parent.country === COUNTRY_WITH_MANDATORY_ZIPCODE[Country.BR]
          ) {
            return value?.length === ZIPCODE_VALUE_LENGTH;
          }
          if (value && obj.parent.country in COUNTRY_WITH_MANDATORY_ZIPCODE) {
            const isValid = await validateCountryZipCode(
              value,
              obj.parent.country,
            );
            return isValid;
          }
          return true;
        } catch (error) {
          return false;
        }
      },
    ),
  province: Yup.string(),
  city: Yup.string().required('locations.form.errors.city'),
  locality: Yup.string()
    .nullable()
    .test('localityRequired', 'locations.form.errors.locality', (_, obj) => {
      if (obj.parent.country in COUNTRY_WITH_OPTIONAL_LOCALITY) return true;
      return obj.parent.locality;
    }),
  street: Yup.string()
    .nullable()
    .test(
      'streetRequired',
      'locations.form.errors.street',
      (_, obj) => obj.parent.street,
    ),
  number: Yup.string().nullable().required('locations.form.errors.number'),
  complement: Yup.string(),
});
