import { CategoryDetailsResponseDto } from '@tiendanube/common';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  GoogleShoppingCategories,
  SeoCategories,
} from 'domains/Catalog/Products/pages/components/Categories/components';
import useCategory, {
  ImageInterface,
} from '../../hooks/useCategory/useCategory';
import FeaturedImageCard from '../FeaturedImageCard';
import InformationEditCategoryCard from '../InformationEditCategoryCard';
import SectionCategoriesMetafieldsExisting from '../SectionCategoriesMetafieldsExisting';

interface FormEditCategoryProps {
  values: CategoryDetailsResponseDto;
  errors: Partial<Record<string, string>>;
  setFieldValue: (name: string, value: any) => void;
  categoryOrSubcategory: 'category' | 'subcategory';
  handleOnSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;
  id: string;
}
function FormEditCategory({
  values,
  errors,
  setFieldValue,
  categoryOrSubcategory,
  handleOnSubmit,
  id,
}: FormEditCategoryProps) {
  const t = useTranslationCatalog();
  const { goBack } = useNavegate();

  const { isSaving, uploadImage } = useCategory(id);
  useScrollToError(errors);

  const titleInformationEdit = t(
    `categories.detail.categoryInformation.${categoryOrSubcategory}`,
  );

  const handleOnChange = ({ name, value }) => {
    setFieldValue(name, value);
  };

  const handleChangeImage = (image: ImageInterface | null) => {
    setFieldValue('image', image);
  };

  const handleChangeMetafields = (metafields) => {
    setFieldValue('metafields', metafields);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack column spacing="loose" align="stretch">
        <InformationEditCategoryCard
          title={titleInformationEdit}
          values={values}
          errors={errors}
          handleChange={handleOnChange}
        />
        <FeaturedImageCard
          image={values.image}
          uploadImage={uploadImage}
          onChangeImage={handleChangeImage}
        />
        <GoogleShoppingCategories
          categoryId={id}
          name={values.name}
          description={values.description}
          googleShoppingCategory={values.googleShoppingCategory}
          handleChange={handleOnChange}
          errors={errors}
        />
        <SeoCategories
          seoTitle={values.seoTitle}
          seoDescription={values.seoDescription}
          categoryId={id}
          name={values.name}
          description={values.description}
          handle={values.handle}
          handleChange={handleOnChange}
          errors={errors}
        />
        <SectionCategoriesMetafieldsExisting
          selecteds={values.metafields?.internals || []}
          apiSelecteds={values.metafields?.fromApi || []}
          onChange={handleChangeMetafields}
          id={id}
        />
        <CancelAndConfirmButtons
          confirmText={t('categories.detail.header.save')}
          cancelText={t('categories.detail.header.cancel')}
          onCancel={goBack}
          isConfirmDisabled={isSaving}
          isLoading={isSaving}
          type="submit"
        />
      </Stack>
    </form>
  );
}

export default FormEditCategory;
