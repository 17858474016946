import { Box, Card } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useAutoFocus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { PromotionFormProps } from '../PromotionForm';

interface NameCardProps
  extends Pick<PromotionFormProps, 'values' | 'onChange'> {
  disabled: boolean;
}

export function NameCard({
  values: { name },
  disabled,
  onChange,
}: Readonly<NameCardProps>) {
  const t = useTranslationMarketing('promotions.settingsPage');

  const { ref } = useAutoFocus();

  return (
    <Card>
      <Card.Header title={t('name')} />
      <Card.Body>
        <Box
          display="flex"
          flexDirection="column"
          gap="4"
          marginTop="4"
          marginBottom="1"
          ref={ref}
        >
          <FormField.Input
            name="name"
            value={name}
            onChange={onChange}
            showHelpText
            helpText={t('info')}
            disabled={disabled}
          />
        </Box>
      </Card.Body>
    </Card>
  );
}
