import {
  FulfillmentOrdersResponseDto,
  OrderDetailsResponseDto,
  OrderFulfillRequestDto,
} from '@tiendanube/common';
import {
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
  Status,
} from '@tiendanube/common/src/enums';
import { Text, Stack, DataList, Button } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import { useModal } from 'commons/hooks';
import { calculateDateRange } from 'commons/utils/date';
import { PrintManagerDropdown } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/components';
import ConfirmTrackingNumModal from 'domains/Orders/components/ComfirmTrackingNumModal';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import useFulfillmentActionButton from '../../hooks/useFulfillmentActionButton';
import TrackingNumberInfo from '../TrackingNumberInfo';
import './OrderSummaryDelivery.scss';

export const notifyFulfillmentDefault = {
  shippingTrackingNum: '',
  sendNotification: true,
};

interface OrderSummaryDeliveryProps {
  orderDetails: OrderDetailsResponseDto;
  packOrder: () => void;
  fulfillOrder: (order: OrderFulfillRequestDto) => void;
  isLoading: boolean;
  fulfillmentOrder?: FulfillmentOrdersResponseDto;
}

const nuvem_envio_available = ['Nuvem Envio']; // TODO: next will be for Argentina

function OrderSummaryDelivery({
  orderDetails: {
    id: orderId,
    shipping,
    fulfillment: {
      status,
      preference: {
        option,
        weight,
        shippingMinDays,
        shippingMaxDays,
        type,
        trackingNumber,
        deliveryAddress,
      },
      isShippable,
      pickupDetail,
    },
    urlTrackingCode,
    status: orderStatus,
    isShippablePickup,
    payment,
  },
  packOrder,
  fulfillOrder,
  isLoading,
  fulfillmentOrder,
}: OrderSummaryDeliveryProps): JSX.Element {
  const t = useTranslationOrders();

  const { isDesktop } = useResponsive();

  const [isShow, openModal, closeModal] = useModal();

  const shippingStatus = fulfillmentOrder?.status || status;
  const shippingType = fulfillmentOrder?.shipping?.type || type;

  const { secondaryButton, handleClickFulfill } = useFulfillmentActionButton({
    fulfillOrder,
    packOrder,
    openModal,
    closeModal,
    status: shippingStatus,
    type: shippingType as FulfillmentPreferenceTypeEnum,
    isLoading,
    orderStatus,
    isShippablePickup,
  });

  const getShippingDays = () => {
    if (fulfillmentOrder) {
      const dateRange = calculateDateRange(
        fulfillmentOrder.createdAt,
        fulfillmentOrder.shipping.minDeliveryDate ?? null,
        fulfillmentOrder.shipping.maxDeliveryDate ?? null,
      );
      return {
        minDays: dateRange.min_days,
        maxDays: dateRange.max_days,
      };
    } else {
      return {
        minDays: shippingMinDays,
        maxDays: shippingMaxDays,
      };
    }
  };

  const { minDays: shippingMinDaysFinal, maxDays: shippingMaxDaysFinal } =
    getShippingDays();

  const checkShippingDays = (): boolean =>
    shippingMinDaysFinal !== undefined &&
    shippingMinDaysFinal > 0 &&
    shippingMaxDaysFinal !== undefined &&
    shippingMaxDaysFinal > 0;

  const hasShippingDays: boolean = checkShippingDays();
  const isShippingDaysRange = shippingMinDaysFinal !== shippingMaxDaysFinal;
  const showPickUpAdress = pickupDetail?.address;

  const [trackingCode, trackingUrl] = fulfillmentOrder
    ? [fulfillmentOrder.trackingInfo.code, fulfillmentOrder.trackingInfo.url]
    : [trackingNumber, urlTrackingCode];
  const shippingOption = fulfillmentOrder
    ? fulfillmentOrder.shipping.option.name
    : option;

  const totalWeight = fulfillmentOrder ? fulfillmentOrder.totalWeight : weight;

  const getOptionOrShippingOption = () => shippingOption || option;

  const getTranslationOrOption = () =>
    shippingOption
      ? t('cardFulfillment.pickerFrom', { from: shippingOption })
      : option;

  const getPickupOptionText = () =>
    isShippablePickup ? getOptionOrShippingOption() : getTranslationOrOption();

  const getNonPickupOptionText = () =>
    fulfillmentOrder?.shipping?.carrier?.name &&
    shippingOption &&
    !shippingOption.includes(fulfillmentOrder.shipping.carrier.name)
      ? `${fulfillmentOrder.shipping.carrier.name} - ${shippingOption}`
      : shippingOption;

  const getShippingOptionText = () => {
    if (!fulfillmentOrder) return shippingOption;
    if (shippingType === FulfillmentPreferenceTypeEnum.PICKUP)
      return getPickupOptionText();
    if (shippingType !== FulfillmentPreferenceTypeEnum.NON_SHIPPABLE)
      return getNonPickupOptionText();
    return shippingOption;
  };

  return (
    <>
      <div className="summaryDelivery">
        <DataList.Row id="bottom">
          <DataList.Cell width="fill">
            <Stack column align="stretch" spacing="base">
              <Stack>
                {isShippable && (
                  <Stack.Item>
                    <Text block bold appearance="secondary">
                      {getShippingOptionText()}
                    </Text>

                    {!!showPickUpAdress && (
                      <>
                        <Text block>{pickupDetail.name}</Text>
                        <Text block>{pickupDetail.address}</Text>
                      </>
                    )}

                    {hasShippingDays &&
                      (isShippingDaysRange ? (
                        <Text block>
                          {t('cardFulfillment.estimatedTimeRange', {
                            minDays: shippingMinDaysFinal,
                            maxDays: shippingMaxDaysFinal,
                          })}
                        </Text>
                      ) : (
                        <Text block>
                          {t('cardFulfillment.estimatedTime', {
                            count: shippingMinDaysFinal,
                          })}
                        </Text>
                      ))}

                    {!!totalWeight && (
                      <Text block>
                        {t('cardFulfillment.orderWeight', {
                          weight: totalWeight,
                        })}
                      </Text>
                    )}
                  </Stack.Item>
                )}
              </Stack>
              <Stack
                column={!isDesktop}
                justify="flex-start"
                align="flex-start"
                spacing="tight"
              >
                {fulfillmentOrder ? (
                  <PrintManagerDropdown
                    fulfillmentOrderId={fulfillmentOrder.id}
                    orderId={orderId}
                    shipping={shipping}
                    hasGeneratedTag={deliveryAddress?.hasGeneratedTag || false}
                    isNuvemEnvio={Boolean(
                      fulfillmentOrder?.shipping?.carrier?.name &&
                        nuvem_envio_available?.includes(
                          fulfillmentOrder.shipping.carrier.name,
                        ) &&
                        payment.status === Status.PAID,
                    )}
                  />
                ) : (
                  <PrintManagerDropdown
                    orderId={orderId}
                    shipping={shipping}
                    hasGeneratedTag={deliveryAddress?.hasGeneratedTag || false}
                  />
                )}
                {!!secondaryButton?.children && (
                  <Button {...secondaryButton} appearance="primary">
                    {secondaryButton?.children}
                  </Button>
                )}
              </Stack>
              {!!trackingCode && (
                <div className="trackingNumberInfo">
                  <TrackingNumberInfo
                    trackingNumber={trackingCode}
                    urlTrackingCode={trackingUrl}
                  />
                </div>
              )}
            </Stack>
          </DataList.Cell>
        </DataList.Row>
      </div>

      {isShow && (
        <ConfirmTrackingNumModal
          onClose={closeModal}
          onConfirm={handleClickFulfill}
          shippingTrackingNum={trackingCode || ''}
        />
      )}
    </>
  );
}
export default OrderSummaryDelivery;
