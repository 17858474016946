import { Input } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { InterfaceNameValue } from '@tiendanube/components';
import { RadioButtonGroup, Stack } from 'commons/components';
import { getTodayIsoString } from 'commons/utils/date';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { RadioOptionsSkeleton } from '../RadioOptionsSkeleton';

export interface DatesValuesInterface {
  dateType: 'unlimited' | 'limited';
  startDate: string;
  endDate: string;
}

export interface DatesErrorsInterface {
  startDate: string;
  endDate: string;
}

interface DatesProps {
  values: DatesValuesInterface;
  errors?: Partial<DatesErrorsInterface>;
  onChange: ({ value, name }: InterfaceNameValue) => void;
  disabled?: boolean;
}

export function Dates({
  values: { dateType, startDate, endDate },
  errors,
  onChange,
  disabled = false,
}: Readonly<DatesProps>) {
  const t = useTranslationMarketing('coupons.couponForm.limits.date');
  const { startDate: startDateError, endDate: endDateError } = errors || {};

  const today = getTodayIsoString();

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item fill>
        <RadioButtonGroup
          label={t('title')}
          boldLabel
          name="dateType"
          options={[
            {
              label: t('type.unlimited'),
              value: 'unlimited',
              disabled,
            },
            {
              label: t('type.limited'),
              value: 'limited',
              disabled,
            },
          ]}
          onChange={onChange}
          value={dateType}
        />
      </Stack.Item>
      {dateType === 'limited' && (
        <Stack.Item fill>
          <Stack spacing="tight">
            <Stack.Item fill>
              <FormField label={t('start')} id="startDate">
                <Input
                  type="date"
                  name="startDate"
                  value={startDate}
                  min={today}
                  onChange={({ target: { name, value } }) =>
                    onChange({ name, value })
                  }
                  appearance={startDateError ? 'danger' : undefined}
                  id="startDate"
                  disabled={disabled}
                />
              </FormField>
            </Stack.Item>
            <Stack.Item fill>
              <FormField label={t('end')} id="endDate">
                <Input
                  type="date"
                  name="endDate"
                  value={endDate}
                  min={startDate}
                  onChange={({ target: { name, value } }) =>
                    onChange({ name, value })
                  }
                  appearance={endDateError ? 'danger' : undefined}
                  id="endDate"
                  disabled={disabled}
                />
              </FormField>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
}

Dates.Skeleton = RadioOptionsSkeleton;
