import {
  BaseCard,
  Stack,
  Title,
  Input,
  Select,
  InterfaceNameValue,
  InterfaceSelectOption,
} from '@tiendanube/components';
import { useCategories } from 'domains/Catalog/Categories/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { NewCategoryInterface } from '../ModalConfirmationCreateCategory';

interface InformationCategoryCardProps {
  values: NewCategoryInterface;
  errors: Partial<Record<string, string>>;
  handleOnChange: (data: InterfaceNameValue) => void;
}

function InformationCategoryCard({
  values,
  errors,
  handleOnChange,
}: InformationCategoryCardProps): JSX.Element {
  const { categories } = useCategories();

  const categoriesOptions: InterfaceSelectOption[] = categories.map(
    (category) => ({
      label: category.title,
      value: category.id,
    }),
  );

  const t = useTranslationCatalog();

  const title = t('categories.detail.categoryInformation.category');
  const nameLabel = t('products.detail.name');
  const parentLabel = t('products.detail.isSubcategoryOf');
  const parentPlaceholder = t('products.detail.selectCategory');

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack column spacing="base" align="stretch">
          <Stack.Item>
            <Title type="h3">{title}</Title>
          </Stack.Item>
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          <Stack.Item fill>
            <Input
              appearance={errors.name ? 'validation_error' : 'default'}
              label={nameLabel}
              value={values.name}
              name="name"
              onChange={handleOnChange}
              placeholder={t('categories.detail.name.placeholder')}
              helpText={t(errors.name || '')}
            />
          </Stack.Item>
          <Stack.Item fill>
            <Select
              label={parentLabel}
              name="parent"
              value={values.parent}
              options={categoriesOptions}
              onChange={handleOnChange}
              placeholder={parentPlaceholder}
            />
          </Stack.Item>
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default InformationCategoryCard;
