import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import getImageMimeType from 'commons/utils/getImageMimeType';
import { useGetLanguage } from 'domains/Auth/hooks';
import { ImageGalleryState } from '../../components/ImageGallery/ImageGallery';

const ACCEPT_TYPE_FILE = 'image/jpeg,image/gif,image/png,image/webp';
const ACCEPT_TYPE_FILE_ARRAY = ACCEPT_TYPE_FILE.split(',');
const ONE_MB_IN_BYTES = 1024 * 1024;

interface UseImageLoaderProps {
  onUploadImages: (images: ImageGalleryState[]) => void;
}

function useImageLoader({ onUploadImages }: UseImageLoaderProps) {
  const language = useGetLanguage();
  const [imagesWithBadType, setImagesWithBadType] = useState<string[]>([]);
  const [imagesExceededSize, setImagesExceededSize] = useState<string[]>([]);

  const handleOnchange = async (files: File[]) => {
    const newImages = await Promise.all(
      Object.values(files).map(async (file) => ({
        alt: { [language]: file.name },
        isLoading: true,
        id: uuidv4(),
        file,
        src: URL.createObjectURL(file),
        isError: false,
        name: file.name,
        size: file.size,
        type: file.type,
        mimeType: await getImageMimeType(file),
        isEdited: false,
      })),
    );
    const imagesWithBadType = newImages
      .filter(
        (file) =>
          !ACCEPT_TYPE_FILE_ARRAY.includes(file.type) ||
          !ACCEPT_TYPE_FILE_ARRAY.includes(file.mimeType),
      )
      .map((file) => file.name);
    const imagesExceededSize = newImages
      .filter((file) => file.size / ONE_MB_IN_BYTES >= 10)
      .map((file) => file.name);
    const validImages = newImages.filter(
      (file) =>
        !imagesWithBadType.includes(file.name) &&
        !imagesExceededSize.includes(file.name),
    );
    setImagesWithBadType(imagesWithBadType);
    setImagesExceededSize(imagesExceededSize);
    onUploadImages(validImages);
  };

  return {
    imagesWithBadType,
    imagesExceededSize,
    acceptTypeFile: ACCEPT_TYPE_FILE,
    setImagesWithBadType,
    setImagesExceededSize,
    handleOnchange,
  };
}

export default useImageLoader;
