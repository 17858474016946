import { Label } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { ClientLimitationType } from '@tiendanube/common';
import { InterfaceNameValue } from '@tiendanube/components';
import { InputNumberNimbus, RadioButtonGroup, Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { RadioOptionsSkeleton } from '../RadioOptionsSkeleton';

export interface ClientValuesInterface {
  clientLimitationType: ClientLimitationType;
  maxUsesPerClient: string;
}

export interface ClientErrorsInterface {
  maxUsesPerClient: string;
}

interface ClientProps {
  values: ClientValuesInterface;
  errors: Partial<ClientErrorsInterface>;
  onChange: ({ value, name }: InterfaceNameValue) => void;
  disabled?: boolean;
}

export function Client({
  values: { clientLimitationType, maxUsesPerClient },
  errors: { maxUsesPerClient: maxUsesPerClientError },
  onChange,
  disabled = false,
}: Readonly<ClientProps>) {
  const t = useTranslationMarketing();
  const appearance = maxUsesPerClientError ? 'danger' : undefined;

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item>
        <RadioButtonGroup
          label={t('coupons.couponForm.limits.client.title')}
          boldLabel
          name="clientLimitationType"
          options={[
            {
              label: t('coupons.couponForm.limits.client.type.unlimited'),
              value: 'unlimited',
              disabled,
            },
            {
              label: t('coupons.couponForm.limits.client.type.limited'),
              value: 'limited',
              disabled,
            },
            {
              label: t(
                'coupons.couponForm.limits.client.type.first_consumer_purchase',
              ),
              value: 'first_consumer_purchase',
              disabled,
            },
          ]}
          onChange={onChange}
          value={clientLimitationType}
        />
      </Stack.Item>

      {clientLimitationType === 'first_consumer_purchase' && (
        <Stack.Item>
          <Label>
            {t(
              'coupons.couponForm.limits.client.labels.first_consumer_purchase',
            )}
          </Label>
        </Stack.Item>
      )}

      {clientLimitationType === 'limited' && (
        <Stack.Item>
          <FormField
            label={t(
              'coupons.couponForm.limits.client.labels.max_uses_per_client',
            )}
            id="maxUsesPerClient"
            appearance={appearance}
            helpText={t(maxUsesPerClientError || '')}
            showHelpText={!!maxUsesPerClientError}
          >
            <InputNumberNimbus
              type="float"
              name="maxUsesPerClient"
              value={maxUsesPerClient}
              onChange={({ target: { name, value } }) =>
                onChange({ name, value })
              }
              appearance={appearance}
              id="maxUsesPerClient"
              disabled={disabled}
            />
          </FormField>
        </Stack.Item>
      )}
    </Stack>
  );
}

Client.Skeleton = RadioOptionsSkeleton;
