import { Radio, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { Categories, Products } from 'domains/Marketing/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { PromotionFormProps } from '../PromotionForm';

interface ScopeSelectorProps
  extends Pick<
    PromotionFormProps,
    'values' | 'errors' | 'onChange' | 'onChangeCategories' | 'onChangeProducts'
  > {
  disabled?: boolean;
}

export function ScopeSelector({
  values,
  errors,
  disabled = false,
  onChange,
  onChangeCategories,
  onChangeProducts,
}: Readonly<ScopeSelectorProps>) {
  const t = useTranslationMarketing(
    'promotions.settingsPage.conditions.applyTo',
  );
  const errorCategories = errors['categories'] ? t(errors['categories']) : '';
  const errorProducts = errors['products'] ? t(errors['products']) : '';

  const { scopeType, categories, products } = values;

  return (
    <>
      <Text fontWeight="bold">{t('title')}</Text>
      <Stack spacing="tight">
        <Radio
          id="scope-type-1"
          name="scopeType"
          as="button"
          value="all"
          checked={scopeType === 'all'}
          label={t('all')}
          onChange={onChange}
          disabled={scopeType !== 'all' && disabled}
        />
        <Radio
          id="scope-type-2"
          name="scopeType"
          as="button"
          value="products"
          checked={scopeType === 'products'}
          label={t('products.title')}
          onChange={onChange}
          disabled={scopeType !== 'products' && disabled}
        />
        <Radio
          id="scope-type-3"
          name="scopeType"
          as="button"
          value="categories"
          checked={scopeType === 'categories'}
          label={t('categories.title')}
          onChange={onChange}
          disabled={scopeType !== 'categories' && disabled}
        />
      </Stack>
      {scopeType === 'categories' && (
        <Categories
          categories={categories}
          requiredText={errorCategories}
          emptyTitle={t('categories.empty.title')}
          emptyText={t('categories.empty.text')}
          onChange={onChangeCategories}
          showTitle
        />
      )}
      {scopeType === 'products' && (
        <Products
          products={products}
          requiredText={errorProducts}
          title={t('products.title')}
          subtitle={t('products.subtitle')}
          emptyTitle={t('products.empty.title')}
          emptyText={t('products.empty.text')}
          onChange={onChangeProducts}
        />
      )}
    </>
  );
}
