import { Text } from '@nimbus-ds/components';
import { InterfaceNameValue } from '@tiendanube/components';
import { RadioButtonGroup, Stack } from 'commons/components';
import {
  Categories,
  CategoriesInterface,
  Products,
  ProductsInterface,
} from 'domains/Marketing/components';
import { Scope, ScopeType } from 'domains/Marketing/enums';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import Skeleton from './Skeleton';

export interface ScopeSelectorValuesInterface {
  scopeType: ScopeType;
  categories: CategoriesInterface[];
  products: ProductsInterface[];
}

interface ScopeSelectorProps {
  values: ScopeSelectorValuesInterface;
  errors: Partial<Record<string, string>>;
  onChange: ({ name, value }: InterfaceNameValue) => void;
  onChangeCategories: (scopeValues: CategoriesInterface[]) => void;
  onChangeProducts: (scopeValues: ProductsInterface[]) => void;
  disabled?: boolean;
  translationPrefix?: string;
}

export function ScopeSelector({
  values,
  errors,
  disabled = false,
  onChange,
  onChangeCategories,
  onChangeProducts,
  translationPrefix = '',
}: Readonly<ScopeSelectorProps>) {
  const t = useTranslationMarketing(translationPrefix);
  const tError = useTranslationMarketing();

  const errorCategories = errors['categories']
    ? tError(errors['categories'])
    : '';
  const errorProducts = errors['products'] ? tError(errors['products']) : '';

  const { scopeType, categories, products } = values;

  return (
    <>
      <RadioButtonGroup
        name="scopeType"
        value={scopeType as ScopeType}
        onChange={onChange}
        options={[
          {
            label: t('all.title'),
            value: Scope.ALL,
            disabled: scopeType !== Scope.ALL && disabled,
          },
          {
            label: t('categories.title'),
            value: Scope.CATEGORIES,
            disabled: scopeType !== Scope.CATEGORIES && disabled,
          },
          {
            label: t('products.title'),
            value: Scope.PRODUCTS,
            disabled: scopeType !== Scope.PRODUCTS && disabled,
          },
        ]}
      />
      {scopeType === Scope.ALL && <Text>{t('all.description')}</Text>}
      {scopeType === Scope.CATEGORIES && (
        <Stack column align="stretch">
          <Text>{t('categories.description')}</Text>
          <Categories
            categories={categories}
            requiredText={errorCategories}
            title={t('categories.title')}
            subtitle={t('categories.selector.description')}
            selectLinkText={t('categories.selector.select')}
            editLinkText={t('categories.selector.edit')}
            emptyTitle={t('categories.empty.title')}
            emptyText={t('categories.empty.text')}
            onChange={onChangeCategories}
            showTitle={false}
            disabled={disabled}
          />
        </Stack>
      )}
      {scopeType === Scope.PRODUCTS && (
        <Stack column align="stretch">
          <Text>{t('products.description')}</Text>
          <Products
            products={products}
            requiredText={errorProducts}
            title={t('products.title')}
            subtitle={t('products.selector.description')}
            selectLinkText={t('products.selector.select')}
            editLinkText={t('products.selector.edit')}
            emptyTitle={t('products.empty.title')}
            emptyText={t('products.empty.text')}
            onChange={onChangeProducts}
            showTitle={false}
            disabled={disabled}
          />
        </Stack>
      )}
    </>
  );
}

ScopeSelector.Skeleton = Skeleton;
