import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { InputNumberNimbus } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface PurchaseValueProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export function PurchaseValue({
  onChange,
  value,
}: Readonly<PurchaseValueProps>) {
  const t = useTranslationMarketing('freeShipping.settingsPage.conditions');
  const { symbol } = useGetCurrency();

  return (
    <FormField label={t('purchaseValue')}>
      <InputNumberNimbus
        name="priceMinimum"
        type="float"
        append={symbol}
        appendPosition="start"
        value={`${value}`}
        onChange={onChange}
      />
    </FormField>
  );
}
