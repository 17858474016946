import { Box, Card, Link, Text, Tag } from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { Link as LinkRouter } from 'react-router-dom';
import { OnboardingTaskNameType } from '@tiendanube/common';
import { Responsive } from '@tiendanube/components';
import IconBox from 'commons/components/IconBox/IconBox';
import { useTransactionFeeAdminDisabled } from 'domains/Auth/hooks';
import { TRANSACTION_FEES_BASE_ROUTE } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import Skeleton from './Skeleton';

interface OnboardingCardProps {
  name: OnboardingTaskNameType;
  isCompleted?: boolean;
  title: string;
  label?: string;
  to: string;
  linkText: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

function OnboardingTaskCard({
  title,
  label,
  linkText,
  to,
  isCompleted = false,
  icon,
  onClick,
}: OnboardingCardProps) {
  const isBlocked = useTransactionFeeAdminDisabled();
  const chip = label ? <Tag appearance="success">{label}</Tag> : undefined;
  const newAdminPath = isBlocked ? TRANSACTION_FEES_BASE_ROUTE : to;

  return (
    <Card padding="base">
      <Box display="flex" flexDirection={{ lg: 'column' }} gap="2">
        <Box display="flex" gap="0-5" justifyContent="space-between">
          <IconBox
            backgroundColor={
              isCompleted ? 'success-surfaceHighlight' : 'primary-surface'
            }
            color={isCompleted ? 'success-textLow' : 'primary-interactive'}
          >
            {isCompleted ? <CheckCircleIcon color="currentColor" /> : icon}
          </IconBox>
          <Responsive displays={['desktop']}>{chip}</Responsive>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="1"
          justifyContent="space-between"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            gap="2"
            justifyContent="space-between"
          >
            <Text fontWeight={isCompleted ? 'bold' : undefined}>{title}</Text>
            <Responsive displays={['mobile', 'tablet']}>{chip}</Responsive>
          </Box>
          <Link
            textDecoration="none"
            as={LinkRouter}
            appearance="primary"
            to={newAdminPath}
            onClick={onClick}
          >
            {linkText}
          </Link>
        </Box>
      </Box>
    </Card>
  );
}

OnboardingTaskCard.Skeleton = Skeleton;

export default OnboardingTaskCard;
