import { Trans } from 'react-i18next';
import { Alert, List, Stack, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface UnclaimedStockAlertProps {
  productNames: string[];
  show: boolean;
}

function UnclaimedStockAlert({
  productNames,
  show,
}: UnclaimedStockAlertProps): JSX.Element {
  const t = useTranslationOrders();
  const productsString = productNames.join(', ');
  const showProductsAsList = productNames.length > 3;

  return (
    <Alert
      title={t('detail.unclaimedStockAlert.title')}
      appearance="warning"
      show={show}
    >
      <Stack column align="stretch">
        {showProductsAsList ? (
          <List>
            {productNames.map((productName) => (
              <List.Item key={productName}>
                <Text bold>{productName}</Text>
              </List.Item>
            ))}
          </List>
        ) : (
          <Trans
            t={t}
            i18nKey="detail.unclaimedStockAlert.body"
            values={{ products: productsString }}
            components={{
              strong: <strong />,
            }}
          />
        )}
        <List>
          <List.Item>
            <Text>{t('detail.unclaimedStockAlert.item1')}</Text>
          </List.Item>
          <List.Item>
            <Text>{t('detail.unclaimedStockAlert.item2')}</Text>
          </List.Item>
          <List.Item>
            <Text>{t('detail.unclaimedStockAlert.item3')}</Text>
          </List.Item>
        </List>
      </Stack>
    </Alert>
  );
}

export default UnclaimedStockAlert;
