import { Text } from '@nimbus-ds/components';
import { useHistory } from 'react-router';
import { EmptyState } from '@tiendanube/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { MARKETING_ROUTES } from 'domains/Marketing/marketingRoutes';
import emptyCouponsImage from './empty-coupons.png';

export function CouponsListEmpty() {
  const t = useTranslationMarketing('coupons.emptyResults');
  const { push } = useHistory();
  const handlePrimary = () => push(MARKETING_ROUTES.newCoupon);

  return (
    <EmptyState
      image={emptyCouponsImage}
      title={t('title')}
      primaryActionLabel={t('action')}
      onClickPrimary={handlePrimary}
    >
      <Text>{t('body')}</Text>
    </EmptyState>
  );
}
