import { Fragment } from 'react';
import { Box } from '@nimbus-ds/components';
import {
  ToolsIcon,
  TagIcon,
  TruckIcon,
  CreditCardIcon,
} from '@nimbus-ds/icons';
import { OnboardingTaskNameType } from '@tiendanube/common';
import getMockArrayForSkeleton from 'commons/utils/getMockArrayForSkeleton';
import {
  trackingAddProductClick,
  trackingPaymentsClick,
  trackingSelectThemeClick,
  trackingShippingClick,
} from 'domains/Dashboard/tracking';
import OnboardingTaskCard from './OnboardingTaskCard';
import useOnboadringSteps from './useOnboardingTasks';

const ICONS: Record<OnboardingTaskNameType, React.ReactNode> = {
  setup_layout: <ToolsIcon />,
  setup_first_product: <TagIcon />,
  setup_payments: <CreditCardIcon />,
  setup_shipping: <TruckIcon />,
};

const LOG_EVENTS: Record<OnboardingTaskNameType, () => void> = {
  setup_layout: trackingSelectThemeClick,
  setup_first_product: trackingAddProductClick,
  setup_payments: trackingPaymentsClick,
  setup_shipping: trackingShippingClick,
};

function OnboardingTasks() {
  const { hasCompleted, isError, isLoading, onboardingTasks } =
    useOnboadringSteps();

  if (hasCompleted || isError) return null;

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        lg: '1fr 1fr 1fr 1fr',
        md: '1fr 1fr',
        xs: '1fr',
      }}
      gridGap="4"
      gap="4"
    >
      {isLoading &&
        getMockArrayForSkeleton(4).map((element) => (
          <Fragment key={element}>
            <OnboardingTaskCard.Skeleton />
          </Fragment>
        ))}
      {!isLoading &&
        onboardingTasks?.tasks.map((task) => (
          <OnboardingTaskCard
            key={task.name}
            name={task.name}
            title={task.title}
            linkText={task.ctaText}
            to={task.ctaLink}
            isCompleted={task.completed}
            label={task.label}
            icon={ICONS[task.name]}
            onClick={LOG_EVENTS[task.name]}
          />
        ))}
    </Box>
  );
}

export default OnboardingTasks;
