import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Stack, BaseCard, Title } from '@tiendanube/components';
import { FEATURE_AI_PRODUCT_DESCRIPTION, TIER_1 } from 'App/features';
import { LanguagesType } from 'App/i18n';
import TabsMultiLanguage from 'commons/components/TabsMultiLanguage';
import { useModal } from 'commons/hooks';
import { CATALOG_SEO_CONTENT } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import FormSeoCategories, { FormSeoCategoriesProps } from './FormSeoCategories';
import Skeleton from './Skeleton';

type SeoCategoriesProps = Omit<
  FormSeoCategoriesProps,
  'showAiModal' | 'openAiModal' | 'closeAiModal'
>;

function SeoCategories({
  seoTitle,
  seoDescription,
  categoryId,
  name,
  description,
  handle,
  handleChange,
  errors,
}: SeoCategoriesProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  const missingLanguages = errors
    ? Object.keys(errors)
        .filter((err) => err.startsWith('handle'))
        .map((err) => err.split('.')[1])
    : [];

  const [showAiModal, openAiModal, closeAiModal] = useModal();

  const handleOpenSeoGenerationModal = useUpsellFlow({
    title: t('categories.detail.seoCategory.aiGeneration.upsell'),
    featureKey: FEATURE_AI_PRODUCT_DESCRIPTION,
    trackingSource: CATALOG_SEO_CONTENT,
    callback: openAiModal,
    minValue: TIER_1,
    asAside: true,
  });

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{t('categories.detail.seoCategory.title')}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          <TabsMultiLanguage
            initialLanguage={missingLanguages[0] as LanguagesType}
          >
            <FormSeoCategories
              seoTitle={seoTitle}
              seoDescription={seoDescription}
              categoryId={categoryId}
              name={name}
              description={description}
              handle={handle}
              handleChange={handleChange}
              errors={errors}
              showAiModal={showAiModal}
              openAiModal={handleOpenSeoGenerationModal}
              closeAiModal={closeAiModal}
            />
          </TabsMultiLanguage>
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

SeoCategories.Skeleton = Skeleton;

export default SeoCategories;
