import { Box, Link } from '@nimbus-ds/components';
import { BaseCard, Stack, Text, Title } from '@tiendanube/components';
import { CalendarIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { SubmitButton } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { TaxAlert } from 'domains/Billing/Checkout/components';
import { CancelRecurrentPaymentButton } from 'domains/Billing/Checkout/pages/components';
import { TRANSACTION_FEES_ROUTES } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import TransactionFeeToPayCardSkeleton from './Skeleton';
import { useTransactionFeeToPay } from '../../hooks';
import TransactionFeesErrorState from '../TransactionFeesErrorState';

function TransactionFeeToPayCard(): JSX.Element {
  const t = useTranslationBilling(
    'transactionFeesPage.transactionFeeToPayCard',
  );
  const { goTo } = useNavegate();
  const {
    refreshTransactionFeeToPay,
    transactionFeeToPay,
    thereAreOrdersForCurrentMonth,
    isLoading,
    isSuccess,
    isError,
    shouldShowPaymentButton,
    onPay,
    payOrderStatus,
  } = useTransactionFeeToPay(true);

  useToastStatus({
    status: payOrderStatus.status,
    error: t('payOrderError'),
  });

  if (isLoading) return <TransactionFeeToPayCardSkeleton />;

  const goToDetail = () => {
    if (!transactionFeeToPay || !transactionFeeToPay.paymentId) return;
    goTo(
      TRANSACTION_FEES_ROUTES.transactionFeesNoSplitMonthly.replace(
        ':paymentId',
        transactionFeeToPay.paymentId,
      ),
    );
  };

  const goToCurrentOrdersDetail = () =>
    goTo(`${TRANSACTION_FEES_ROUTES.transactionFeesNoSplitList}`);

  const transactionFeesFound =
    transactionFeeToPay && !transactionFeeToPay.isEmpty;

  const titlePath = transactionFeeToPay?.recurrentPaymentActivated
    ? 'recurrentPaymentActivated'
    : 'recurrentPaymentDisabled';

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack spacing="base" column align="flex-start">
          <Title type="h3">{`${t(`${titlePath}.title`)}`}</Title>
        </Stack>
      </BaseCard.Header>
      {isError && (
        <TransactionFeesErrorState onClick={refreshTransactionFeeToPay} />
      )}
      {isSuccess && (
        <>
          <BaseCard.Body>
            {transactionFeesFound &&
              (transactionFeeToPay?.paymentAvailable ||
                shouldShowPaymentButton) && (
                <Stack spacing="base" column align="flex-start">
                  <Text>{transactionFeeToPay.description}</Text>
                  <Text size="featured">{transactionFeeToPay.total}</Text>
                  <TaxAlert />
                  <Box display="flex" flexDirection="row">
                    {transactionFeeToPay.recurrentPaymentActivated && (
                      <CancelRecurrentPaymentButton
                        conceptCode="transaction-fee"
                        appearance="neutral"
                      />
                    )}
                    {shouldShowPaymentButton && (
                      <SubmitButton
                        onClick={onPay}
                        isLoading={payOrderStatus.isLoading}
                      >
                        {t('pay')}
                      </SubmitButton>
                    )}
                  </Box>
                  <Link onClick={goToDetail} appearance="primary">
                    {t('moreDetails')}
                  </Link>
                </Stack>
              )}
            {!shouldShowPaymentButton &&
              (!transactionFeesFound ||
                !transactionFeeToPay?.paymentAvailable) && (
                <Text size="highlight">{t('emptyMessage')}</Text>
              )}
          </BaseCard.Body>

          {transactionFeesFound && thereAreOrdersForCurrentMonth && (
            <>
              <hr />
              <BaseCard.Footer>
                <Stack spacing="base" align="center" justify="flex-start">
                  <CalendarIcon />
                  <Stack spacing="none" column align="flex-start">
                    <Text>{transactionFeeToPay.feeSummary?.date}</Text>
                    <Text>{transactionFeeToPay.feeSummary?.cost}</Text>
                    <Link
                      onClick={goToCurrentOrdersDetail}
                      appearance="primary"
                    >
                      {t('moreDetails')}
                    </Link>
                  </Stack>
                </Stack>
              </BaseCard.Footer>
            </>
          )}
        </>
      )}
    </BaseCard>
  );
}

export default TransactionFeeToPayCard;
