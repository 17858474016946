import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { LocationDetailsError, LocationDetailsSkeleton } from './components';
import {
  useGetCityOptions,
  useGetCountriesOptions,
  useGetProvincesOptions,
} from '../../hooks';
import useLocationDetails from '../../hooks/useLocationDetails';
import useLocations from '../../hooks/useLocations';
import { FormLocation } from '../components';
import { validationSchema } from '../components/FormLocation/validationSchema';
import LocationPage from '../components/LocationPage';
import { COUNTRY_WITH_CITIES } from '../constants';
import { LocationErrorsInterface, LocationValuesInterface } from '../types';

function EditLocationPage(): JSX.Element | null {
  const { id } = useParams<{ id: string }>();
  const t = useTranslationShipping();
  const { goBack } = useNavegate();

  const { addToast } = useToast();

  const { fetchLocation, isLoading, isError, location } =
    useLocationDetails(id);

  const { updateLocationAndFetch } = useLocations();

  useEffect(() => {
    fetchLocation();
  }, [fetchLocation]);

  const {
    provinces,
    isLoading: isLoadingProvinces,
    isError: isErrorProvinces,
    fetchProvinces,
  } = useGetProvincesOptions();

  const {
    countries,
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    fetchCountries,
  } = useGetCountriesOptions();

  const {
    cities,
    isLoading: isLoadingCities,
    isError: isErrorCities,
    fetchCities,
  } = useGetCityOptions();

  const [handleEditLocation, isSaving] = useAsyncFunc<
    LocationValuesInterface,
    Promise<void>
  >(
    async (data) => {
      if (data) {
        await updateLocationAndFetch({ id, location: data });
      }
    },
    () => {
      addToast({
        label: t('locations.form.edit.success'),
        appearance: 'success',
      });
      goBack();
    },
    () => {
      addToast({
        label: t('locations.form.edit.error'),
        appearance: 'danger',
      });
    },
  );

  const {
    errors,
    values,
    isDirty,
    handleOnChange,
    handleOnSubmit,
    setFieldValue,
  } = useForm<LocationValuesInterface, LocationErrorsInterface>({
    validationSchema,
    initialValues: location,
    onSubmit: handleEditLocation,
  });

  const isErrorOnLoadForm =
    isError || isErrorCountries || isErrorProvinces || isErrorCities;
  const isLoadingData = isLoading || (!isLoading && values.id !== location.id);

  const onRetryError = () => {
    fetchLocation();
    fetchCountries();
    fetchProvinces(values.country);
    if (values.country in COUNTRY_WITH_CITIES && values.province) {
      fetchCities(values.country, values.province);
    }
  };

  return (
    <LocationPage
      isSaving={isSaving}
      handleSave={handleOnSubmit}
      title={
        location.main
          ? t('locations.form.edit.titleMain')
          : t('locations.form.edit.title')
      }
      submitLabel={t(`locations.form.edit.save`)}
      cancelLabel={t(`locations.form.edit.cancel`)}
      isDirty={isDirty}
      isError={isErrorOnLoadForm || isLoading}
      isEmptyTags={values.tags.length === 0}
    >
      {isLoadingData && <LocationDetailsSkeleton />}
      {isErrorOnLoadForm && !isLoadingData && (
        <LocationDetailsError onRetryError={onRetryError} />
      )}
      {!isErrorOnLoadForm && !isLoadingData && (
        <FormLocation
          values={values}
          errors={errors}
          countries={countries}
          provinces={provinces}
          cities={cities}
          loadingCountries={isLoadingCountries}
          loadingProvinces={isLoadingProvinces}
          loadingCities={isLoadingCities}
          fetchCountries={fetchCountries}
          fetchProvinces={fetchProvinces}
          fetchCities={fetchCities}
          handleChange={handleOnChange}
          setFieldValue={setFieldValue}
        />
      )}
    </LocationPage>
  );
}

export default EditLocationPage;
