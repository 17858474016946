import { Table } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export function PromotionsListHeader() {
  const t = useTranslationMarketing('promotions.headers');
  return (
    <Table.Head>
      <CustomHeaderCell width={{ xl: '180px' }}>{t('name')}</CustomHeaderCell>
      <CustomHeaderCell width={{ xl: '180px', lg: '100px' }}>
        {t('discountType')}
      </CustomHeaderCell>
      <CustomHeaderCell width="auto">{t('scope')}</CustomHeaderCell>
      <CustomHeaderCell width={{ xl: 'auto', lg: '100px' }}>
        {t('validity')}
      </CustomHeaderCell>
      <CustomHeaderCell width={{ xl: '140px', lg: '100px' }}>
        {t('status')}
      </CustomHeaderCell>
      <CustomHeaderCell width={{ md: '120px' }}>
        {t('actions')}
      </CustomHeaderCell>
    </Table.Head>
  );
}
