import { InteractiveList } from '@nimbus-ds/patterns';
import { i18nResponseDto } from '@tiendanube/common';
import {
  AI_PRODUCT_IMAGE_EDIT_ENABLED,
  AI_PRODUCT_IMAGE_SMART_ERASE_ENABLED,
} from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { DEFAULT_LANGUAGE, LanguagesType } from 'App/i18n';
import {
  trackingAiEditImageRemoveBgCtaClick,
  trackingAiEditImageReplaceBgWithColorCtaClick,
  trackingAiEditImageReplaceBgWithImageCtaClick,
  trackingAiEditImageSmartEraseCtaClick,
} from 'domains/Ai/tracking';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import ChangeBackgroundColorModal from './components/ChangeBackgroundColorModal';
import ChangeBackgroundImageModal from './components/ChangeBackgroundImageModal';
import EditAltTextModal from './components/EditAltTextModal';
import { AltTextGenerationContext } from './components/EditAltTextModal/components/AltTextGenerationModal';
import ModalInteractiveListAction from './components/ModalInteractiveListAction';
import RemoveBackgroundModal from './components/RemoveBackgroundModal';
import SmartEraseModal from './components/SmartEraseModal';
import { ImageGalleryState } from '../../../ImageGallery';
import { EditedImage } from '../EditImageModal';

interface EditImageActionsProps {
  language?: LanguagesType;
  image: ImageGalleryState;
  altTextGenerationContext: AltTextGenerationContext;
  onChangeAltText: (value: i18nResponseDto) => void;
  onSaveImage: (image: EditedImage) => void;
}

function EditImageActions({
  language = DEFAULT_LANGUAGE,
  image,
  altTextGenerationContext,
  onChangeAltText,
  onSaveImage,
}: Readonly<EditImageActionsProps>) {
  const t = useTranslationCatalog('products.modal.editPhoto.actions');
  const defaultValue = image.alt;

  const hasAltText =
    defaultValue[language] !== undefined && defaultValue[language] !== '';

  const [editImageActionsEnabled] = useHasTags([AI_PRODUCT_IMAGE_EDIT_ENABLED]);
  const [smartEraseEnabled] = useHasTags([
    AI_PRODUCT_IMAGE_SMART_ERASE_ENABLED,
  ]);
  return (
    <InteractiveList>
      {editImageActionsEnabled && (
        <ModalInteractiveListAction
          cta={t('changeBackgroundColor.cta')}
          aIPoweredAction
          isBeta
          onOpen={() => trackingAiEditImageReplaceBgWithColorCtaClick()}
          modalChildren={
            <ChangeBackgroundColorModal
              currentImage={image}
              onSaveImage={onSaveImage}
            />
          }
        />
      )}
      {editImageActionsEnabled && (
        <ModalInteractiveListAction
          cta={t('changeBackgroundImage.cta')}
          aIPoweredAction
          isBeta
          onOpen={() => trackingAiEditImageReplaceBgWithImageCtaClick()}
          modalChildren={
            <ChangeBackgroundImageModal
              currentImage={image}
              onSaveImage={onSaveImage}
            />
          }
        />
      )}
      {editImageActionsEnabled && (
        <ModalInteractiveListAction
          cta={t('removeBackground.cta')}
          aIPoweredAction
          isBeta
          onOpen={() => trackingAiEditImageRemoveBgCtaClick()}
          modalChildren={
            <RemoveBackgroundModal
              currentImage={image}
              onSaveImage={onSaveImage}
            />
          }
        />
      )}
      {smartEraseEnabled && (
        <ModalInteractiveListAction
          cta={t('smartErase.cta')}
          aIPoweredAction
          isBeta
          onOpen={() => trackingAiEditImageSmartEraseCtaClick()}
          modalChildren={
            <SmartEraseModal currentImage={image} onSaveImage={onSaveImage} />
          }
        />
      )}
      <ModalInteractiveListAction
        cta={hasAltText ? t('altText.ctaEdit') : t('altText.ctaAdd')}
        modalChildren={
          <EditAltTextModal
            image={image}
            onChange={onChangeAltText}
            altTextGenerationContext={altTextGenerationContext}
          />
        }
      />
    </InteractiveList>
  );
}

export default EditImageActions;
