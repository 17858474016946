import { Alert, Box, Chip, Text } from '@nimbus-ds/components';
import { SelectOrEditLink } from 'commons/components';
import { useModal } from 'commons/hooks';
import Skeleton from './Skeleton';
import { useTranslationMarketing } from '../../hook';
import { CategoriesModalAside } from '../CategoriesModalAside';

export interface CategoriesInterface {
  id: string;
  description: string;
}

interface CategoriesProps {
  categories: CategoriesInterface[];
  title?: string;
  subtitle?: string;
  requiredText?: string;
  emptyTitle: string;
  emptyText: string;
  showTitle: boolean;
  onChange: (categories: CategoriesInterface[]) => void;
  selectLinkText?: string;
  editLinkText?: string;
  disabled?: boolean;
}

export function Categories({
  categories,
  title,
  subtitle,
  showTitle,
  requiredText,
  emptyTitle,
  emptyText,
  onChange,
  selectLinkText,
  editLinkText,
  disabled = false,
}: Readonly<CategoriesProps>) {
  const t = useTranslationMarketing('freeShipping.asides.categories');

  const [isShowModal, openModal, closeModal] = useModal();
  const isEmpty = !categories.length;

  const titleOrDefault = title ?? t('title');
  const extraMargin = !showTitle ? '2' : 'none';

  return (
    <>
      {showTitle && <Text fontWeight="bold">{titleOrDefault}</Text>}
      {!isEmpty && (
        <Box display="flex" gap="2" flexWrap="wrap" marginTop={extraMargin}>
          {categories.map(({ id, description }) => (
            <Chip
              key={id}
              text={description}
              removable={!disabled}
              onClick={() =>
                onChange(categories.filter((current) => current.id !== id))
              }
            />
          ))}
        </Box>
      )}

      {!disabled && (
        <>
          <SelectOrEditLink
            isEdit={!isEmpty}
            onSelect={openModal}
            selectLinkText={selectLinkText}
            editLinkText={editLinkText}
          />
          {!!requiredText && <Alert appearance="danger">{requiredText}</Alert>}
          <CategoriesModalAside
            isOpen={isShowModal}
            selected={categories}
            title={titleOrDefault}
            subtitle={subtitle}
            emptyTitle={emptyTitle}
            emptyText={emptyText}
            onClose={closeModal}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
}

Categories.Skeleton = Skeleton;
