import { Tag, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { Skeleton } from './Skeleton';
import { Actions } from '../../Actions';
import { DomainSSLStatusTag } from '../../DomainSSLStatusTag';
import { DomainStatusTag } from '../../DomainStatusTag';
import { PrimaryDomainTag } from '../../PrimaryDomainTag';
import { ReactivateDomainLink } from '../../ReactivateDomainLink';
import { DomainsListRowProps } from '../../types';

export function DomainsListMobileRow({
  domain,
  ourDomain,
  domainsCount,
  onDeleteDomain,
  isDeleting,
  isUpdating,
  onSetPrimaryDomain,
}: Readonly<DomainsListRowProps>) {
  const t = useTranslationConfigurations('domains');

  return (
    <DataList.Row backgroundColor="transparent">
      <Stack column align="stretch" gap="4">
        <Stack justify="space-between" align="center" wrap>
          <Text>{domain.name}</Text>
          {domain.isOurDomain && (
            <Tag appearance="neutral">{t('tags.default')}</Tag>
          )}
          {domain.isPrimaryDomain && <PrimaryDomainTag />}
        </Stack>
        <Stack justify="space-between" align="center">
          <Stack wrap>
            {!domain.isOurDomain && isUpdating ? (
              <Tag.Skeleton />
            ) : (
              <DomainStatusTag
                isOurDomain={domain.isOurDomain}
                status={domain.status.domain}
              />
            )}
            {!domain.isOurDomain && isUpdating ? (
              <Tag.Skeleton />
            ) : (
              <DomainSSLStatusTag
                isOurDomain={domain.isOurDomain}
                status={domain.status.ssl}
              />
            )}
          </Stack>
          <Actions
            domain={domain}
            ourDomain={ourDomain}
            domainsCount={domainsCount}
            isDeleting={isDeleting}
            onDelete={onDeleteDomain}
            onSetPrimaryDomain={onSetPrimaryDomain}
          />
        </Stack>
        {!domain.isOurDomain && <ReactivateDomainLink />}
      </Stack>
    </DataList.Row>
  );
}

DomainsListMobileRow.Skeleton = Skeleton;
