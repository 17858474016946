import { useState, useEffect, useRef } from 'react';
import { NodeData } from '@nosferatu500/react-sortable-tree';
import { Alert } from '@tiendanube/components';
import { ModalConfirmationBase } from 'App/components';
import { useNavegate } from 'App/hooks';
import {
  CancelAndSaveButtons,
  DraggableTree,
  DraggableTreeNew,
} from 'commons/components';
import { useModal } from 'commons/hooks';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import { trackingCategoriesCreateButtonClick } from 'domains/Catalog/Products/tracking';
import { CategoriesListEmpty, CategoriesPage } from './components';
import { Actions } from './components/CategoryNode/components';
import CategoryNodeNew from './components/CategoryNodeNew';
import { useCategoriesHandlers } from './hooks';
import useCategoriesTreeNew, {
  TreeData,
} from './hooks/useCategoriesTree/useCategoriesTreeNew';
import {
  findEmojiNotAllowed,
  hasSavedDecendents,
  ID_ROOT,
  newEmptyNode,
} from './utils';
import './CategoriesListPage.scss';

function CategoriesListPageNew() {
  const { goTo } = useNavegate();
  const bottomRef = useRef<HTMLDivElement>(null);
  const t = useTranslationCatalog();
  const sender = useTrackFullCatalog();

  const {
    tree,
    treeFlatten,
    isLoading,
    isError,
    isSuccess,
    isDirty,
    showEmpty,
    showEmptyCategories,
    statusSave,
    closeEmpty,
    fetchTree,
    setTree,
    retry,
    restoreSavedTree,
    save,
  } = useCategoriesTreeNew();

  const [idSelected, setIdSelected] = useState<string>();
  const [pathSelected, setPathSelected] = useState<number[]>();

  const {
    handleAddSubcategory,
    handleRemoveCategory,
    handleChangeInput,
    handleAddCategory,
  } = useCategoriesHandlers(tree, setTree, setIdSelected);

  const [showCancel, openCancel, closeCancel] = useModal();
  const handleConfirmOnCancelModal = () => {
    restoreSavedTree();
    closeCancel();
  };

  const [
    showDeleteSavedDecendents,
    openDeleteSavedDecendents,
    closeDeleteSavedDecendents,
  ] = useModal();
  const handleConfirmDeleteSavedDecendents = () => {
    closeDeleteSavedDecendents();
    if (pathSelected) handleRemoveCategory(pathSelected);
  };

  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();
  const handleOpenDeleteModal = (rowInfo: NodeData) => {
    setIdSelected(rowInfo.node.id);
    setPathSelected(rowInfo.path);
    openDeleteModal();
  };

  const handleDelete = (rowInfo: NodeData) => {
    // Node with some decendent saved
    if (hasSavedDecendents(treeFlatten, rowInfo.node.id)) {
      setIdSelected(rowInfo.node.id);
      setPathSelected(rowInfo.path);
      openDeleteSavedDecendents();
      return;
    }
    handleRemoveCategory(rowInfo.path);
  };

  const handleDeletePersisted = () => {
    closeDeleteModal();
    if (pathSelected) handleRemoveCategory(pathSelected);
  };

  const handleAddRow = () => {
    const emptyNode = newEmptyNode();
    const treeUpdated = [...tree, emptyNode];
    setIdSelected(emptyNode.id);
    setTree(treeUpdated as TreeData[]);
    bottomRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'end',
    });
  };

  const handleCloseEmptyState = () => {
    closeEmpty();
    handleAddRow();
  };

  const handleCancel = () => {
    if (isDirty) openCancel();
  };

  const handleGoToDetails = (id: string) => {
    setIdSelected(id);
    goTo(`/categories/${id}`);
  };

  const categoryOrSubcategory =
    treeFlatten.find((current) => current.id === idSelected)?.parent === ID_ROOT
      ? 'category'
      : 'subcategory';

  const emptyFieldsCount = treeFlatten.filter(
    (current) => current.text === '',
  ).length;

  const hasCategories = !showEmpty && isSuccess && tree.length > 0;

  const showCancelAndSaveButtons = isDirty && !findEmojiNotAllowed(treeFlatten);

  useEffect(() => {
    fetchTree();
  }, [fetchTree]);

  const handleClick = () => {
    sender(trackingCategoriesCreateButtonClick);
    handleAddRow();
  };

  return (
    <div className="stratus--category-page">
      <CategoriesPage
        handleSave={save}
        statusSave={statusSave}
        handleAddRow={handleClick}
        handleRetry={retry}
        isSuccess={isSuccess}
        showEmpty={showEmpty}
        isDirty={isDirty}
        isError={isError}
      >
        {isLoading && <DraggableTree.Skeleton />}
        {showEmpty && <CategoriesListEmpty onClose={handleCloseEmptyState} />}
        <Alert show={showEmptyCategories && isDirty} appearance="danger">
          {t('categories.emptyField', { count: emptyFieldsCount })}
        </Alert>
        {hasCategories && (
          <DraggableTreeNew
            data={tree}
            onChange={setTree}
            renderItem={(rowInfo) => ({
              title: (
                <CategoryNodeNew
                  autofocus={rowInfo.node.id === idSelected}
                  node={rowInfo.node}
                  onChange={(e) => handleChangeInput(e, rowInfo)}
                  showEmptyCategories={showEmptyCategories}
                  onPressEnter={() => handleAddCategory(rowInfo.path)}
                />
              ),
              buttons: [
                <Actions
                  key="actions"
                  isAdding={rowInfo.node?.id.startsWith('new-')}
                  depth={rowInfo.path.length}
                  onAddSubcategory={() => handleAddSubcategory(rowInfo)}
                  onAddCategory={() => handleAddCategory(rowInfo.path)}
                  onDelete={() => handleDelete(rowInfo)}
                  onGoToDeatils={() => handleGoToDetails(rowInfo.node.id)}
                  openDeleteModal={() => handleOpenDeleteModal(rowInfo)}
                />,
              ],
            })}
          />
        )}
        {showCancelAndSaveButtons && (
          <CancelAndSaveButtons
            saveText={t('categories.save')}
            cancelText={t('categories.cancel')}
            onCancel={handleCancel}
            onSave={save}
            isDisabled={statusSave === 'saving'}
            isLoading={statusSave === 'saving'}
          />
        )}
        <div ref={bottomRef} />
        <ModalConfirmationBase
          show={showCancel}
          onConfirm={handleConfirmOnCancelModal}
          onCancel={closeCancel}
        />
        <ModalConfirmationBase
          show={showDeleteModal}
          title={t(`categories.detail.delete.${categoryOrSubcategory}.title`)}
          text={t(`categories.detail.delete.${categoryOrSubcategory}.info`)}
          label={t('categories.detail.delete.ok')}
          labelCancel={t('categories.detail.delete.cancel')}
          onConfirm={handleDeletePersisted}
          onCancel={closeDeleteModal}
        />
        <ModalConfirmationBase
          show={showDeleteSavedDecendents}
          title={t(
            `categories.deleteSavedDecendents.${categoryOrSubcategory}.title`,
          )}
          text={t(
            `categories.deleteSavedDecendents.${categoryOrSubcategory}.info`,
          )}
          label={t('categories.deleteSavedDecendents.label')}
          appearance="primary"
          onConfirm={handleConfirmDeleteSavedDecendents}
          onCancel={closeDeleteSavedDecendents}
        />
      </CategoriesPage>
    </div>
  );
}

export default CategoriesListPageNew;
