import { useEffect } from 'react';
import { Stack, Title } from '@tiendanube/components';
import { ErrorScreen } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { InvoiceHistoryDataTable } from './components';
import InvoiceHistoryWebSkeleton from './InvoiceHistoryWebSkeleton';
import useInvoiceHistoryWeb from './useInvoiceHistoryWeb';
import TaxCondition from '../TaxCondition';

function InvoiceHistoryWeb(): JSX.Element {
  const t = useTranslationBilling();

  const { fetchInvoiceHistory, invoiceHistory, status } =
    useInvoiceHistoryWeb();

  const getInvoiceHistory = () => fetchInvoiceHistory(1);

  useEffect(() => {
    fetchInvoiceHistory(1);
  }, [fetchInvoiceHistory]);

  const handlePageSelect = (page) => {
    fetchInvoiceHistory(page);
  };

  if (status === 'error')
    return (
      <ErrorScreen
        description={t('invoiceHistory.errors.load')}
        onRetry={getInvoiceHistory}
      />
    );

  return (
    <div className="invoice-history-web">
      <Stack column spacing="loose" align="stretch">
        <TaxCondition />
        <Stack column spacing="base" align="flex-start">
          <Title type="h3">{`${t('invoiceHistory.subTitle')}`}</Title>
          <InvoiceHistoryDataTable
            onPageSelect={handlePageSelect}
            pagination={invoiceHistory.pagination}
            data={invoiceHistory.results}
            status={status}
          />
        </Stack>
      </Stack>
    </div>
  );
}

InvoiceHistoryWeb.Skeleton = InvoiceHistoryWebSkeleton;

export default InvoiceHistoryWeb;
