import { Box, Card, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import {
  ALIEXPRESS_DROPSHIPPING_APP_STORE_LINK,
  DROPSHIPPING_APP_INSTALL_LINK,
  DROPSHIPPING_APP_STORE_LINK,
} from 'commons/constants';
import goToAdmin from 'commons/utils/gotToAdmin';
import { CATALOG_INSTALL_DROPI_APP } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  trackingCoreAppStoreDropshippingAppClick,
  trackingCoreAppStoreDropshippingListClick,
} from 'domains/Catalog/Products/tracking';
import aliExpress from './ali-express.png';

function DropshippingAppCard(): JSX.Element {
  const t = useTranslationCatalog();

  const onClickDropshippingInstallApp = useUpsellFlow({
    title: t('products.emptyResults.dropshipping.upsellTitle'),
    featureKey: 'dropshipping',
    callback: () => {
      trackingCoreAppStoreDropshippingAppClick();
      goToAdmin(DROPSHIPPING_APP_INSTALL_LINK)();
    },
    trackingSource: CATALOG_INSTALL_DROPI_APP,
  });

  const onClickDropshippingList = () => {
    trackingCoreAppStoreDropshippingListClick();
  };

  return (
    <Card>
      <Box display="flex" gap="4" flexWrap="nowrap">
        <Box>
          <img width="40px" height="40px" src={aliExpress} />
        </Box>
        <Box display="flex" flexDirection="column" gap="2">
          <Link
            as="a"
            textDecoration="none"
            target="_blank"
            href={ALIEXPRESS_DROPSHIPPING_APP_STORE_LINK}
          >
            <Text
              fontSize="highlight"
              color="primary-interactive"
              fontWeight="bold"
            >
              {t('products.emptyResults.dropshipping.card.title')}
            </Text>
          </Link>
          <Text>{t('products.emptyResults.dropshipping.card.body')}</Text>
          <Box display="flex" gap="4" flexWrap="wrap">
            <Link
              appearance="primary"
              as="a"
              target="_blank"
              textDecoration="none"
              onClick={onClickDropshippingInstallApp}
            >
              {t('products.emptyResults.dropshipping.card.action_primary')}
            </Link>
            <Link
              appearance="primary"
              as="a"
              target="_blank"
              textDecoration="none"
              href={DROPSHIPPING_APP_STORE_LINK}
              onClick={onClickDropshippingList}
            >
              {t('products.emptyResults.dropshipping.card.action_secondary')}
              <Icon color="primary-interactive" source={<ExternalLinkIcon />} />
            </Link>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default DropshippingAppCard;
