import isEmpty from 'lodash.isempty';
import { FiltersType } from './constants';

interface fetchOrdersPayloadInterface extends FiltersType {
  metafields: Record<string, string>[];
  variantIds: string[];
}

export function fetchOrderDigest(filtersParams: FiltersType) {
  let othersFilters: Partial<fetchOrdersPayloadInterface> = {};
  let metafieldsFilters = {};
  Object.entries(filtersParams).forEach(([key, value]) => {
    if (key.startsWith('mf-') && value !== '') {
      metafieldsFilters = {
        ...metafieldsFilters,
        [key.split('mf-')[1]]: value,
      };
      return;
    }
    if (key === 'products') {
      const variantIds = value ? value.toString().split(';') : [];
      if (!isEmpty(variantIds)) {
        othersFilters = {
          ...othersFilters,
          variantIds,
        };
      }
      return;
    }
    if (value !== '') {
      othersFilters = { ...othersFilters, [key]: value };
    }
  });
  const othersFiltersWithPage = {
    ...othersFilters,
    page: `${othersFilters.page}`,
    perPage: `${othersFilters.perPage}`,
  };
  if (isEmpty(metafieldsFilters)) {
    return othersFiltersWithPage;
  }
  return {
    ...othersFiltersWithPage,
    metafields: Object.entries(metafieldsFilters),
  };
}
