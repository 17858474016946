import { useEffect } from 'react';
import { Text, Alert, Box } from '@nimbus-ds/components';
import { Status } from '@tiendanube/common/src/enums';
import { Button, Input, Select, Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { DateFilter } from 'commons/components';
import {
  OrderApplicationsFilter,
  OrderPaymentMethodsFilter,
} from 'domains/Orders/components';
import OrderPaymentProvidersFilter from 'domains/Orders/components/OrderPaymentProvidersFilter';
import useGetOrdersFilters from 'domains/Orders/Orders/hooks/useGetOrdersFilters/useGetOrdersFilters';
import {
  ExportFiltersType,
  FilterStatusEnum,
} from 'domains/Orders/Orders/ordersService';
import { validateDateLimit } from 'domains/Orders/Orders/utils/OrderListFilter';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { APP_ID_FILTER_RELEASE_DATE } from '../../../constants';
import { useOrdersExportFilters } from '../../hooks';
import useNewFiltersAlert from '../../hooks/useNewFiltersAlert';

interface OrdersExportFiltersModalProps {
  show: boolean;
  filters: ExportFiltersType;
  onConfirm: (filters: ExportFiltersType) => void;
  onClose: () => void;
}

function OrdersExportFiltersModal({
  show,
  filters,
  onConfirm,
  onClose,
}: OrdersExportFiltersModalProps) {
  const t = useTranslationOrders();
  const {
    values,
    statusOptions,
    fulfillmentStatusOptions,
    paymentOptions,
    paymentMethodOptions,
    paymentProviderOptions,
    shippingMethodOptions,
    originOptions,
    handleChange,
    handleDateRangeChange,
    setAllValues,
    applicationOptions,
  } = useOrdersExportFilters(filters);
  const { fetchOrdersFilters } = useGetOrdersFilters();

  const applicationFilterAlert = {
    ...useNewFiltersAlert(
      new Date(APP_ID_FILTER_RELEASE_DATE),
      'new_app_id_filter_alert',
    ),
  };

  const handleApplicationFiltersClick = () =>
    applicationFilterAlert.closeAlert();

  const handleConfirm = () => {
    onConfirm(validateDateLimit(values));
    onClose();
  };

  const handleCancel = () => {
    setAllValues(filters);
    onClose();
  };

  useEffect(() => {
    setAllValues(filters);
  }, [filters, setAllValues]);

  useEffect(() => {
    fetchOrdersFilters();
  }, [fetchOrdersFilters]);

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={onClose}
      headerContent={t('exportPage.filtersTitle')}
      footer={
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          width="100%"
          gap="4"
          flex="1"
        >
          <Button onClick={handleCancel}>{`${t('filter.cancel')}`}</Button>
          <Button onClick={handleConfirm} appearance="primary">
            {`${t('filter.filter')}`}
          </Button>
        </Box>
      }
      footerHeight="70px"
    >
      <Stack column align="stretch">
        <Stack.Item>
          <Alert
            appearance="primary"
            title={t('filters.application.alert.title')}
            show={applicationFilterAlert.showAlert}
            onRemove={handleApplicationFiltersClick}
          >
            <Text>{t('filters.application.alert.text')}</Text>
          </Alert>
        </Stack.Item>
        <Stack.Item>
          <Input
            name="q"
            label={t('filter.search')}
            placeholder={t('filter.searchPlaceholder')}
            value={values.q}
            onChange={handleChange}
          />
        </Stack.Item>
        <Stack.Item>
          <Select
            label={t('filter.status')}
            name="status"
            value={
              values.status === FilterStatusEnum.EMPTY
                ? Status.OPEN
                : values.status
            }
            options={statusOptions}
            onChange={handleChange}
          />
        </Stack.Item>
        <Stack.Item>
          <DateFilter
            label={t('filter.dateRange')}
            dateFrom={values.dateFrom}
            dateTo={values.dateTo}
            onChange={handleDateRangeChange}
            threeMonthsLimited={
              values.paymentMethods !== '' || values.paymentProvider !== ''
            }
          />
        </Stack.Item>
        <Stack.Item>
          <Select
            label={t('filter.fulfillmentStatus')}
            name="fulfillmentStatus"
            value={values.fulfillmentStatus}
            options={fulfillmentStatusOptions}
            onChange={handleChange}
          />
        </Stack.Item>
        <Stack.Item>
          <Select
            label={t('filter.paymentStatus')}
            name="paymentStatus"
            value={values.paymentStatus}
            options={paymentOptions}
            onChange={handleChange}
          />
        </Stack.Item>
        <OrderPaymentMethodsFilter
          paymentMethods={values.paymentMethods}
          paymentMethodOptions={paymentMethodOptions}
          handleChange={handleChange}
        />
        <OrderPaymentProvidersFilter
          paymentProviders={values.paymentProvider}
          paymentProviderOptions={paymentProviderOptions}
          handleChange={handleChange}
        />
        <Stack.Item>
          <Select
            label={t('filter.shippingMethod')}
            name="shippingMethod"
            value={values.shippingMethod}
            options={shippingMethodOptions}
            onChange={handleChange}
          />
        </Stack.Item>
        <Stack.Item>
          <Select
            label={t('filter.origin')}
            name="origin"
            value={values.origin}
            options={originOptions}
            onChange={handleChange}
          />
        </Stack.Item>
        <OrderApplicationsFilter
          application={values.appId}
          applicationOptions={applicationOptions}
          handleChange={handleChange}
        />
      </Stack>
    </ModalAside>
  );
}

export default OrdersExportFiltersModal;
