import { Table, Tag, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { Skeleton } from './Skeleton';
import { Actions } from '../../Actions';
import { DomainSSLStatusTag } from '../../DomainSSLStatusTag';
import { DomainStatusTag } from '../../DomainStatusTag';
import { PrimaryDomainTag } from '../../PrimaryDomainTag';
import { ReactivateDomainLink } from '../../ReactivateDomainLink';
import { DomainsListRowProps } from '../../types';

export function DomainsListDesktopRow({
  domain,
  domainsCount,
  ourDomain,
  isDeleting,
  isUpdating,
  onDeleteDomain,
  onSetPrimaryDomain,
}: Readonly<DomainsListRowProps>) {
  const t = useTranslationConfigurations('domains');

  return (
    <Table.Row>
      <Table.Cell>
        <Stack spacing="tight" wrap>
          <Text lineClamp={1}>{domain.name}</Text>
          {domain.isOurDomain && (
            <Tag appearance="neutral">{t('tags.default')}</Tag>
          )}
          {domain.isPrimaryDomain && <PrimaryDomainTag />}
        </Stack>
      </Table.Cell>
      <Table.Cell>
        {!domain.isOurDomain && isUpdating ? (
          <Tag.Skeleton />
        ) : (
          <Stack spacing="tight" wrap>
            <DomainStatusTag
              isOurDomain={domain.isOurDomain}
              status={domain.status.domain}
            />
            {!domain.isOurDomain && <ReactivateDomainLink />}
          </Stack>
        )}
      </Table.Cell>
      <Table.Cell>
        {!domain.isOurDomain && isUpdating ? (
          <Tag.Skeleton />
        ) : (
          <DomainSSLStatusTag
            isOurDomain={domain.isOurDomain}
            status={domain.status.ssl}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <Actions
          domain={domain}
          ourDomain={ourDomain}
          onDelete={onDeleteDomain}
          domainsCount={domainsCount}
          isDeleting={isDeleting}
          onSetPrimaryDomain={onSetPrimaryDomain}
        />
      </Table.Cell>
    </Table.Row>
  );
}

DomainsListDesktopRow.Skeleton = Skeleton;
