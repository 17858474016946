import { useEffect, useRef } from 'react';
import { Layout } from '@nimbus-ds/patterns';
import isEmpty from 'lodash.isempty';
import { Prompt } from 'react-router';
import { CouponStatus } from '@tiendanube/common';
import { InterfaceNameValue } from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons } from 'commons/components';
import { useToast } from 'commons/hooks';
import { InterfaceNameBooleanValue } from 'commons/types';
import {
  CategoriesInterface,
  ProductsInterface,
} from 'domains/Marketing/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  ApplyTo,
  CouponCode,
  DiscountType,
  Limits,
  CouponUsedAlert,
  CouponFormSkeleton,
  CouponHistory,
} from './components';
import { useComponentHeight } from './components/CouponHistory/hooks/useComponentHeight';
import { CouponErrorsInterface, CouponValuesInterface } from './types';
import { trackingExitEditCouponPage } from '../../tracking';
import { UNIQUE_CODE } from '../../utils';

interface CouponFormProps {
  setFieldValue: (name: string, value: any) => void;
  values: CouponValuesInterface;
  errors: Partial<CouponErrorsInterface>;
  isValidating: boolean;
  isLoading: boolean;
  handleOnChange: (
    values: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
  handleOnSubmit: () => void;
  isError: boolean;
  errorMessageTranslation: string;
  clearError: () => void;
  errorMessage: string;
  isDirty: boolean;
  limitsCollapsed: boolean;
  showActiveInput: boolean;
  couponIsUsed: boolean;
  currentUses: number;
  confirmText: string;
  readOnly?: boolean;
  couponId?: string;
  couponStatus?: CouponStatus;
}

export function CouponForm({
  setFieldValue,
  values,
  errors,
  isValidating,
  isLoading,
  handleOnChange,
  handleOnSubmit,
  isError,
  errorMessageTranslation,
  clearError,
  errorMessage,
  isDirty,
  limitsCollapsed,
  showActiveInput,
  couponIsUsed,
  currentUses,
  couponStatus,
  confirmText,
  readOnly = false,
  couponId,
}: Readonly<CouponFormProps>) {
  const { goBack } = useNavegate();
  const { scrollToTop } = useLayoutScroll();
  const { addToast } = useToast();
  const t = useTranslationMarketing();
  const ref = useRef<HTMLDivElement>(null);
  const { height } = useComponentHeight({ mainContainerRef: ref });

  const handleChangeCategories = (categories: CategoriesInterface[]) => {
    setFieldValue('categories', categories);
  };

  const handleChangeProducts = (products: ProductsInterface[]) => {
    setFieldValue('products', products);
  };

  const handleCancel = () => {
    trackingExitEditCouponPage(couponId);
    goBack();
  };

  const errorDuplicateCode =
    errorMessage === UNIQUE_CODE
      ? t('coupons.couponForm.errors.code.duplicate')
      : '';

  useEffect(() => {
    if (isError && errorMessage !== UNIQUE_CODE) {
      addToast({
        label: errorMessageTranslation,
        appearance: 'danger',
      });
      clearError();
    }
  }, [isError, clearError, addToast, t, errorMessage, errorMessageTranslation]);

  useEffect(() => {
    if (values.startDate > values.endDate)
      setFieldValue('endDate', values.startDate);
  }, [setFieldValue, values.endDate, values.startDate]);

  useEffect(() => {
    if (!isEmpty(errors) || errorDuplicateCode) {
      scrollToTop();
    }
  }, [errors, errorDuplicateCode, scrollToTop]);

  const handleChangeCode = (values: InterfaceNameValue) => {
    clearError();
    handleOnChange(values);
  };

  const showCouponHistory = !!couponId;

  return (
    <>
      <Prompt when={isDirty} message={t('common:exitEdit.info')} />
      <CouponUsedAlert couponIsUsed={couponIsUsed && !readOnly} />
      <div ref={ref}>
        <Layout
          columns={showCouponHistory ? '2 - asymmetric' : '1'}
          grid-template-rows="auto"
        >
          <Layout.Section>
            <CouponCode
              value={values.couponCode}
              couponStatus={couponStatus}
              error={errors.couponCode ?? errorDuplicateCode}
              onChange={handleChangeCode}
              disabled={couponIsUsed || readOnly}
            />
            <DiscountType
              values={values}
              errors={errors}
              onChange={handleOnChange}
              disabled={couponIsUsed || readOnly}
            />
            <Limits
              values={values}
              errors={errors}
              onChange={handleOnChange}
              collapsed={limitsCollapsed}
              showActiveInput={showActiveInput}
              currentUses={currentUses}
              disabled={readOnly}
            />
            <ApplyTo
              values={values}
              errors={errors}
              onChange={handleOnChange}
              onChangeCategories={handleChangeCategories}
              onChangeProducts={handleChangeProducts}
              collapsed={limitsCollapsed}
              disabled={readOnly}
            />
          </Layout.Section>
          {showCouponHistory && (
            <Layout.Section>
              <CouponHistory couponId={couponId} height={height} />
            </Layout.Section>
          )}
        </Layout>
      </div>
      {!readOnly && (
        <CancelAndConfirmButtons
          isLoading={isValidating || isLoading}
          confirmText={confirmText}
          isCancelDisabled={isLoading}
          isConfirmDisabled={isValidating || isLoading}
          onCancel={handleCancel}
          onConfirm={handleOnSubmit}
        />
      )}
    </>
  );
}

CouponForm.Skeleton = CouponFormSkeleton;

export default CouponForm;
