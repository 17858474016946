import { Label } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { AiFeature, Domain } from '@tiendanube/common/src/enums';
import { Input, Stack } from '@tiendanube/components';
import {
  DEFAULT_LANGUAGE,
  LanguageNameValueInterface,
  LanguageInterface,
  LanguagesType,
} from 'App/i18n';
import GenerateWithAiLink from 'commons/components/GenerateWithAiLink';
import { useHeaderMenuData } from 'domains/Auth/hooks';
import Skeleton from './Skeleton';
import SeoCategoriesGenerationModal from '../SeoCategoriesGenerationModal';

export interface FormSeoCategoriesProps {
  seoTitle: LanguageInterface;
  seoDescription: LanguageInterface;
  categoryId?: string;
  name?: LanguageInterface;
  description?: LanguageInterface;
  handle: LanguageInterface;
  handleChange: (data: LanguageNameValueInterface) => void;
  language?: LanguagesType;
  errors?: Partial<Record<string, string>>;
  showAiModal: boolean;
  openAiModal: () => void;
  closeAiModal: () => void;
}

function FormSeoCategories({
  seoTitle,
  seoDescription,
  categoryId,
  name,
  description,
  handle,
  handleChange,
  language = DEFAULT_LANGUAGE,
  errors,
  showAiModal,
  openAiModal,
  closeAiModal,
}: FormSeoCategoriesProps): JSX.Element {
  const { urlReduced } = useHeaderMenuData();
  const { t } = useTranslation(Domain.CATALOG);

  const handleOnSeoTitle = (data: LanguageNameValueInterface) => {
    handleChange({
      name: data.name,
      value: { ...seoTitle, [language]: data.value },
    });
  };

  const handleOnSeoDescription = (data: LanguageNameValueInterface) => {
    handleChange({
      name: data.name,
      value: { ...seoDescription, [language]: data.value },
    });
  };

  const handleOnSeoHandle = (data: LanguageNameValueInterface) => {
    handleChange({
      name: data.name,
      value: { ...handle, [language]: data.value },
    });
  };

  const errorHandle = errors?.[`handle.${language}`];
  const errorSeoTitle = errors?.[`seoTitle.${language}`];
  const errorSeoDescription = errors?.[`seoDescription.${language}`];

  const helpTextSeoTitle = errorSeoTitle
    ? t(errorSeoTitle)
    : t('categories.detail.seoCategory.form.titleHelperText', {
        count: seoTitle[language]?.length || 0,
      });

  const helpTextSeoDescription = errorSeoDescription
    ? t(errorSeoDescription)
    : t('categories.detail.seoCategory.form.descriptionHelperText', {
        count: seoDescription[language]?.length || 0,
      });

  const allowAiGeneration =
    categoryId !== undefined && name !== undefined && description !== undefined;

  const handleUseContent = (
    generatedSeoTitle: string,
    generatedSeoDescription: string,
  ) => {
    handleChange({
      name: 'seoTitle',
      value: { ...seoTitle, [language]: generatedSeoTitle },
    });
    handleChange({
      name: 'seoDescription',
      value: { ...seoDescription, [language]: generatedSeoDescription },
    });
  };

  return (
    <>
      <Stack column align="stretch">
        <Stack column align="stretch" spacing="tight">
          <Stack spacing="tight">
            <Stack.Item>
              <Label htmlFor="input_seoTitle">
                {t('categories.detail.seoCategory.form.title')}
              </Label>
            </Stack.Item>
            <Stack.Item>
              <GenerateWithAiLink
                trackingFeature={AiFeature.AI_CATEGORIES_SEO}
                fontSize="caption"
                onClick={openAiModal}
              />
            </Stack.Item>
          </Stack>
          <Input
            value={seoTitle[language]}
            name="seoTitle"
            onChange={handleOnSeoTitle}
            maxLength={70}
            placeholder={t(
              'categories.detail.seoCategory.form.titlePlaceholder',
            )}
            helpText={helpTextSeoTitle}
            appearance={errorSeoTitle ? 'validation_error' : undefined}
          />
        </Stack>
        <Stack column align="stretch" spacing="tight">
          <Stack spacing="tight">
            <Stack.Item>
              <Label htmlFor="input_seoDescription">
                {t('categories.detail.seoCategory.form.description')}
              </Label>
            </Stack.Item>
            <Stack.Item>
              <GenerateWithAiLink fontSize="caption" onClick={openAiModal} />
            </Stack.Item>
          </Stack>
          <Input
            value={seoDescription[language]}
            name="seoDescription"
            onChange={handleOnSeoDescription}
            maxLength={320}
            placeholder={t(
              'categories.detail.seoCategory.form.descriptionPlaceholder',
            )}
            rows={3}
            multiRows
            helpText={helpTextSeoDescription}
            appearance={errorSeoDescription ? 'validation_error' : undefined}
          />
        </Stack>
        <Stack.Item>
          <Input
            label={t('categories.detail.seoCategory.form.url')}
            value={handle[language]}
            name="handle"
            onChange={handleOnSeoHandle}
            placeholder=""
            prependLabel={urlReduced}
            appearance={errorHandle ? 'validation_error' : undefined}
            helpText={errorHandle ? t(errorHandle) : ''}
          />
        </Stack.Item>
      </Stack>

      {allowAiGeneration && (
        <SeoCategoriesGenerationModal
          show={showAiModal}
          onDismiss={closeAiModal}
          onUseContent={handleUseContent}
          category={{
            id: categoryId,
            name: name[language] || '',
            description: description[language] || '',
            lang: language,
          }}
        />
      )}
    </>
  );
}

FormSeoCategories.Skeleton = Skeleton;

export default FormSeoCategories;
