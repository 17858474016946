import { Box, Checkbox, Text, Thumbnail } from '@nimbus-ds/components';
import { CurrencyType } from '@tiendanube/common';
import { CurrencyPrice, useResponsive } from 'commons/components';
import { OrderEditProduct } from 'domains/Orders/Orders/pages/OrderEditPage/components/Products/types';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { EditOrderProductActions } from './EditOrderProductActions';
import { EditOrderProductTag } from './EditOrderProductTag';
import { EditProductQuantity } from './EditProductQuantity';
import './EditOrderProductItem.scss';

interface EditOrderProductItemProps {
  product: OrderEditProduct;
  currency: CurrencyType;
  onEditProduct: (product: OrderEditProduct) => void;
  onRemoveProduct: (productId: string, variantId: string) => void;
  onReAddProduct: (productId: string, variantId: string) => void;
  onRestoreStock: (
    productId: string,
    variantId: string,
    isRestore: boolean,
  ) => void;
  editable: boolean;
}

function EditOrderProductItem({
  product,
  currency,
  onEditProduct,
  onRemoveProduct,
  onReAddProduct,
  onRestoreStock,
  editable,
}: Readonly<EditOrderProductItemProps>) {
  const { isMobile } = useResponsive();
  const t = useTranslationOrders();
  const titleColor =
    product.editType === 'delete' ? 'neutral-textLow' : 'neutral-textHigh';

  const handleRestoreStock = () => {
    onRestoreStock(product.productId, product.variantId, !product.restoreStock);
  };

  return (
    <Box display="flex" flex="1">
      <Box maxHeight="80px">
        <div
          className={
            product.editType === 'delete'
              ? 'stratus--edit-order-product-item-container'
              : ''
          }
        >
          <Thumbnail
            id={`${product.productId}-${product.variantId}`}
            src={product.photoUrl}
            title={product.title}
            alt={product.title}
            width={isMobile ? '48px' : '80px'}
            aspectRatio="1/1"
          />
        </div>
      </Box>
      <Box paddingLeft="4" display="flex" flexDirection="column" flex="1">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flex="1"
        >
          <Box display="flex" flexDirection="column">
            <Text fontSize="highlight" fontWeight="bold" color={titleColor}>
              {product.title}
            </Text>
            {!!product.variants && (
              <Text fontSize="base" color="neutral-textLow">
                {product.variants}
              </Text>
            )}
            {!!product.sku && (
              <Box paddingTop="1">
                <Text fontSize="caption" color="neutral-textLow">
                  SKU: {product.sku}
                </Text>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" paddingLeft="2">
            <CurrencyPrice
              price={product.totalPrice}
              currency={currency}
              textAlign="right"
              appearance="light"
              block
              size="base"
            />
            {editable && (
              <Box paddingLeft="4">
                <EditOrderProductActions
                  product={product}
                  onRemoveProduct={onRemoveProduct}
                  onReAddProduct={onReAddProduct}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box paddingTop="1">
          <EditProductQuantity
            product={product}
            currency={currency}
            onSubmit={onEditProduct}
            editable={editable}
          />
        </Box>
        {!!product.editType && (
          <>
            <Box
              paddingY="2"
              flexDirection={isMobile ? 'column' : 'row'}
              display="flex"
              alignItems={isMobile ? 'flex-start' : 'flex-end'}
            >
              <EditOrderProductTag editType={product.editType} />
              {product.editType === 'delete' && (
                <Box
                  paddingLeft={isMobile ? 'none' : '2'}
                  paddingY={isMobile ? '2' : 'none'}
                  minWidth="100%"
                >
                  <Checkbox
                    name={`remove-stock-${product.productId}-${product.variantId}`}
                    label={t('editOrders.productList.restoreStock')}
                    checked={product.restoreStock}
                    onChange={handleRestoreStock}
                  />
                </Box>
              )}
            </Box>
            {product.editType !== 'add' && (
              <Text fontSize="caption">
                {t('editOrders.productList.originalQuantity', {
                  quantity: product.originalQuantity,
                })}
              </Text>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default EditOrderProductItem;
