import SortableTree from '@nosferatu500/react-sortable-tree';
import classNames from 'classnames';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import DndProvider from 'App/components/DndProvider';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import CustomNode from './CustomNode';
import '@nosferatu500/react-sortable-tree/style.css';
import './DraggableTreeNew.scss';

const MAX_DEPTH = 14;

function DraggableTreeNew({ data, renderItem, onChange }) {
  const isMobileDevice = useIsMobileDevice();

  const containerClass = classNames('stratus--draggable-tree', {
    'stratus--draggable-tree__mobile': isMobileDevice,
  });

  return (
    <div className={containerClass}>
      <DndProvider>
        <SortableTree
          treeData={data}
          onChange={onChange}
          theme={FileExplorerTheme}
          maxDepth={MAX_DEPTH}
          canDrag={({ node }) => !node.noDragging}
          canDrop={({ nextParent }) => !nextParent || !nextParent.noChildren}
          nodeContentRenderer={(props) => <CustomNode {...props} />}
          generateNodeProps={renderItem}
          virtuosoProps={{
            useWindowScroll: true,
          }}
          overscan={{ main: 1000, reverse: 1000 }}
        />
      </DndProvider>
    </div>
  );
}

export default DraggableTreeNew;
