import { Tooltip, IconButton } from '@nimbus-ds/components';
import { SubcategoryIcon } from '@nimbus-ds/icons';
import { Popover, Stack } from '@tiendanube/components';
import {
  EllipsisIcon,
  PlusCircleIcon,
  TrashIcon,
  EditIcon,
} from '@tiendanube/icons';
import { useResponsive } from 'commons/components';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import {
  trackingCategoriesActionCategoryClick,
  trackingCategoriesActionDeleteClick,
  trackingCategoriesActionEditClick,
  trackingCategoriesActionSubcategoryClick,
} from 'domains/Catalog/Products/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import './Actions.scss';

const MAX_ADD_MOBILE = 5;
const MAX_ADD_DESKTOP = 14;

interface ActionsProps {
  isAdding: boolean;
  depth: number;
  onAddSubcategory: () => void;
  onAddCategory: () => void;
  onDelete: () => void;
  onGoToDeatils: () => void;
  openDeleteModal: () => void;
}

function Actions({
  isAdding,
  depth,
  onAddSubcategory,
  onAddCategory,
  onDelete,
  onGoToDeatils,
  openDeleteModal,
}: ActionsProps): JSX.Element {
  const t = useTranslationCatalog();
  const { isMobile } = useResponsive();

  const isMinorLimit = isMobile
    ? depth < MAX_ADD_MOBILE
    : depth < MAX_ADD_DESKTOP;
  const showAdd = isAdding && isMinorLimit;
  const showDelete = isAdding;
  const showEdit = !isAdding && !isMinorLimit;
  const showMenu = !isAdding && isMinorLimit;

  const sender = useTrackFullCatalog();

  const handleAddSubcategory = () => {
    sender(trackingCategoriesActionSubcategoryClick);
    onAddSubcategory();
  };

  const handleOnGoToDeatils = () => {
    sender(trackingCategoriesActionEditClick);
    onGoToDeatils();
  };

  const handleDelete = () => {
    sender(trackingCategoriesActionDeleteClick);
    openDeleteModal();
  };

  const handleOnAddCategory = () => {
    sender(trackingCategoriesActionCategoryClick);
    onAddCategory();
  };

  const menu = [
    {
      children: t('categories.actions.addSubcategory'),
      onClick: handleAddSubcategory,
      icon: SubcategoryIcon,
    },
    {
      children: t('categories.actions.edit'),
      onClick: handleOnGoToDeatils,
      icon: EditIcon,
    },
    {
      children: t('categories.actions.delete'),
      onClick: handleDelete,
      icon: TrashIcon,
    },
    {
      children: t('categories.actions.addCategory'),
      onClick: handleOnAddCategory,
      icon: PlusCircleIcon,
    },
  ];
  return (
    <Stack spacing="tight">
      {showAdd && (
        <Tooltip content={t('categories.actions.addSubcategory')}>
          <IconButton
            size="2rem"
            source={<PlusCircleIcon />}
            onClick={handleAddSubcategory}
          />
        </Tooltip>
      )}
      {showDelete && (
        <Tooltip content={t('categories.actions.delete')}>
          <IconButton size="2rem" source={<TrashIcon />} onClick={onDelete} />
        </Tooltip>
      )}
      {showEdit && (
        <Tooltip content={t('categories.actions.edit')}>
          <IconButton
            size="2rem"
            source={<EditIcon />}
            onClick={onGoToDeatils}
          />
        </Tooltip>
      )}
      {showMenu && (
        <div className="stratus--popover-custom">
          <Popover
            name="Menu"
            menu={menu}
            position="right"
            renderInitiator={(onClick) => (
              <Tooltip content={t('categories.actions.options')}>
                <IconButton
                  size="2rem"
                  source={<EllipsisIcon />}
                  onClick={onClick}
                />
              </Tooltip>
            )}
          />
        </div>
      )}
    </Stack>
  );
}

export default Actions;
