import { ChangeEvent, useEffect, useRef } from 'react';
import { TreeData } from '../../hooks/useCategoriesTree/useCategoriesTreeNew';
import { InputCategory } from '../CategoryNode/components';

interface CategoryNodeNewProps {
  node: TreeData;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  autofocus: boolean;
  showEmptyCategories: boolean;
  onPressEnter: () => void;
}

function CategoryNodeNew({
  node,
  onChange,
  autofocus,
  showEmptyCategories,
  onPressEnter,
}: CategoryNodeNewProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const isAdding = node?.id.startsWith('new-');

  useEffect(() => {
    if (autofocus) {
      inputRef?.current?.focus();
    }
  }, [autofocus]);

  return (
    <InputCategory
      ref={inputRef}
      value={node.text}
      isAdding={isAdding}
      showEmptyCategories={showEmptyCategories && node.text.length === 0}
      handleChange={onChange}
      onPressEnter={onPressEnter}
    />
  );
}

export default CategoryNodeNew;
