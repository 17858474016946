import { useStorage } from 'commons/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';

function useNewFiltersAlert(releaseDate: Date, key: string) {
  const { createdAt } = useStoreInfo();

  const storeIsNewer = new Date(createdAt) >= releaseDate;

  const [showAlert, setShowAlert] = useStorage<boolean>(key, !storeIsNewer);

  const closeAlert = () => setShowAlert(false);

  return { showAlert, closeAlert };
}

export default useNewFiltersAlert;
