import { Text, Box, Checkbox } from '@nimbus-ds/components';
import { AiFeature } from '@tiendanube/common/src/enums';
import { InterfaceNameValue } from '@tiendanube/components';
import { FEATURE_AI_PRODUCT_DESCRIPTION, TIER_1 } from 'App/features';
import { DEFAULT_LANGUAGE, LanguagesType } from 'App/i18n';
import { Input, Editor, useResponsive, Stack } from 'commons/components';
import GenerateWithAiLink from 'commons/components/GenerateWithAiLink';
import { useModal } from 'commons/hooks';
import { ONLINE_PAGES } from 'config/upsellFlowSources';
import { trackingAiGenerateCtaClick } from 'domains/Ai/tracking';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { FormPageProps } from '../../../FormPage';
import PageGenerationModal from '../../PageGenerationModal';

export interface InputsTitleAndContentProps extends FormPageProps {
  language?: LanguagesType;
}

function InputsTitleAndContent({
  values,
  onChange,
  errors,
  language = DEFAULT_LANGUAGE,
}: InputsTitleAndContentProps) {
  const t = useTranslationOnline('contentPages.formPage');
  const { isDesktop } = useResponsive();
  const [showAiModal, openAiModal, closeAiModal] = useModal();

  const handleOnOpenAiGenerationModal = () => {
    trackingAiGenerateCtaClick({ feature: AiFeature.AI_PAGES });
    openAiModal();
  };

  const handleOpenAiGenerationModal = useUpsellFlow({
    title: t('aiGeneration.upsell'),
    featureKey: FEATURE_AI_PRODUCT_DESCRIPTION,
    trackingSource: ONLINE_PAGES,
    callback: handleOnOpenAiGenerationModal,
    minValue: TIER_1,
    asAside: true,
  });

  const appearanceTitle = errors[`title.${language}`] ? 'danger' : undefined;

  const helpTextTitle = errors[`title.${language}`]
    ? t(errors[`title.${language}`] || '')
    : undefined;

  const helpTextContent = errors[`content.${language}`]
    ? t(errors[`content.${language}`] || '')
    : undefined;

  const handleChangeTitle = ({ name, value }: InterfaceNameValue) => {
    onChange({
      name,
      value: { ...values.title, [language]: value },
    });
  };

  const handleOnChangeEditor = (value: string) => {
    onChange({
      name: 'content',
      value: { ...values.content, [language]: value },
    });
  };

  const handleOnChangeShowInStore = ({ target: { name, checked: value } }) =>
    onChange({ name, value });

  const page = {
    name: values?.title?.[language] || '',
    content: values?.content?.[language] || '',
    lang: language,
  };

  const handleUseGeneratedContent = ({
    generatedPageContent,
    generatedSeoTitle,
    generatedSeoDescription,
  }) => {
    onChange({
      name: 'content',
      value: { ...values.content, [language]: generatedPageContent },
    });
    onChange({
      name: 'seoTitle',
      value: { ...values.seoTitle, [language]: generatedSeoTitle },
    });
    onChange({
      name: 'seoDescription',
      value: { ...values.seoDescription, [language]: generatedSeoDescription },
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap="4">
        <Input
          autoFocus
          label={t('title.label')}
          value={values?.title?.[language] || ''}
          name="title"
          type="text"
          placeholder={t('title.placeholder')}
          onChange={handleChangeTitle}
          onBlur={handleChangeTitle}
          appearance={appearanceTitle}
          helpText={helpTextTitle}
          required
        />

        <Box display="flex" flexDirection="column" gap="2">
          <Stack spacing="tight">
            <Stack.Item>
              <Text>{t('content')}</Text>
            </Stack.Item>
            <Stack.Item>
              <GenerateWithAiLink onClick={handleOpenAiGenerationModal} />
            </Stack.Item>
          </Stack>
          <Editor
            value={values?.content?.[language] || ''}
            onChange={handleOnChangeEditor}
            language={language}
            showToolbar={isDesktop}
          />
          {!!helpTextContent && (
            <Text color="danger-textLow">{helpTextContent}</Text>
          )}
        </Box>

        <Checkbox
          onChange={handleOnChangeShowInStore}
          name="showInStore"
          label={t('showInStore')}
          checked={!!values?.showInStore}
        />
      </Box>
      <PageGenerationModal
        show={showAiModal}
        onDismiss={closeAiModal}
        onUseContent={handleUseGeneratedContent}
        page={page}
      />
    </>
  );
}

export default InputsTitleAndContent;
