import { Stack } from '@tiendanube/components';
import { FEATURE_AI_PRODUCT_DESCRIPTION, TIER_1 } from 'App/features';
import TabsMultiLanguage from 'commons/components/TabsMultiLanguage';
import { useModal } from 'commons/hooks';
import { CATALOG_SEO_CONTENT } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import FormSeo from './FormSeo';
import { FormSeoProps } from './FormSeo/FormSeo';
import TagAndSeoItemTitle from '../TagAndSeoItemTitle';

export type SeoProps = Omit<
  FormSeoProps,
  'showAiModal' | 'openAiModal' | 'closeAiModal'
>;

function Seo(props: Readonly<SeoProps>): JSX.Element {
  const t = useTranslationCatalog();

  const [showAiModal, openAiModal, closeAiModal] = useModal();

  const handleOpenSeoGenerationModal = useUpsellFlow({
    title: t('products.detail.seo.aiGeneration.upsell'),
    featureKey: FEATURE_AI_PRODUCT_DESCRIPTION,
    trackingSource: CATALOG_SEO_CONTENT,
    callback: openAiModal,
    minValue: TIER_1,
    asAside: true,
  });

  return (
    <TagAndSeoItemTitle
      title={t('products.detail.seo.title')}
      description={t('products.detail.seo.description')}
    >
      <Stack wrap spacing="tight" align="stretch">
        <Stack.Item fill>
          <TabsMultiLanguage>
            <FormSeo
              {...props}
              showAiModal={showAiModal}
              openAiModal={handleOpenSeoGenerationModal}
              closeAiModal={closeAiModal}
            />
          </TabsMultiLanguage>
        </Stack.Item>
      </Stack>
    </TagAndSeoItemTitle>
  );
}

export default Seo;
