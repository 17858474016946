import { useMemo } from 'react';
import { Country, Gateway, Status } from '@tiendanube/common/src/enums';
import { InterfaceLabel } from '@tiendanube/components';
import { NEW_ADMIN_ORDER_DETAILS } from 'App/featuresFlags';
import { useNavegate } from 'App/hooks';
import { useResponsive } from 'commons/components';
import goToAdmin from 'commons/utils/gotToAdmin/goToAdmin';
import {
  useGetCountry,
  useGetTags,
  useHasPermission,
  useIsMobileDevice,
} from 'domains/Auth/hooks';
import { trackingTransactionLinkClick } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import NewCardPayment from './NewCardPayment';
import OldCardPayment from './OldCardPayment';
import { payment } from '../commons/Status/status';
import { OrderStatusType } from '../commons/useOrderStatus';

interface CardPaymentProps {
  order: OrderStatusType;
  isMarkingAsPaid: boolean;
  paidOrder: (
    gateway?: string,
    gatewayMethod?: string,
    gatewayName?: string,
  ) => void;
  partiallyPaidOrder: (method: string, name?: string) => void;
  isMakingPartialPayment: boolean;
}

function CardPayment({
  order,
  isMarkingAsPaid,
  paidOrder,
  partiallyPaidOrder,
  isMakingPartialPayment,
}: CardPaymentProps): JSX.Element {
  const { payment: orderPayment, status: orderStatus } = order;
  const { status, gateway = '', gatewayLink, installments } = orderPayment;

  const tags = useGetTags();
  const t = useTranslationOrders();
  const isMobile = useIsMobileDevice();
  const { goTo } = useNavegate();
  const country = useGetCountry();
  const isArchived = orderStatus === Status.ARCHIVED;
  const isCancelled = orderStatus === Status.CANCELLED;
  const { isDesktop } = useResponsive();
  const canSetPaymentReceived = useHasPermission('set_payment_received');

  const openGatewayLink = () => {
    trackingTransactionLinkClick();
    window.open(gatewayLink, '_blank');
  };

  const openNuvemPago = () => {
    if (country === Country.BR) goToAdmin('/nuvempago/dashboard')();
    else goTo('/apps/nuvempago');
  };

  const showInstallments = Number(installments) > 1;

  const actionButtonProps = {
    children: `${t(`cardPayment.action.${status}`)}`,
    onClick: () => paidOrder(),
    spinner: isMarkingAsPaid,
    disabled: isMarkingAsPaid,
  };

  const secondaryButton =
    isArchived || isCancelled ? undefined : actionButtonProps;

  const headerLabel = useMemo<InterfaceLabel>(() => {
    const paymentLabel = payment(status, gateway as Gateway);

    return {
      ...paymentLabel,
      id: status,
      label: `${t(`status.${paymentLabel.status}`)}`,
    };
  }, [t, status, gateway]);

  const showButton =
    ![Status.PAID, Status.REFUNDED].includes(status) && canSetPaymentReceived;

  const isNuvemPagoPayment = ['Pago Nube', 'Nuvem Pago'].includes(
    gateway || '',
  );

  return tags.includes(NEW_ADMIN_ORDER_DETAILS) ? (
    <NewCardPayment
      order={order}
      t={t}
      showButton={showButton}
      headerLabel={headerLabel}
      showInstallments={showInstallments}
      isNuvemPagoPayment={isNuvemPagoPayment}
      openGatewayLink={openGatewayLink}
      country={country}
      isMobile={isMobile}
      isDesktop={isDesktop}
      openNuvemPago={openNuvemPago}
      isArchived={isArchived}
      isMarkingAsPaid={isMarkingAsPaid}
      paidOrder={paidOrder}
      partiallyPaidOrder={partiallyPaidOrder}
      isMakingPartialPayment={isMakingPartialPayment}
    />
  ) : (
    <OldCardPayment
      order={order}
      t={t}
      showButton={showButton}
      secondaryButton={secondaryButton}
      headerLabel={headerLabel}
      showInstallments={showInstallments}
      isNuvemPagoPayment={isNuvemPagoPayment}
      openGatewayLink={openGatewayLink}
      country={country}
      isMobile={isMobile}
      openNuvemPago={openNuvemPago}
    />
  );
}

export default CardPayment;
