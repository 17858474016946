import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import {
  getFetchFiscalDataStatus,
  getInvoiceInfoExists,
} from 'domains/Billing/Invoices/invoicesSlice';
import {
  EmptyInvoiceList,
  InvoiceHistory,
  ErrorComponent,
  SkeletonComponent,
} from './components';

interface useInvoiceListPageResult {
  Component: FC;
}

function useInvoiceListPage(): useInvoiceListPageResult {
  const { fetchInvoiceInfo } = useGetInvoiceInfo();

  const invoiceInfoExists = useSelector(getInvoiceInfoExists);
  const status = useSelector(getFetchFiscalDataStatus);

  useEffect(() => {
    fetchInvoiceInfo();
  }, [fetchInvoiceInfo]);

  const getComponent = () => {
    if (status === 'loading') {
      return SkeletonComponent;
    } else if (status === 'success') {
      if (invoiceInfoExists) {
        return InvoiceHistory;
      } else {
        return EmptyInvoiceList;
      }
    } else {
      return ErrorComponent;
    }
  };

  return {
    Component: getComponent(),
  };
}

export default useInvoiceListPage;
