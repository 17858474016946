import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { PayOrderDto } from '@tiendanube/common';
import { PAY_ORDER_NEW_CHECKOUT_TAG } from 'App/featuresFlags';
import { useHasTags, useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { combineStatus } from 'commons/utils/combineStatus';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import goToAdmin from 'commons/utils/gotToAdmin/goToAdmin';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  GO_TO_TRANSACTION_FEES_AFTER_PAYMENT,
  useAfterPaymentRedirection,
} from 'domains/Billing/Checkout/hooks';
import {
  fetchTransactionFeeToPay as fetchTransactionFeeToPayAction,
  getOrCreatePayOrder as getOrCreatePayOrderAction,
  getTransactionFeeToPayStatus,
  fetchTransactionFeeToPaySelector,
  getPayOrderStatus,
} from 'domains/Billing/TransactionFees/TransactionFeesSlice';

function useTransactionFeeToPay(fetch = false) {
  const dispatch = useAppDispatch();
  const [hasNewCheckoutTag] = useHasTags(PAY_ORDER_NEW_CHECKOUT_TAG);
  const status = useSelector(getTransactionFeeToPayStatus);
  const transactionFeeToPay = useSelector(fetchTransactionFeeToPaySelector);
  const { goTo } = useNavegate();
  const { addAfterPayActionToPath } = useAfterPaymentRedirection();

  const refreshTransactionFeeToPay = useCallback(() => {
    dispatch(fetchTransactionFeeToPayAction());
  }, [dispatch]);

  const fetchTransactionFeeToPay = useCallback(() => {
    if (status === 'idle') {
      refreshTransactionFeeToPay();
    }
  }, [refreshTransactionFeeToPay, status]);

  const onPay = useCallback(async () => {
    const payOrder = (await dispatch(getOrCreatePayOrderAction()))
      .payload as PayOrderDto;
    if (hasNewCheckoutTag) {
      goTo(
        addAfterPayActionToPath(
          generatePath(CHECKOUT_ROUTES.choosePaymentMethodForPayOrder, {
            payOrderId: payOrder.id,
          }),
          GO_TO_TRANSACTION_FEES_AFTER_PAYMENT,
        ),
      );
    } else {
      goToAdmin(`/account/checkout/pay_order/${payOrder.id}`)();
    }
  }, [dispatch, hasNewCheckoutTag, goTo, addAfterPayActionToPath]);

  const payOrderStatus = useSelector(getPayOrderStatus);

  const shouldShowPaymentButton = useMemo(() => {
    const hasPaymentPendingOrFailed =
      ((!transactionFeeToPay?.recurrentPaymentActivated ||
        transactionFeeToPay?.manualPaymentActive) &&
        transactionFeeToPay?.paymentPending) ||
      transactionFeeToPay?.paymentFailed ||
      transactionFeeToPay?.paymentCancelled;

    return (
      transactionFeeToPay &&
      !transactionFeeToPay.isEmpty &&
      transactionFeeToPay.paymentAvailable &&
      hasPaymentPendingOrFailed
    );
  }, [transactionFeeToPay]);

  useEffect(() => {
    if (fetch) {
      fetchTransactionFeeToPay();
    }
  }, [fetch, fetchTransactionFeeToPay]);

  return {
    status,
    ...convertStatusTypeToObject(status),
    fetchTransactionFeeToPay,
    transactionFeeToPay,
    shouldShowPaymentButton,
    thereAreOrdersForCurrentMonth:
      transactionFeeToPay?.feeSummary?.thereAreOrdersForCurrentMonth,
    onPay,
    payOrderStatus: combineStatus(payOrderStatus),
    refreshTransactionFeeToPay,
  };
}

export default useTransactionFeeToPay;
