import { Alert, Box, Text, Title } from '@nimbus-ds/components';
import { Trans, useTranslation } from 'react-i18next';
import { CurrencyType } from '@tiendanube/common';
import { InterfaceText } from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import { formatCurrency } from 'commons/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import './SummarySubtotal.scss';

interface SummarySubtotalProps {
  readonly previousTotal: number;
  readonly newTotal: number;
  readonly currency: CurrencyType;
  readonly isInsufficientStock?: boolean;
  readonly isOrderEdited?: boolean;
}

function SummarySubtotalOld({
  previousTotal,
  newTotal,
  currency,
  isInsufficientStock,
  isOrderEdited,
}: SummarySubtotalProps) {
  const t = useTranslationOrders();
  const {
    i18n: { language },
  } = useTranslation();

  const getSummaryBackgroundColor = () => {
    if (!isOrderEdited) return 'neutral-surface';
    if (isInsufficientStock) return 'danger-surface';
    if (previousTotal === newTotal) return 'success-surface';
    return 'warning-surface';
  };

  const getTotalColor: () => InterfaceText['appearance'] = () => {
    if (!isOrderEdited) return 'default';
    if (isInsufficientStock) return 'default';
    if (previousTotal === newTotal) return 'success';
    return 'warning';
  };

  const getAlertAppearance = () => {
    if (isInsufficientStock) return 'danger';
    if (previousTotal === newTotal) return 'success';
    return 'warning';
  };

  const getAlertTextKey = () => {
    if (isInsufficientStock) return 'editOrders.summary.alert.missingStock';
    if (newTotal < previousTotal) return 'editOrders.summary.alert.lowerTotal';
    if (newTotal > previousTotal) return 'editOrders.summary.alert.higherTotal';
    return 'editOrders.summary.alert.equalTotal';
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius="2"
      padding="2"
      backgroundColor={getSummaryBackgroundColor()}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Title fontWeight="bold" fontSize="4" lineHeight="5">
          {t('editOrders.summary.subtotalOld')}
        </Title>
        <Box flexDirection="row" display="flex" paddingLeft="2">
          <CurrencyPrice
            price={newTotal}
            currency={currency}
            appearance={getTotalColor()}
            size="highlight"
            bold
          />
        </Box>
      </Box>
      {isOrderEdited && (
        <Box paddingTop="2">
          <Alert
            appearance={getAlertAppearance()}
            data-style="summary-difference-alert"
          >
            <Text color="currentColor">
              <Trans
                t={t}
                i18nKey={getAlertTextKey()}
                values={{
                  amount: formatCurrency(
                    Math.abs(newTotal - previousTotal),
                    currency,
                    language,
                  ),
                }}
                components={{
                  strong: <strong />,
                }}
              />
            </Text>
          </Alert>
        </Box>
      )}
    </Box>
  );
}

export default SummarySubtotalOld;
