import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Scope } from 'domains/Marketing/enums';

export const validationSchema = (currentUses: number) =>
  Yup.object().shape({
    couponCode: Yup.string()
      .required('coupons.couponForm.errors.code.required')
      .test('length', 'coupons.couponForm.errors.code.length', (value) => {
        if (!value) return true;
        return value.length <= 30;
      }),
    discountType: Yup.string(),
    valuePercentage: Yup.string()
      .test(
        'requiredPercentage',
        'coupons.couponForm.errors.valuePercentage.required',
        (_, { parent: { discountType, valuePercentage } }) =>
          (discountType === 'percentage' && Number(valuePercentage) > 0) ||
          discountType !== 'percentage',
      )
      .test(
        'maxPercentage',
        'coupons.couponForm.errors.valuePercentage.valueMax',
        (_, { parent: { discountType, valuePercentage } }) =>
          (discountType === 'percentage' && Number(valuePercentage) <= 100) ||
          discountType !== 'percentage',
      ),
    valueAbsolute: Yup.string().test(
      'requiredAbsolute',
      'coupons.couponForm.errors.valueAbsolute.required',
      (_, { parent: { discountType, valueAbsolute } }) =>
        (discountType === 'absolute' && Number(valueAbsolute) > 0) ||
        discountType !== 'absolute',
    ),
    limitedType: Yup.string(),
    maxUsesPerClient: Yup.string().test(
      'requiredMaxUsesPerClient',
      'coupons.couponForm.errors.maxUsesPerClient.required',
      (_, { parent: { maxUsesPerClient, clientLimitationType } }) =>
        (Number(maxUsesPerClient) >= 1 && clientLimitationType === 'limited') ||
        clientLimitationType !== 'limited',
    ),
    maxUses: Yup.string()
      .test(
        'requiredMaxUses',
        'coupons.couponForm.errors.maxUses.required',
        (_, { parent: { limitedType, maxUses } }) =>
          (limitedType === 'limited' && Number(maxUses) > 0) ||
          limitedType !== 'limited',
      )
      .test(
        'requiredMaxUsesGreaterThanCurrentUses',
        'coupons.couponForm.errors.maxUses.greaterThanCurrentUses',
        (_, { parent: { limitedType, maxUses } }) =>
          (limitedType === 'limited' && Number(maxUses) >= currentUses) ||
          limitedType !== 'limited',
      ),
    startDate: Yup.string().test(
      'formatStartDate',
      'coupons.couponForm.errors.startDate.format',
      (value) => dayjs(value, 'YYYY-MM-DD').isValid(),
    ),
    endDate: Yup.string().test(
      'formatEndDate',
      'coupons.couponForm.errors.endDate.format',
      (value) => dayjs(value, 'YYYY-MM-DD').isValid(),
    ),
    categories: Yup.array().when('scopeType', {
      is: Scope.CATEGORIES,
      then: Yup.array().min(1, 'coupons.couponForm.errors.categories.required'),
    }),
    products: Yup.array().when('scopeType', {
      is: Scope.PRODUCTS,
      then: Yup.array().min(1, 'coupons.couponForm.errors.products.required'),
    }),
  });
