import { Tabs, Box, Title, IconButton, Text } from '@nimbus-ds/components';
import { EyeIcon, EyeOffIcon, InfoCircleIcon } from '@nimbus-ds/icons';
import { Tooltip } from '@tiendanube/components';
import { useStorage } from 'commons/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { useStatisticsDaily } from 'domains/Statistics/hooks';
import StatsDailyTabContent from './StatsDailyTabContent';

function CardStatisticsDaily() {
  const t = useTranslationDashboard();
  const { statisticsDaily, loading, showTabs } = useStatisticsDaily();

  const [showAmounts, setShowAmount] = useStorage(
    'showAmountsDashboard',
    true,
    true,
  );

  function handleOnClick() {
    setShowAmount(!showAmounts);
  }

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Box display="flex" gap="4" alignItems="center">
        <Title as="h3" fontWeight="medium">
          {t('tabs.title')}
        </Title>
        <Box display="flex" flexDirection="row" gap="1" alignItems="flex-start">
          <IconButton
            size="2rem"
            as="button"
            backgroundColor="neutral-surface"
            color="red"
            onClick={handleOnClick}
            source={
              showAmounts ? (
                <EyeOffIcon size="small" />
              ) : (
                <EyeIcon size="small" />
              )
            }
          />
          <Tooltip ariaLabel={t('tooltip')} labelIcon={InfoCircleIcon}>
            <Text fontSize="caption" color="neutral-surface">
              {t('tabs.tooltip')}
            </Text>
          </Tooltip>
        </Box>
      </Box>
      {!showTabs ? (
        <>
          <Text fontSize="highlight">{t('tabs.thisday')}</Text>
          {loading && <StatsDailyTabContent.Skeleton />}
          {!loading && (
            <StatsDailyTabContent
              values={statisticsDaily?.today}
              hideAmounts={!showAmounts}
            />
          )}
        </>
      ) : (
        <Tabs preSelectedTab={0}>
          <Tabs.Item label={t('tabs.thisday')}>
            {loading && <StatsDailyTabContent.Skeleton />}
            {!loading && (
              <StatsDailyTabContent
                values={statisticsDaily?.today}
                hideAmounts={!showAmounts}
              />
            )}
          </Tabs.Item>
          <Tabs.Item label={t('tabs.lastday')}>
            {loading && <StatsDailyTabContent.Skeleton />}
            {!loading && (
              <StatsDailyTabContent
                values={statisticsDaily?.yesterday}
                hideAmounts={!showAmounts}
              />
            )}
          </Tabs.Item>
          <Tabs.Item label={t('tabs.currentWeek')}>
            {loading && <StatsDailyTabContent.Skeleton />}
            {!loading && (
              <StatsDailyTabContent
                values={statisticsDaily?.currentWeek}
                hideAmounts={!showAmounts}
              />
            )}
          </Tabs.Item>
        </Tabs>
      )}
    </Box>
  );
}

export default CardStatisticsDaily;
