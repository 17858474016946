import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import { useSelector } from 'react-redux';
import { OrdersExportResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import {
  dateFormat,
  Format,
  getTodayIsoString,
  ninetyDaysAgo,
  subtractDays,
} from 'commons/utils/date';
import {
  ExportFiltersType,
  FilterStatusEnum,
} from 'domains/Orders/Orders/ordersService';
import {
  fetchTotalOrders,
  exportOrders as exportOrdersAction,
} from 'domains/Orders/Orders/ordersSlice';
import {
  getOrdersExportStatus,
  getOrdersExportTotal,
} from 'domains/Orders/Orders/ordersSlice/ordersSelectors';
import { validateDateLimit } from 'domains/Orders/Orders/utils/OrderListFilter';

interface UseOrdersExportResult {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  filters: ExportFiltersType;
  totalResults: number;
  getTotalOrders: (newFilters: ExportFiltersType) => Promise<void>;
  refreshTotalOrders: () => Promise<void>;
  removeFilter: (id: string) => Promise<void>;
  exportOrders: () => Promise<OrdersExportResponseDto>;
}

export const defaultFilters: ExportFiltersType = {
  q: '',
  dateFrom: subtractDays(getTodayIsoString(), 30),
  dateTo: getTodayIsoString(),
  status: FilterStatusEnum.EMPTY,
  fulfillmentStatus: FilterStatusEnum.EMPTY,
  paymentStatus: FilterStatusEnum.EMPTY,
  paymentMethods: '',
  paymentProvider: '',
  shippingMethod: '',
  origin: '',
  appId: '',
  products: '',
};

function useOrdersExport(): UseOrdersExportResult {
  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess } = useSelector(getOrdersExportStatus);
  const { filters, changeFilters } = useListFilters(
    'orders/export',
    defaultFilters,
  );
  const totalResults = useSelector(getOrdersExportTotal);

  const getTotalOrders = useCallback(
    async (newFilters: ExportFiltersType) => {
      if (!isEqual(filters, newFilters)) {
        changeFilters(newFilters);
        await dispatch(fetchTotalOrders(newFilters));
      }
    },
    [changeFilters, dispatch, filters],
  );

  const refreshTotalOrders = useCallback(async () => {
    await dispatch(fetchTotalOrders(validateDateLimit(filters)));
  }, [dispatch, filters]);

  const removeFilter = useCallback(
    async (id: string) => {
      const hasFiltersWithDateRestrictionsApplied =
        filters.paymentMethods !== '' ||
        filters.paymentProvider !== '' ||
        filters.appId !== '';

      const dateAndPaymentsFilter =
        hasFiltersWithDateRestrictionsApplied && id === 'dateFrom';
      const dateAfterNinetyDays = filters.dateFrom > ninetyDaysAgo;

      if (dateAndPaymentsFilter && !dateAfterNinetyDays) return;

      const newValue =
        dateAndPaymentsFilter && dateAfterNinetyDays ? ninetyDaysAgo : '';

      const newFilters = { ...filters, [id]: newValue };
      changeFilters(newFilters);
      await getTotalOrders(newFilters);
    },
    [changeFilters, filters, getTotalOrders],
  );

  const exportOrders = useCallback(async () => {
    const dateFrom = filters.dateFrom;
    const dateTo = filters.dateTo;
    const formattedDateFrom =
      dateFrom === '' ? dateFrom : dateFormat(dateFrom, Format.DD_MM_YYYY);
    const formattedDateTo =
      dateTo === '' ? dateTo : dateFormat(dateTo, Format.DD_MM_YYYY);
    const result = await dispatch(
      exportOrdersAction({
        ...filters,
        dateFrom: formattedDateFrom,
        dateTo: formattedDateTo,
      }),
    );
    return unwrapResult(result);
  }, [dispatch, filters]);

  return {
    isLoading,
    isSuccess,
    isError,
    filters,
    totalResults,
    refreshTotalOrders,
    getTotalOrders,
    removeFilter,
    exportOrders,
  };
}

export default useOrdersExport;
