import { ChangeEvent, useMemo, useState } from 'react';
import { Icon, Input, Title, Text, Tag, Box } from '@nimbus-ds/components';
import { SearchIcon } from '@nimbus-ds/icons';
import { InteractiveList } from '@nimbus-ds/patterns';
import { FreeShippingZoneDto } from '@tiendanube/common';
import ModalAside from 'App/components/lab/ModalAside';
import {
  ErrorScreen,
  Repeater,
  ShowMoreContent,
  Stack,
} from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { DeliverySubzonesModalAside } from './DeliverySubzonesModalAside';
import { useGetFreeShippingsZonesQuery } from '../freeShippingApi';

export interface DeliveryZonesSelectedInterface extends FreeShippingZoneDto {
  selected: boolean;
}

const emptyZone: DeliveryZonesSelectedInterface = {
  id: '',
  description: '',
  selected: false,
  subzones: [],
};

interface DeliveryZonesModalAsideProps {
  isOpen: boolean;
  selected: FreeShippingZoneDto[];
  onChange: (zones: FreeShippingZoneDto[]) => void;
  onClose: () => void;
}

const skeleton = () => (
  <InteractiveList.ButtonItemSkeleton title="" description="" />
);

export function DeliveryZonesModalAside({
  isOpen,
  selected,
  onChange,
  onClose,
}: Readonly<DeliveryZonesModalAsideProps>) {
  const t = useTranslationMarketing();
  const { ref } = useAutoFocus();
  const {
    data: zonesStore,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetFreeShippingsZonesQuery(undefined, { skip: !isOpen });

  const [search, setSearch] = useState('');
  const [idZoneSelected, setIdZoneSelected] = useState('');
  const searchLowerCase = search.toLowerCase();
  const dataSelected: DeliveryZonesSelectedInterface[] = useMemo(
    () =>
      (zonesStore || [])
        .filter(
          ({ description }) =>
            !search || description.toLowerCase().includes(searchLowerCase),
        )
        .map((currentData) => {
          const zone = selected.find(
            (current) => current.id === currentData.id,
          );
          return {
            ...currentData,
            selected: !!zone,
            subzones: zone?.subzones || [],
          };
        }),
    [zonesStore, search, searchLowerCase, selected],
  );

  const handleOnChangeSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const handleChangeZone = (zone: FreeShippingZoneDto) => {
    const othersZones = selected.filter((current) => current.id !== zone.id);
    const newZones = [...othersZones, zone];
    newZones.sort((prev, next) =>
      prev.description.localeCompare(next.description),
    );
    onChange(newZones);
  };

  const handleRemoveZone = (idZone: string) => {
    const othersZones = selected.filter((current) => current.id !== idZone);
    onChange(othersZones);
  };

  const handleCloseSubzone = () => setIdZoneSelected('');

  const zoneSelected = dataSelected.find(
    (current) => current.id === idZoneSelected,
  );

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerContent={
        <Title>{t('freeShipping.asides.deliveryZones.title')}</Title>
      }
    >
      <>
        <Stack innerRef={ref} column align="stretch">
          <Input
            value={search}
            onChange={handleOnChangeSearch}
            append={<Icon source={<SearchIcon size="small" />} />}
            appendPosition="start"
            placeholder={t('common.search')}
          />
          {isError && <ErrorScreen onRetry={refetch} />}
          {isLoading && (
            <InteractiveList>
              <Repeater times={10} item={skeleton} />
            </InteractiveList>
          )}
          {isSuccess && (
            <InteractiveList>
              {dataSelected.map(({ id, description, selected, subzones }) => {
                const handleSelectZone = () => {
                  setIdZoneSelected(id);
                };
                const displayShowAll = subzones.length === 0;
                const displaySubzones = subzones.length !== 0;
                return (
                  <InteractiveList.ButtonItem
                    key={id}
                    title={description}
                    iconButton={{
                      onClick: handleSelectZone,
                    }}
                  >
                    {selected && (
                      <Box display="flex" marginTop="1" gap="1" flexWrap="wrap">
                        {displayShowAll && (
                          <Tag>
                            <Text fontSize="caption" lineHeight="caption">
                              {t('freeShipping.asides.deliveryZones.allZones')}
                            </Text>
                          </Tag>
                        )}
                        {displaySubzones && (
                          <ShowMoreContent onClick={handleSelectZone}>
                            {subzones.map(({ id, description }) => (
                              <Tag key={id}>
                                <Text fontSize="caption" lineHeight="caption">
                                  {description}
                                </Text>
                              </Tag>
                            ))}
                          </ShowMoreContent>
                        )}
                      </Box>
                    )}
                  </InteractiveList.ButtonItem>
                );
              })}
            </InteractiveList>
          )}
        </Stack>
        <DeliverySubzonesModalAside
          isOpen={idZoneSelected !== ''}
          zone={zoneSelected || emptyZone}
          onClose={handleCloseSubzone}
          onChange={handleChangeZone}
          onRemove={handleRemoveZone}
        />
      </>
    </ModalAside>
  );
}
