import { CodeIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { PopoverMenu } from 'commons/components';
import { useModal } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useGetStoreInfo } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { EnableFtpModal } from './EnableFtpModal';

interface ThemeActionsProps {
  hasThemeConfigAvailable: boolean;
  hasFtpConfigAvailable: boolean;
}

export function ThemeActions({
  hasThemeConfigAvailable,
  hasFtpConfigAvailable,
}: Readonly<ThemeActionsProps>) {
  const t = useTranslationOnline('themesPage.theme.actions');
  const { url } = useGetStoreInfo();

  const handleOpenStoreFront = () => {
    openWindow(`${url}/`, true);
  };

  const [showEnableFtpModal, openEnableFtpModal, closeEnableFtpModal] =
    useModal();

  const actions = [
    {
      label: t('openActiveDesign'),
      startIcon: ExternalLinkIcon,
      onClick: handleOpenStoreFront,
    },
    ...(hasThemeConfigAvailable
      ? [
          {
            label: t('editCode'),
            startIcon: CodeIcon,
            onClick: openEnableFtpModal,
          },
        ]
      : []),
  ];

  return (
    <>
      <PopoverMenu actions={actions} />
      <EnableFtpModal
        showModal={showEnableFtpModal}
        hasFtpConfigAvailable={hasFtpConfigAvailable}
        onClose={closeEnableFtpModal}
      />
    </>
  );
}
