import { ChangeEvent, useEffect } from 'react';
import { Label } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { AiFeature } from '@tiendanube/common/src/enums';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { FEATURE_AI_PRODUCT_DESCRIPTION, TIER_1 } from 'App/features';
import {
  DEFAULT_LANGUAGE,
  LanguageInterface,
  LanguagesType,
  LanguageNameValueInterface,
} from 'App/i18n';
import { useResponsive, Stack, Editor } from 'commons/components';
import GenerateWithAiLink from 'commons/components/GenerateWithAiLink';
import { useAutoFocus, useModal } from 'commons/hooks';
import { CATALOG_PRODUCT_DESCRIPTION } from 'config/upsellFlowSources';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import AIProductDescriptionGeneratorModal from '../AIProductDescriptionGeneratorModal';

export interface FormNameAndDescriptionProps {
  name: LanguageInterface;
  description?: LanguageInterface;
  onChange: (data: LanguageNameValueInterface) => void;
  language?: LanguagesType;
  errors: Partial<Record<string, string>>;
  autoFocus?: boolean;
}

function FormNameAndDescription({
  name,
  errors,
  description,
  onChange,
  language = DEFAULT_LANGUAGE,
  autoFocus,
}: FormNameAndDescriptionProps): JSX.Element {
  const t = useTranslationCatalog();
  const { scrollToTop } = useLayoutScroll();
  const languageStore = useGetLanguage();
  const { isDesktop } = useResponsive();
  const { ref } = useAutoFocus();
  const [
    showProductDescriptionGeneratorModal,
    openProductDescriptionGeneratorModal,
    closeProductDescriptionGeneratorModal,
  ] = useModal();

  const hasError = !!errors[`name.${language}`];

  const handleOpenProductDescriptionGeneratorModal = useUpsellFlow({
    title: t('products.aiDescriptionGenerator.upsell'),
    featureKey: FEATURE_AI_PRODUCT_DESCRIPTION,
    trackingSource: CATALOG_PRODUCT_DESCRIPTION,
    callback: openProductDescriptionGeneratorModal,
    minValue: TIER_1,
  });

  const handleChangeName = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange({
      name: target.name,
      value: { ...name, [language]: target.value },
    });
  };

  const handleChangeEditor = (value: string): void => {
    onChange({
      name: 'description',
      value: { ...description, [language]: value },
    });
  };

  useEffect(() => {
    if (hasError) scrollToTop();
  }, [hasError, scrollToTop]);

  return (
    <>
      <AIProductDescriptionGeneratorModal
        show={showProductDescriptionGeneratorModal}
        product={{
          name: name[language],
          lang: language,
        }}
        onUseDescription={(description: string) => {
          handleChangeEditor(description);
        }}
        onDismiss={closeProductDescriptionGeneratorModal}
      />
      <Stack innerRef={autoFocus && ref} column align="stretch" spacing="loose">
        <FormField.Input
          type="text"
          label={t('products.detail.name')}
          placeholder={t('products.detail.sampleName')}
          onChange={handleChangeName}
          name="name"
          maxLength={150}
          value={name[language]}
          appearance={hasError ? 'danger' : undefined}
          helpText={hasError ? t('products.errorName') : undefined}
          showHelpText={hasError}
          helpIcon={hasError ? ExclamationCircleIcon : undefined}
        />

        <Stack column spacing="tight" align="stretch">
          <Stack spacing="tight">
            <Stack.Item>
              <Label>{t('products.detail.description')}</Label>
            </Stack.Item>
            <Stack.Item>
              <GenerateWithAiLink
                trackingFeature={AiFeature.AI_PRODUCTS_DESCRIPTION}
                onClick={handleOpenProductDescriptionGeneratorModal}
                fontSize="caption"
              />
            </Stack.Item>
          </Stack>
          <Editor
            value={description ? description[language] || '' : ''}
            onChange={handleChangeEditor}
            language={languageStore}
            showToolbar={isDesktop}
          />
        </Stack>
      </Stack>
    </>
  );
}

export default FormNameAndDescription;
