import { useEffect } from 'react';
import { Box } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { FEATURE_ALLOW_CUSTOM_DOMAIN } from 'App/features';
import HelpLink from 'App/HelpLink';
import { useQuery } from 'App/hooks';
import {
  ActionProp,
  ActionsDesktop,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  useResponsive,
} from 'commons/components';
import { HELP_LINKS_DOMAINS } from 'commons/constants';
import { useModal, useTranslationLanguage } from 'commons/hooks';
import { DOMAINS_ADD_DOMAIN_BTN } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import {
  useGetDomainsQuery,
  useUpdateOurDomainMutation,
} from 'domains/Configurations/Domains/domainsApi';
import { trackingAddDomainClick } from 'domains/Configurations/Domains/tracking';
import { UPDATE_OUR_DOMAIN_MUTATION_CACHE_KEY } from 'domains/Configurations/Domains/utils';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  HelpSection,
  DomainsList,
  ActivateSSLAlert,
  DomainUpdatedSuccessAlert,
  AddDomainModal,
} from './components';

export function DomainsPage() {
  const t = useTranslationConfigurations('domains');
  const showUpdatedAlertParam = useQuery().get('showUpdatedAlert');
  const language = useTranslationLanguage();
  const { isMobile } = useResponsive();
  const { data, isLoading, isError, refetch, isFetching } =
    useGetDomainsQuery();
  const [showAddDomainModal, openAddDomainModal, closeAddDomainModal] =
    useModal(false);
  const [
    showDomainUpdatedSuccessAlert,
    openDomainUpdatedAlert,
    closeDomainUpdatedAlert,
  ] = useModal(false);

  const [, { isSuccess: ourDomainUpdated, data: ourDomainData }] =
    useUpdateOurDomainMutation({
      fixedCacheKey: UPDATE_OUR_DOMAIN_MUTATION_CACHE_KEY,
    });

  const handleOpenAddDomainModal = useUpsellFlow({
    title: t('upsell.addDomain'),
    featureKey: FEATURE_ALLOW_CUSTOM_DOMAIN,
    callback: () => {
      trackingAddDomainClick();
      openAddDomainModal();
    },
    trackingSource: DOMAINS_ADD_DOMAIN_BTN,
  });

  const addAction: ActionProp = {
    children: t('addBtn'),
    icon: PlusCircleIcon,
    onClick: handleOpenAddDomainModal,
    appearance: isMobile ? undefined : 'primary',
  };

  const showAddButton = !isError || isLoading || isFetching;

  const showActiveSSLAlert =
    !!data &&
    data.some(
      (domain) =>
        domain.status.domain === 'ok' &&
        domain.status.ssl === 'domain_not_ready',
    );

  useEffect(() => {
    if (ourDomainUpdated || !!showUpdatedAlertParam) openDomainUpdatedAlert();
  }, [ourDomainUpdated, openDomainUpdatedAlert, showUpdatedAlertParam]);

  const ourDomainName = ourDomainData?.new_url || data?.[0].name || '';

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop mainAction={showAddButton ? addAction : undefined} />
      }
      headerContent={
        <HeaderContent
          title={t('title')}
          subtitle={t('subtitle')}
          actions={showAddButton && <ActionsDesktop actions={[addAction]} />}
        />
      }
    >
      {isError && <ErrorScreen onRetry={refetch} />}
      {!isError && (
        <>
          {showDomainUpdatedSuccessAlert && !!ourDomainName && (
            <DomainUpdatedSuccessAlert
              domainName={ourDomainName}
              onRemove={closeDomainUpdatedAlert}
            />
          )}
          {showActiveSSLAlert && <ActivateSSLAlert />}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="8"
            marginTop="3"
          >
            <DomainsList domains={data} isLoading={isLoading || isFetching} />
            <HelpSection />
          </Box>
          <AddDomainModal
            show={showAddDomainModal}
            onClose={closeAddDomainModal}
          />
          <HelpLink
            title={t('helpLink.title')}
            previousValue=""
            currentViewTracking={t('helpLink.currentViewTracking')}
            linkURL={HELP_LINKS_DOMAINS[language]}
            icon="both"
            hasDisclaimer={false}
          />
        </>
      )}
    </IonPageStratus>
  );
}
