import { isDomainWebAdmin } from 'App/components/PrivateRoute/utils';
import { useSubPathname } from 'App/hooks';
import goToAuthAdminRedirect from 'commons/utils/goToAuthAdminRedirect';
import { useGetSessionsQuery } from 'domains/Auth/authApi';
import { useCurrentUserSessionId, useIsLogged } from 'domains/Auth/hooks';

function useValidateCurrentUserSession(): boolean | null {
  const { isLogin } = useIsLogged();
  const { saveTemporal } = useSubPathname();
  const currentSessionId = useCurrentUserSessionId();
  const { data: userSessionIds } = useGetSessionsQuery(undefined, {
    skip: !isLogin,
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
  });

  // Validation is skipped if the current user's info has not been loaded yet.
  if (userSessionIds === undefined) {
    return true;
  }

  // The admin session cookie does not exist or has expired.
  if (currentSessionId === '' && isDomainWebAdmin(document.location.host)) {
    //Before exiting the application we temporarily persist the subpathname (#hash)
    saveTemporal();
    goToAuthAdminRedirect();

    return null;
  }

  return userSessionIds.includes(currentSessionId);
}

export default useValidateCurrentUserSession;
