import ShowByRole from 'App/components/ShowByRole';
import { PaymentStatusAlert } from 'domains/Billing/Checkout/components';
import { TransactionFeesPaymentAlert } from 'domains/Billing/TransactionFees/components';
import { KycRejectedAlert } from 'domains/NuvemPagoCommons/components/KycRejectedAlert';
import AlertFirstSale from '../AlertFirstSale';
import CardAuthenticationFactorPromotion from '../CardAuthenticationFactorPromotion';
import EmailVerifiedAlert from '../EmailVerifiedAlert';
import FacebookConnectionStatusAlert from '../FacebookConnectionStatusAlert';
import MercadoPagoCredentialsAlert from '../MercadoPagoCredentialsAlert';
import NuvemEnvioAdminBlockingAlert from '../NuvemEnvioAdminBlockingAlert';
import PagseguroCredentialsAlert from '../PaymentsNotifications/PagseguroCredentialsAlert';
import VerifyMercadoPagoAlert from '../PaymentsNotifications/VerifyMercadoPagoAlert';
import VerifyMercadoPagoSuccessOrDangerAlert from '../PaymentsNotifications/VerifyMercadoPagoSuccessOrDangerAlert';
import RecoveryCodesAlert from '../RecoveryCodesAlert';
import TrialMessages from '../TrialMessages';
import UpdateSuggestion from '../UpdateSuggestion';
import VulnerablePasswordAlert from '../VulnerablePasswordAlert';

function AlertNotifications() {
  return (
    <>
      <CardAuthenticationFactorPromotion />
      <RecoveryCodesAlert />
      <VulnerablePasswordAlert />
      <ShowByRole includeRoles="full">
        <>
          <KycRejectedAlert />
          <NuvemEnvioAdminBlockingAlert />
          <EmailVerifiedAlert />
          <TrialMessages />
          <FacebookConnectionStatusAlert />
          <MercadoPagoCredentialsAlert />
          <VerifyMercadoPagoAlert />
          <VerifyMercadoPagoSuccessOrDangerAlert />
          <PagseguroCredentialsAlert />
          <UpdateSuggestion />
        </>
      </ShowByRole>
      <ShowByRole includeRoles="orders">
        <AlertFirstSale />
      </ShowByRole>
      <ShowByRole includeRoles="full">
        <>
          <PaymentStatusAlert concept="plan-cost" isExternalContext />
          <TransactionFeesPaymentAlert initialFetch={false} />
        </>
      </ShowByRole>
    </>
  );
}

export default AlertNotifications;
