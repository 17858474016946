import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { i18nResponseDto } from '@tiendanube/common';
import { Thumbnail } from '@tiendanube/components';
import { TrashIcon } from '@tiendanube/icons';
import { AlertSessionStorage, ModalConfirmationBase } from 'App/components';
import ModalAside from 'App/components/lab/ModalAside';
import { AlertImagesValidation, HeaderTop } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { useImageLoader } from 'domains/Catalog/Products/pages/hooks';
import { trackingProductDropImages } from 'domains/Catalog/Products/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import Sortable from './Sortable';
import FileDraggable from '../../../FileDraggable';
import { ImageGalleryState } from '../../ImageGallery';
import EditImageModal from '../EditImageModal';
import { AltTextGenerationContext } from '../EditImageModal/EditImageActions/components/EditAltTextModal/components/AltTextGenerationModal';
import { EditedImage } from '../EditImageModal/EditImageModal';
import UploadingMessage from '../UploadingMessage';

import './ImageGalleryModal.scss';

interface ImageGalleryModalInterface {
  isEdit: boolean;
  isShow: boolean;
  images: ImageGalleryState[];
  imageIdForEditOnOpen?: string;
  message?: string;
  isOpenFileSystem?: boolean;
  altTextGenerationContext: AltTextGenerationContext;
  onClose: () => void;
  onChange: (images: ImageGalleryState[]) => void;
  onError: () => void;
  onUploadImages: (images: ImageGalleryState[]) => void;
  onOpenEditModal: () => void;
}

function ImageGalleryModal({
  isEdit,
  isShow,
  images,
  imageIdForEditOnOpen,
  message = '',
  isOpenFileSystem = false,
  altTextGenerationContext,
  onClose,
  onChange,
  onError,
  onUploadImages,
  onOpenEditModal,
}: ImageGalleryModalInterface) {
  const t = useTranslationCatalog();
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false);
  const [imagesSelected, setImagesSelected] = useState<string[]>([]);
  const [image, setImage] = useState<ImageGalleryState | null>(null);
  const sender = useTrackFullCatalog();

  const [showModalRemoveImage, openModalRemoveImage, closeModalRemoveImage] =
    useModal();
  const [isShowEditPhotoModal, openEditPhotoModal, closeEditPhotoModal] =
    useModal();

  const {
    imagesWithBadType,
    imagesExceededSize,
    acceptTypeFile,
    setImagesWithBadType,
    setImagesExceededSize,
    handleOnchange,
  } = useImageLoader({
    onUploadImages,
  });

  const handleOnDrop = (files: File[]) => {
    handleOnchange(files);
    sender(() => {
      trackingProductDropImages({
        from: 'modal',
        count: files.length,
        isEdit,
      });
    });
  };

  const handleOnClose = () => {
    setIsSelectMode(false);
    setImagesSelected([]);
    setImagesExceededSize([]);
    setImagesWithBadType([]);
    onClose();
  };

  const handleOnClickRemove = (id: string) => {
    handleSelect(id);
    openModalRemoveImage();
  };

  const handleOnClickEdit = useCallback(
    (id: string) => {
      const image = images.find((image) => image.id === id);

      if (image) {
        setImage(image);
        openEditPhotoModal();
      }
    },
    [images, setImage, openEditPhotoModal],
  );

  useEffect(() => {
    if (imageIdForEditOnOpen) {
      handleOnClickEdit(imageIdForEditOnOpen);
      onOpenEditModal();
    }
  }, [imageIdForEditOnOpen, handleOnClickEdit, onOpenEditModal]);

  const handleApplyRemoveImages = () => {
    onChange(images.filter((image) => !imagesSelected.includes(image.id)));
    setImagesSelected([]);
    setImagesExceededSize([]);
    setIsSelectMode(false);
    closeModalRemoveImage();
  };

  const handleSelectImage = () => setIsSelectMode(!isSelectMode);

  const handleSelect = (idSelected: string) => {
    if (imagesSelected.includes(idSelected)) {
      setImagesSelected((ids) => ids.filter((id) => id !== idSelected));
    } else {
      setImagesSelected((ids) => [...ids, idSelected]);
    }
  };

  const handleOnCloseRemoveModal = () => {
    setImagesSelected([]);
    closeModalRemoveImage();
  };

  const handleOnClickImage = (image: ImageGalleryState) => {
    setImage(image);
  };

  const handleOnChangeAltText = (value: i18nResponseDto) => {
    onChange(
      images.map((img) => {
        if (img.id === image?.id) {
          return {
            ...img,
            alt: value,
          };
        }
        return img;
      }),
    );
    setImage(null);
  };

  const isSelected = !!imagesSelected.length;
  const labelAction =
    isSelectMode && !isSelected
      ? t('products.modal.cancel')
      : isSelected
      ? t('products.modal.remove')
      : t('products.modal.selectToRemove');

  const backNavigation = {
    onClick: handleOnClose,
    children: '',
  };

  const headerAction = {
    appearance: isSelected ? ('danger' as const) : undefined,
    icon: !(isSelectMode && !isSelected) ? TrashIcon : undefined,
    children: labelAction,
    onClick: isSelected ? openModalRemoveImage : handleSelectImage,
  };

  const onSaveEditedImage = (
    editedImage: EditedImage,
    replacedImageId: string,
  ) => {
    const imageToUpload: ImageGalleryState = {
      isLoading: true,
      id: uuidv4(),
      isError: false,
      src: editedImage.base64,
      alt: image?.alt || {},
      base64: editedImage.base64,
      isEdited: true,
      replacedImageId: replacedImageId,
    };
    onUploadImages([imageToUpload]);
  };

  return (
    <ModalAside
      hasVirtualizationContent
      isOpen={isShow}
      onDidDismiss={handleOnClose}
      headerTop={
        <HeaderTop navigation={backNavigation} mainAction={headerAction} />
      }
      headerContent={t('products.modal.photos')}
    >
      <div className="stratus--image-gallery-modal-alert">
        <AlertSessionStorage
          keyName="imageGalleryModalAlert"
          title={t('products.modal.orderPhotosAlert')}
        >
          {t('products.modal.orderPhotosAlertBody')}
        </AlertSessionStorage>
      </div>
      <AlertImagesValidation filenames={imagesWithBadType} validation="type" />
      <AlertImagesValidation filenames={imagesExceededSize} validation="size" />
      <UploadingMessage message={message} />
      <Sortable
        items={images}
        isSelectMode={isSelectMode}
        imagesSelected={imagesSelected}
        onSelected={handleSelect}
        onRemove={handleOnClickRemove}
        onEdit={handleOnClickEdit}
        onOrder={onChange}
        onError={onError}
        ariaLabelError={t('products.details.errorUploadPhoto')}
        onClickImage={handleOnClickImage}
        newFileElement={
          <FileDraggable onDropFiles={handleOnDrop}>
            <div className="opacable">
              <Thumbnail.File
                onChange={handleOnchange}
                ariaLabel={t('products.modal.addPhotos')}
                disabled={isSelectMode}
                open={isOpenFileSystem}
                accept={acceptTypeFile}
              />
            </div>
          </FileDraggable>
        }
      />
      <ModalConfirmationBase
        title={t('products.modal.deleteConfirmationModal.title', {
          count: imagesSelected.length,
        })}
        text={t('products.modal.deleteConfirmationModal.body', {
          count: imagesSelected.length,
        })}
        labelCancel={t('products.modal.cancel')}
        label={t('products.modal.remove')}
        show={showModalRemoveImage}
        onConfirm={handleApplyRemoveImages}
        onCancel={handleOnCloseRemoveModal}
      />
      {!!image && (
        <EditImageModal
          image={image}
          onChangeAltText={handleOnChangeAltText}
          onSaveImage={onSaveEditedImage}
          isShow={isShowEditPhotoModal}
          altTextGenerationContext={altTextGenerationContext}
          onClose={closeEditPhotoModal}
        />
      )}
    </ModalAside>
  );
}

export default ImageGalleryModal;
