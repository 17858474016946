import { ReactNode } from 'react';
import { Box, Card, Text } from '@nimbus-ds/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface ConditionsCardProps {
  children: ReactNode;
}

export function ConditionsCard({ children }: Readonly<ConditionsCardProps>) {
  const t = useTranslationMarketing('promotions.settingsPage.conditions');
  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Box
          display="flex"
          flexDirection="column"
          gap="4"
          marginTop="4"
          marginBottom="1"
        >
          <Text>{t('info')}</Text>
          {children}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default ConditionsCard;
