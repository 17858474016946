import { Suspense } from 'react';
import ErrorBoundary from 'commons/components/ErrorBoundary';
import UpsellSoftBlockContextProvider from 'domains/Billing/UpsellFlow/pages/UpsellSoftBlockPage/UpsellSoftBlockContextProvider';
import DomainSoftBlockContextProvider from 'domains/Configurations/Domains/hooks/DomainSoftBlockContextProvider';
import { KycRejectedModal } from 'domains/NuvemPagoCommons/components/KycRejectedModal';
import ExternalServices from './components/ExternalServices';
import googleAnalyticsInit from './components/ExternalServices/GoogleAnalytics/init';
import Manifest from './components/Manifest';
import { DesktopMenuProvider } from './components/Menu/DesktopMenuProvider';
import ModalConfirmationProvider from './components/ModalConfirmation';
import NavTabsContextProvider from './components/Navtabs/NavTabsProvider';
import ToastContextProvider from './components/ToastContext';
import ToastProgressProvider from './components/ToastProgressContext';
import TopAdBarProvider from './components/TopAdBar/TopAdBarProvider';
import TopbarContextProvider from './components/Topbar/TopbarProvider';
import UpsellFlowModalContextProvider from './components/UpsellFlowModalContext';
import i18nInit from './i18n';
import ReduxProvider from './ReduxProvider';
import RouterProvider from './RouterProvider';
import Routes from './Routes';
import { BackgroundDispatcher } from './settings/jobs/components';
import '../styles/_nimbus_temp.scss';

i18nInit();
googleAnalyticsInit();

function App(): JSX.Element {
  return (
    <Suspense fallback={null}>
      <ErrorBoundary>
        <ReduxProvider>
          <ToastContextProvider>
            <ToastProgressProvider>
              <DesktopMenuProvider>
                <Manifest />
                <ModalConfirmationProvider>
                  <RouterProvider>
                    <UpsellFlowModalContextProvider>
                      <ExternalServices />
                      <NavTabsContextProvider>
                        <TopbarContextProvider>
                          <BackgroundDispatcher />
                          <TopAdBarProvider>
                            <UpsellSoftBlockContextProvider>
                              <DomainSoftBlockContextProvider>
                                <KycRejectedModal />
                                <Routes />
                              </DomainSoftBlockContextProvider>
                            </UpsellSoftBlockContextProvider>
                          </TopAdBarProvider>
                        </TopbarContextProvider>
                      </NavTabsContextProvider>
                    </UpsellFlowModalContextProvider>
                  </RouterProvider>
                </ModalConfirmationProvider>
              </DesktopMenuProvider>
            </ToastProgressProvider>
          </ToastContextProvider>
        </ReduxProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
