import { Box, Text, Link } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { CopyLink } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface CNameRecordInfoProps {
  ourDomain: string;
  isSubdomain: boolean;
}

export function CNameRecordInfo({
  ourDomain,
  isSubdomain,
}: Readonly<CNameRecordInfoProps>) {
  const t = useTranslationConfigurations('domains.attachModal.step2.cname');

  return (
    <Box
      backgroundColor="neutral-surface"
      display="flex"
      flexDirection="column"
      borderRadius="base"
      gap="2"
    >
      <Text>
        <Trans
          t={t}
          i18nKey="copyText"
          components={{
            helpLink: (
              <Link
                as="a"
                appearance="primary"
                href={isSubdomain ? t('helpLinkSubdomain') : t('helpLink')}
                target="_blank"
                textDecoration="none"
              >
                {' '}
              </Link>
            ),
          }}
        />
      </Text>
      <Box
        backgroundColor="primary-surface"
        width="fit-content"
        padding="1"
        borderRadius="base"
      >
        <CopyLink
          value={ourDomain}
          message={t('copySuccess')}
          text={ourDomain}
        />
      </Box>
    </Box>
  );
}
