import { TypeFeaturesPlansListResponseDto } from '@tiendanube/common';
import { TIER_1, TIER_2, TIER_3, TIER_DISABLED } from 'App/features';

function useCompareFeatureValues() {
  const tiers = [TIER_DISABLED, TIER_1, TIER_2, TIER_3];

  const meetsRequirement = (
    value: TypeFeaturesPlansListResponseDto,
    minValue: TypeFeaturesPlansListResponseDto,
  ) => {
    // -1 means infinite
    if (value === -1) {
      return true;
    }

    // Compare as numbers
    if (typeof value === 'number' && typeof minValue === 'number') {
      return value >= minValue;
    }

    // Compare as tiers
    if (typeof value === 'string' && typeof minValue === 'string') {
      return tiers.indexOf(value) >= tiers.indexOf(minValue);
    }

    // Can't compare if the types don't match
    return false;
  };

  const negatableMeetsRequirement = (
    value: TypeFeaturesPlansListResponseDto,
    minValue: TypeFeaturesPlansListResponseDto,
    negate: boolean,
  ) => {
    const result = meetsRequirement(value, minValue);

    return negate ? !result : result;
  };

  return {
    meetsRequirement,
    negatableMeetsRequirement,
  };
}

export default useCompareFeatureValues;
