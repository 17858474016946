import { Route } from 'react-router-dom';
import { DASHBOARD_BASE_PATH } from './dashboardRoutes';
import { useSetConfigKeyboard } from './hooks';
import DashboardPage from './pages/DashboardPage';
import { AdminDisabledScreen } from './pages/DashboardPage/components';
import { useDisabledAdminForRoles } from './useDisabledAdminForRoles';

function Dashboard(): JSX.Element {
  useSetConfigKeyboard();
  const isDisabled = useDisabledAdminForRoles();

  return (
    <Route exact path={DASHBOARD_BASE_PATH}>
      {isDisabled ? <AdminDisabledScreen /> : <DashboardPage />}
    </Route>
  );
}

export default Dashboard;
