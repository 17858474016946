const getImageMimeType = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const arr = new Uint8Array(e.target?.result as ArrayBuffer).subarray(
        0,
        4,
      );
      let header = '';
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (header === '89504e47') return resolve('image/png');
      if (header === '47494638') return resolve('image/gif');
      if (header.startsWith('ffd8')) return resolve('image/jpeg');
      if (header === '52494646') return resolve('image/webp');
      return resolve('');
    };
    reader.readAsArrayBuffer(file);
  });

export default getImageMimeType;
