import { EditIcon, EyeIcon, EyeOffIcon, TrashIcon } from '@nimbus-ds/icons';
import { StatusPromotionType } from '@tiendanube/common';
import {
  ActionIconButton,
  Stack,
  useResponsive,
  PopoverMenu,
} from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  DeletePromotionModal,
  StatusPromotionModal,
} from 'domains/Marketing/Promotions/components';
import { usePromotionsActions } from 'domains/Marketing/Promotions/hooks';

interface ActionsProps {
  id: string;
  status: StatusPromotionType;
}

export function Actions({ id, status }: Readonly<ActionsProps>) {
  const t = useTranslationMarketing('promotions.actions');
  const isActive = status === 'active';
  const { isMobile } = useResponsive();

  const {
    showStatusModal,
    showDeleteModal,
    handleChangeStatus,
    handleEdit,
    handleDelete,
    openDeleteModal,
    closeDeleteModal,
    openStatusModal,
    closeStatusModal,
  } = usePromotionsActions(id, isActive);

  const showStatusBtn = status !== 'expired';
  const EyeStatusIcon = isActive ? EyeOffIcon : EyeIcon;
  const typeStatus = isActive ? 'disable' : 'enable';

  const actions = [
    {
      label: t('edit.label'),
      startIcon: EditIcon,
      onClick: handleEdit,
      show: true,
    },
    {
      label: t(`${typeStatus}.label`),
      startIcon: EyeStatusIcon,
      onClick: openStatusModal,
      show: showStatusBtn,
    },
    {
      label: t('delete.label'),
      startIcon: TrashIcon,
      onClick: openDeleteModal,
      show: true,
    },
  ];

  return (
    <>
      {isMobile ? (
        <PopoverMenu actions={actions.filter((option) => option.show)} />
      ) : (
        <Stack spacing="tight">
          <ActionIconButton
            content={t('edit.label')}
            source={<EditIcon />}
            onClick={handleEdit}
          />
          {showStatusBtn && (
            <ActionIconButton
              content={t(`${typeStatus}.label`)}
              source={<EyeStatusIcon />}
              onClick={openStatusModal}
            />
          )}
          <ActionIconButton
            content={t('delete.label')}
            source={<TrashIcon />}
            onClick={openDeleteModal}
          />
        </Stack>
      )}
      <StatusPromotionModal
        show={showStatusModal}
        isActive={isActive}
        onClose={closeStatusModal}
        onChangeStatus={handleChangeStatus}
      />
      <DeletePromotionModal
        show={showDeleteModal}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
      />
    </>
  );
}
