import { ReactNode } from 'react';
import { Box, Icon, Text, Link, TextProps } from '@nimbus-ds/components';
import { Link as LinkRouter } from 'react-router-dom';
import { Stack, Thumbnail } from '@tiendanube/components';
import { CameraIcon, ExclamationTriangleIcon } from '@tiendanube/icons';
import './ImageItemStratus.scss';

interface ImageItemStratusProps {
  id: string;
  title: string;
  imageUrl?: string;
  altText?: string;
  to?: string;
  onClickImage?: () => void;
  onClickTitle?: () => void;
  children?: ReactNode;
  headerRight?: ReactNode;
  appearance?: 'primary' | 'default';
  titleAppearance?: TextProps['color'];
  bold?: boolean;
  imageWidth?: string;
  rightContent?: ReactNode;
  trimTitle?: boolean;
  trimLines?: number;
  warning?: boolean;
  titleSize?: TextProps['fontSize'];
}

function ImageItemStratus({
  id,
  title,
  imageUrl,
  altText,
  to,
  onClickTitle,
  onClickImage,
  children,
  headerRight,
  appearance = 'primary',
  titleAppearance,
  bold = true,
  imageWidth = '72px',
  rightContent,
  trimTitle = false,
  trimLines = 1,
  warning = false,
  titleSize = 'base',
}: ImageItemStratusProps): JSX.Element {
  const appearanceText =
    appearance === 'default' ? 'neutral-textLow' : 'primary-interactive';

  const appearanceLink = appearance === 'default' ? 'neutral' : 'primary';

  return (
    <div className="stratus--image-item">
      <Stack spacing="tight" align="stretch">
        <Stack.Item>
          {!!imageUrl && (
            <div onClick={onClickImage}>
              <Thumbnail
                aspectRatio="1-1"
                width={imageWidth}
                src={imageUrl}
                altText={altText || `${title} - ${id}`}
              />
            </div>
          )}
          {!imageUrl && (
            <div onClick={onClickImage}>
              <Thumbnail.Empty
                aspectRatio="1-1"
                width={imageWidth}
                placeholderIcon={CameraIcon}
              />
            </div>
          )}
        </Stack.Item>
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent={{
            lg: 'space-between',
          }}
          width="100%"
          gap="1"
          flexDirection={{
            lg: 'row',
            md: 'column',
            xs: 'column',
          }}
        >
          <Box
            display="flex"
            flexWrap={headerRight ? 'nowrap' : 'wrap'}
            alignItems="stretch"
            justifyContent="space-between"
            flex={{
              xs: '1 1 auto',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="1"
              flex={{ lg: '1 1 auto', md: '1 1 auto', xs: '1 1 auto' }}
            >
              <Box display="flex" alignItems="center" gap="1">
                <Link
                  appearance={appearanceLink}
                  onClick={onClickTitle}
                  textDecoration="none"
                  as={to ? LinkRouter : 'a'}
                  to={to || ''}
                >
                  <Text
                    fontWeight={bold ? 'bold' : 'regular'}
                    lineClamp={trimTitle ? trimLines : undefined}
                    color={titleAppearance ?? appearanceText}
                    fontSize={titleSize}
                  >
                    {title}
                  </Text>
                </Link>
                {warning && (
                  <Icon
                    color="danger-interactive"
                    source={<ExclamationTriangleIcon />}
                  />
                )}
              </Box>
              {children}
            </Box>
            {!!headerRight && <Stack.Item>{headerRight}</Stack.Item>}
          </Box>
          {!!rightContent && (
            <div className="right-content">{rightContent}</div>
          )}
        </Box>
      </Stack>
    </div>
  );
}

export default ImageItemStratus;
