import { useEffect } from 'react';
import { Text } from '@nimbus-ds/components';
import { CalloutCard } from '@nimbus-ds/patterns';
import isEmpty from 'lodash/isEmpty';
import { Button, ErrorState, Link } from '@tiendanube/components';
import { CogIcon, AppsIcon } from '@tiendanube/icons';
import HelpLink from 'App/HelpLink';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Stack,
} from 'commons/components';
import {
  HELP_LINKS_PAYMENTS_PROVIDERS,
  STORE_PAYMENT_APPS_LINKS,
} from 'commons/constants';
import { useModal, useTranslationLanguage } from 'commons/hooks';
import { Notification } from 'domains/Payments/PaymentNotification';
import { NotificationTypes } from 'domains/Payments/PaymentNotification/types';
import {
  useCheckoutPaymentOptions,
  useGetNuvemPagoFromPorviderList,
  useInstalledPaymentProviders,
} from 'domains/Payments/PaymentProviders/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import {
  AdvancedSettingsModal,
  OnboardingNuvemPagoModal,
  PaymentProviderList,
  RecentlyDisabledAlert,
  RecentlyEnabledAlert,
} from './components';

const TRANSLATE_PREFIX = 'paymentProviders';

function PaymentProvidersListPage(): JSX.Element {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const language = useTranslationLanguage();

  const [
    showOnboardingNuvemPagoModal,
    openOnboardingNuvemPagoModal,
    closeOnboardingNuvemPagoModal,
  ] = useModal();

  const [
    showAdvancedSettingsModal,
    openAdvancedSettingsModal,
    closeAdvancedSettingsModal,
  ] = useModal();

  const {
    paymentProviders,
    isError,
    isLoading,
    isSuccess,
    fetchInstalledPaymentProviders,
  } = useInstalledPaymentProviders();

  const { nuvemPagoId, nuvemPagoIsActive, nuvemPagoGoToSettings } =
    useGetNuvemPagoFromPorviderList(paymentProviders);

  const {
    checkoutPaymentOptions,
    isSuccess: isSuccessLoadCheckout,
    fetchCheckoutPaymentOptions,
  } = useCheckoutPaymentOptions();

  useEffect(() => {
    fetchInstalledPaymentProviders();
  }, [fetchInstalledPaymentProviders]);

  useEffect(() => {
    fetchCheckoutPaymentOptions();
  }, [fetchCheckoutPaymentOptions]);

  useEffect(() => {
    if (nuvemPagoId && nuvemPagoIsActive) {
      openOnboardingNuvemPagoModal();
    }
  }, [nuvemPagoId, nuvemPagoIsActive, openOnboardingNuvemPagoModal]);

  const handleConfigureNuvemPago = () => {
    closeOnboardingNuvemPagoModal();
    nuvemPagoGoToSettings();
  };

  const handleAdvancedConfiguration = () => {
    openAdvancedSettingsModal();
  };

  const hasPaymentProvidersInstalled =
    paymentProviders && paymentProviders.length > 0;

  const showButtonAdvancedSettings =
    isSuccessLoadCheckout &&
    (!isEmpty(checkoutPaymentOptions?.redirect) ||
      !isEmpty(checkoutPaymentOptions?.transparent));

  return (
    <IonPageStratus
      width="small"
      headerTop={
        showButtonAdvancedSettings && (
          <HeaderTop
            actions={
              <Link
                onClick={handleAdvancedConfiguration}
                icon={CogIcon}
                iconSize="medium"
                appearance="secondary"
              >
                <Text>{`${t('advancedConfigurationButton')}`}</Text>
              </Link>
            }
          />
        )
      }
      headerContent={
        <HeaderContent
          title={t('title')}
          subtitle={
            isLoading || hasPaymentProvidersInstalled || isError
              ? ''
              : t('subtitle')
          }
          actions={
            showButtonAdvancedSettings && (
              <Button
                appearance="primary"
                onClick={handleAdvancedConfiguration}
              >
                {`${t('advancedConfigurationButton')}`}
              </Button>
            )
          }
        />
      }
    >
      {showOnboardingNuvemPagoModal && !!nuvemPagoId && (
        <OnboardingNuvemPagoModal
          id={nuvemPagoId}
          onClick={handleConfigureNuvemPago}
        />
      )}

      <AdvancedSettingsModal
        isOpen={showAdvancedSettingsModal}
        onClose={closeAdvancedSettingsModal}
      />

      <RecentlyEnabledAlert />
      <RecentlyDisabledAlert />

      <Notification
        listeningToTypes={[NotificationTypes.LendingNotifyNuvemPagoExclusive]}
      />

      {isError && (
        <ErrorState
          title={t('error.title')}
          action={{
            children: t('error.button'),
            onClick: fetchInstalledPaymentProviders,
          }}
        />
      )}
      {isLoading && <PaymentProviderList.Skeleton />}
      {isSuccess && (
        <PaymentProviderList paymentProviders={paymentProviders || []} />
      )}
      <Stack column>
        <CalloutCard
          appearance="primary"
          title={t('moreOptions.title')}
          subtitle={t('moreOptions.subtitle')}
          icon={AppsIcon}
          onClick={() =>
            window.open(STORE_PAYMENT_APPS_LINKS[language], '_blank')
          }
        />
        <HelpLink
          previousValue=""
          title={t('helpLink.title')}
          currentViewTracking={t('helpLink.amplitudeName')}
          linkURL={HELP_LINKS_PAYMENTS_PROVIDERS[language]}
          icon="both"
          appearance="primary"
          showInMobile
        />
      </Stack>
    </IonPageStratus>
  );
}

export default PaymentProvidersListPage;
