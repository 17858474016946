import { Box } from '@nimbus-ds/components';
import CardStatisticsDaily from '../CardStatisticsDaily';
import SalesQuantityRange from '../SalesQuantityRange';

function StoreStats() {
  return (
    <Box
      display={{
        lg: 'grid',
      }}
      gridTemplateColumns="repeat(2, 1fr)"
      gridGap="4"
    >
      <CardStatisticsDaily />
      <SalesQuantityRange />
    </Box>
  );
}

export default StoreStats;
