/* eslint-disable max-statements */
import { useEffect } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import { CategoryDetailsResponseDto } from '@tiendanube/common';
import { ErrorState, Link } from '@tiendanube/components';
import { CheckCircleIcon, TrashIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import {
  ActionProp,
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import ModalConfirmationAction from 'commons/components/ModalConfirmationAction';
import { useForm, useModal } from 'commons/hooks';
import { useGetAllLanguages } from 'domains/Auth/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { useGetCategoriesMetafields } from 'domains/Metafields/hooks';
import { buildCategorySchema } from './categorySchema';
import FormEditCategory from './components/FormEditCategory';
import useCategory from './hooks/useCategory';
import Skeleton from './Skeleton';
import InviteToCreateMetafieldsAlert from '../CategoriesListPage/components/InviteToCreateMetafieldsAlert';
import { ID_ROOT } from '../CategoriesListPage/utils';

function EditCategoryPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const { goBack } = useNavegate();

  const languages = useGetAllLanguages();

  const validationSchema = buildCategorySchema(languages);

  const t = useTranslationCatalog();

  const {
    category,
    isLoading,
    isSuccess,
    isError,
    isSaving,
    fetchCategory,
    doSave,
    doDelete,
  } = useCategory(id);
  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();

  const {
    hasInternals,
    hasExternals,
    fetchMetafields,
    isAvailable: hasCategoriesMetafieldsAccess,
  } = useGetCategoriesMetafields();

  const showCreateMetafieldsAlert =
    hasCategoriesMetafieldsAccess && !hasInternals && !hasExternals;

  useEffect(() => {
    fetchMetafields();
  }, [fetchMetafields]);

  const handleActionDelete = async () => {
    await doDelete();
    goBack();
  };
  const { values, errors, isDirty, handleOnSubmit, setFieldValue } = useForm<
    CategoryDetailsResponseDto,
    Record<string, string>
  >({
    initialValues: category,
    validationSchema: validationSchema,
    onSubmit: doSave,
  });
  const saveAction: ActionProp = {
    onClick: handleOnSubmit,
    children: t('categories.detail.header.save'),
    spinner: isSaving,
    disabled: isSaving,
  };

  const saveActionMobile: ActionProp = {
    ...saveAction,
    icon: CheckCircleIcon,
  };

  const saveActionDesktop: ActionProp = {
    ...saveAction,
    appearance: 'primary',
  };

  const deleteActionDesktop: ActionProp = {
    onClick: openDeleteModal,
    children: t('categories.detail.header.delete'),
    icon: TrashIcon,
  };

  const backNavigation = {
    onClick: goBack,
  };

  const categoryOrSubcategory =
    values.parent === ID_ROOT ? 'category' : 'subcategory';

  useEffect(() => {
    fetchCategory();
  }, [fetchCategory]);

  const titleStatus = !isLoading
    ? t(`categories.detail.title.${categoryOrSubcategory}`)
    : '';

  return (
    <IonPageStratus
      headerTop={
        <HeaderTop
          navigation={backNavigation}
          actions={
            isSuccess && <Link onClick={openDeleteModal} icon={TrashIcon} />
          }
          mainAction={isSuccess ? saveActionMobile : {}}
        />
      }
      headerContent={
        <HeaderContent
          title={titleStatus}
          actions={
            isSuccess && (
              <ActionsDesktop
                actions={[deleteActionDesktop, saveActionDesktop]}
              />
            )
          }
        />
      }
      width="small"
    >
      {isError && (
        <ErrorState
          title={t('categories.detail.errorResults.title')}
          action={{
            children: t('categories.detail.errorResults.action'),
            onClick: fetchCategory,
          }}
        />
      )}

      <Prompt when={isDirty} message={t('common:exitEdit.info')} />

      {showCreateMetafieldsAlert && <InviteToCreateMetafieldsAlert />}

      {isLoading && <Skeleton />}
      {isSuccess && values && (
        <>
          <FormEditCategory
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            categoryOrSubcategory={categoryOrSubcategory}
            handleOnSubmit={handleOnSubmit}
            id={id}
          />
          {showDeleteModal && (
            <ModalConfirmationAction
              title={t(
                `categories.detail.delete.${categoryOrSubcategory}.title`,
              )}
              body={t(`categories.detail.delete.${categoryOrSubcategory}.info`)}
              btnOk={t('categories.detail.delete.ok')}
              btnCancel={t('categories.detail.delete.cancel')}
              textProgress={t('categories.detail.delete.toast.progress')}
              textSuccess={t('categories.detail.delete.toast.success')}
              textError={t('categories.detail.delete.toast.error')}
              appearance="danger"
              onAction={handleActionDelete}
              onClose={closeDeleteModal}
            />
          )}
        </>
      )}
    </IonPageStratus>
  );
}
EditCategoryPage.Skeleton = Skeleton;

export default EditCategoryPage;
