import { useEffect, useRef, RefObject } from 'react';
import {
  ACTION_AUTH_SESSION_TOKEN,
  ACTION_STORE_INFO,
  ACTION_READY,
  ACTION_CONNECTED,
  ACTION_NAVIGATE_BACK,
  ACTION_NAVIGATE_GOTO,
  ACTION_UTILS_COPY_TO_CLIPBOARD,
  ACTION_NAVIGATE_PATHNAME,
  ACTION_NAVIGATE_SYNC,
  ACTION_NAVIGATE_EXIT,
  ACTION_NAVIGATE_GOTO_OLD_ADMIN,
  ACTION_NAVIGATE_HEADER,
  ACTION_DEVICE,
  ACTION_LOG_ERROR,
  NavigateGoToOldAdminRequest,
  NavigateSyncRequest,
  NavigateGoToRequest,
  NavigateHeaderRequest,
  CopyToClipboardRequest,
  ACTION_UTILS_OPEN_IN_DEFAULT_BROWSER,
} from '@tiendanube/nexo';
import useTopBar from 'App/components/Topbar/useTopBar';
import { useClipboard, useNavegate, useSubPathname } from 'App/hooks';
import goToAdmin from 'commons/utils/gotToAdmin';
import { openWindow } from 'commons/utils/window';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import {
  useGetToken,
  useStatusEmbeddedApp,
  useStoreInfo,
} from 'domains/PartnersApps/hooks';
import { createNexo, NexoInstance } from 'libs/nexo/nexo';
import { digestStoreInfo } from './digestStoreInfo';

function useNexoClient(
  appId: string,
  iframeRef: RefObject<HTMLIFrameElement>,
  windowRef: RefObject<Window | null>,
) {
  const navegate = useNavegate();
  const subPathname = useSubPathname();
  const statusApp = useStatusEmbeddedApp();
  const { setHeaderNavigation } = useTopBar();
  const copyToClipboard = useClipboard();
  const storeInfo = useStoreInfo();
  const getToken = useGetToken();
  const isMobileDevice = useIsMobileDevice();
  const nexoRef = useRef({} as NexoInstance);

  useEffect(() => {
    if (nexoRef.current.dispatch) {
      nexoRef.current.dispatch(
        {
          type: ACTION_NAVIGATE_SYNC,
          payload: { path: subPathname.value ?? '/', replace: false },
        },
        windowRef?.current,
      );
    }
  }, [subPathname.value, windowRef]);

  useEffect(() => {
    if (!iframeRef.current) return;
    nexoRef.current = createNexo(iframeRef.current);
    const nexo = nexoRef.current;

    /**
     * LISTENER ACTION CONNECTED
     * */
    nexo.suscribe(ACTION_CONNECTED, () => {
      nexo.dispatch({ type: ACTION_CONNECTED }, windowRef?.current);
    });

    /**
     * LISTENER ACTION READY
     * */
    nexo.suscribe(ACTION_READY, () => {
      statusApp.handleReady();
      const subPath = subPathname.value || subPathname.restoreTemporal();
      if (subPath) {
        nexo.dispatch(
          {
            type: ACTION_NAVIGATE_SYNC,
            payload: { path: subPath, replace: true },
          },
          windowRef?.current,
        );
        //If there is a persisted subPathname it will be restored
        subPathname.restoreTemporal();
      }
    });

    /**
     * LISTENER ACTION READY
     * */
    /**
     * This flag is necessary to avoid the first sync with the root
     * path that is causing a desynchronization with the parent path
     */
    let toAvoidUnnecessaryFirstSyncRoot = true;
    nexo.suscribe(ACTION_NAVIGATE_SYNC, ({ pathname }: NavigateSyncRequest) => {
      if (!toAvoidUnnecessaryFirstSyncRoot) {
        subPathname.syncNewSubPathname(pathname);
      }
      toAvoidUnnecessaryFirstSyncRoot = false;
    });

    /**
     * LISTENER ACTION EXIT
     * */
    nexo.suscribe(ACTION_NAVIGATE_EXIT, () => {
      navegate.goToReferrer();
    });

    /**
     * LISTENER ACTION GO TO OLD ADMIN
     * */
    nexo.suscribe(
      ACTION_NAVIGATE_GOTO_OLD_ADMIN,
      ({ pathToOldAdmin }: NavigateGoToOldAdminRequest) => {
        goToAdmin(pathToOldAdmin)();
      },
    );

    /**
     * LISTENER ACTION NAVEGATE BACK
     * */
    nexo.suscribe(ACTION_NAVIGATE_BACK, () => {
      navegate.goBack();
    });

    /**
     * LISTENER ACTION NAVEGATE GO TO
     * */
    nexo.suscribe(ACTION_NAVIGATE_GOTO, ({ pathname }: NavigateGoToRequest) => {
      navegate.goTo(pathname);
    });

    /**
     * LISTENER ACTION NAVEGATE SUBPATHNAME
     * */
    nexo.suscribe(ACTION_NAVIGATE_PATHNAME, () => {
      nexo.dispatch(
        {
          type: ACTION_NAVIGATE_PATHNAME,
          payload: { pathname: subPathname.value },
        },
        windowRef?.current,
      );
    });

    /**
     * LISTENER ACTION AUTH_SESSION_TOKEN
     * */
    nexo.suscribe(ACTION_AUTH_SESSION_TOKEN, async () => {
      const token = await getToken(appId);
      nexo.dispatch(
        {
          type: ACTION_AUTH_SESSION_TOKEN,
          payload: { token },
        },
        windowRef?.current,
      );
    });

    /**
     * LISTENER ACTION STORE INFO
     * */
    nexo.suscribe(ACTION_STORE_INFO, () => {
      nexo.dispatch(
        { type: ACTION_STORE_INFO, payload: digestStoreInfo(storeInfo) },
        windowRef?.current,
      );
    });

    /**
     * LISTENER ACTION IS MOBILE DEVICE
     * */
    nexo.suscribe(ACTION_DEVICE, () => {
      nexo.dispatch(
        {
          type: ACTION_DEVICE,
          payload: { isMobile: isMobileDevice },
        },
        windowRef?.current,
      );
    });

    /**
     * LISTENER ACTION UTIL COPY TO CLIPBOARD
     * */
    nexo.suscribe(
      ACTION_UTILS_COPY_TO_CLIPBOARD,
      async ({ text }: CopyToClipboardRequest) => {
        const success = await copyToClipboard(text);
        nexo.dispatch(
          {
            type: ACTION_UTILS_COPY_TO_CLIPBOARD,
            payload: { success },
          },
          windowRef?.current,
        );
      },
    );

    /**
     * LISTENER ACTION UTIL OPEN IN DEFAULT BROWSER
     * */
    nexo.suscribe(
      ACTION_UTILS_OPEN_IN_DEFAULT_BROWSER,
      async (path: { pathname: string }) => {
        openWindow(path.pathname, true);
      },
    );

    /**
     * LISTENER ACTION NAVEGATE HEADER
     * */
    nexo.suscribe(
      ACTION_NAVIGATE_HEADER,
      ({ goTo, goToAdmin, text, remove }: NavigateHeaderRequest) => {
        if (remove) {
          setHeaderNavigation(undefined);
          return;
        }

        const isGoToBack = goTo === 'back';
        const isGoToPath = goTo?.indexOf('/') === 0;
        const isGoToAdminPath = goToAdmin?.indexOf('/') === 0;

        if (!isGoToBack && !isGoToPath && !isGoToAdminPath) {
          //'Nexo: the goTo string is invalid try to define `back` or an path starting with `/`
          return;
        }

        const goToInApp = () => {
          nexo.dispatch(
            {
              type: ACTION_NAVIGATE_SYNC,
              payload: { path: goTo },
            },
            windowRef?.current,
          );
          navegate.goTo(`${navegate.pathname}#${goTo}`);
        };

        const goToInAdmin = () => navegate.goTo(`${goToAdmin}`);

        setHeaderNavigation({
          onClick: isGoToBack
            ? navegate.goBack
            : isGoToPath
            ? goToInApp
            : goToInAdmin,
          children: text,
        });
      },
    );
    /**
     * LISTENER ACTION LOG ERROR
     * */
    nexo.suscribe(ACTION_LOG_ERROR, () => {
      statusApp.handleError();
    });

    return () => {
      nexo.disconnect();
      nexoRef.current = {} as NexoInstance;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef.current, appId]);

  return statusApp;
}

export default useNexoClient;
