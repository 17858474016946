import * as jose from 'jose';
import Smooch from 'smooch';
import { getConfig } from './config';
import { UserConfig } from './interfaces';

let previousPosition: number;
let iframe: HTMLIFrameElement;

async function getJWT(
  appId: string,
  app_secret: string,
  externalId: string,
): Promise<string> {
  const secret = new TextEncoder().encode(app_secret);
  const jwt = await new jose.SignJWT({
    scope: 'user',
    external_id: externalId,
  })
    .setProtectedHeader({
      alg: 'HS256',
      typ: 'JWT',
      kid: appId,
    })
    .sign(secret);
  return jwt;
}

export async function startSmooch(config: UserConfig) {
  const {
    externalId,
    userName,
    email,
    countryCode,
    storeId,
    storeUrl,
    onReady,
  } = config;
  const {
    integrationId,
    suncoTexts,
    appId,
    secret,
    businessIconUrl,
    buttonIconUrl,
    headerIcon,
    customColors,
  } = getConfig(countryCode);
  const jwt = await getJWT(appId, secret, externalId);

  Smooch.on('ready', () => {
    Smooch.updateUser({
      metadata: {
        storeCountry: countryCode,
      },
    });
    onReady?.();
  });

  Smooch.init({
    integrationId: integrationId,
    externalId: externalId,
    jwt: jwt,
    businessName: suncoTexts.businessName,
    businessIconUrl: businessIconUrl,
    buttonIconUrl: buttonIconUrl,
    buttonWidth: '48px',
    buttonHeight: '48px',
    customColors: customColors,
    locale: suncoTexts.locale,
    customText: suncoTexts.customTexts,
    integrationOrder: [],
    menuItems: {
      imageUpload: true,
      fileUpload: true,
      shareLocation: false,
    },
    delegate: {
      beforeDisplay(message) {
        if (message.metadata && message.metadata.isHidden) {
          return null;
        }
        return message;
      },
      beforeSend(message) {
        message.metadata = {
          ...message.metadata,
          user_name: userName,
          user_email: email,
          country: countryCode,
          store_id: storeId,
          store_url: storeUrl,
        };
        return message;
      },
    },
  })
    .then(() => {
      // Replace header image and styles in Iframe
      iframe = document.getElementById(
        'web-messenger-container',
      ) as HTMLIFrameElement;

      if (iframe) {
        iframe.style.zIndex = '0';
        iframe.style.display = 'none';

        iframe.contentDocument?.head.insertAdjacentHTML(
          'beforeend',
          `<style>
          #container {
            font-family: Geist, -apple-system, BlinkMacSystemFont,
            Roboto,  "Helvetica Neue", arial, sans-serif !important;
          }

          .conversation-group
          .conversation-group-list
          .icon img {
              max-width: 32px !important;
          }

          #header .header-icon
          img {
            content: url(${headerIcon});
          }
      </style>
      `,
        );

        const innerDoc =
          iframe.contentDocument?.getElementById('messenger-button');
        if (innerDoc) innerDoc.style.display = 'none';
      }
    })
    .catch(() => Smooch.destroy());
}

function setIframeDisplayOn() {
  const iframe = document.getElementById(
    'web-messenger-container',
  ) as HTMLIFrameElement;
  if (!iframe) return;
  iframe.style.display = 'block';
}

function setIframeDisplayOff() {
  const iframe = document.getElementById(
    'web-messenger-container',
  ) as HTMLIFrameElement;
  if (!iframe) return;
  setTimeout(() => {
    iframe.style.display = 'none';
  }, 300);
}

export function openModal() {
  setIframeDisplayOn();
  Smooch.open();
}

export function statusIsOk() {
  const webMessenger = document.getElementById('web-messenger-container');
  return !!webMessenger;
}

export function closeModal() {
  setIframeDisplayOff();
  Smooch.close();
}

export function onDestroy(callback) {
  Smooch.on('destroy', () => {
    callback?.();
    setIframeDisplayOff();
  });
}

export function onClose(callback) {
  Smooch.on('widget:closed', () => {
    callback?.();
    setIframeDisplayOff();
  });
}

export function onReceivedMessage(callback) {
  Smooch.on('message:received', () => {
    if (!Smooch.isOpened()) {
      callback();
    }
  });
}

export function getTotalNoRead(): number {
  let totalNoRead = 0;
  Smooch.getConversations().forEach(({ participants }) =>
    participants.forEach(({ unreadCount }) => (totalNoRead += unreadCount)),
  );
  return totalNoRead;
}

function handleElementClass(
  element: Element,
  classToRemove: string,
  classToAdd: string,
) {
  element.classList.remove(classToRemove);
  element.classList.add(classToAdd);
}

export function toggleButtonByScroll(event: CustomEvent) {
  toggleButton(event.detail.scrollTop);
}

const buttonShowClass = `show`;
const buttonHiddenClass = `hidden`;

export function toggleButton(currentPosition: number) {
  const button = document.querySelector('#support-button');
  const badge = document.querySelector('#support-badge');

  const onScroll = currentPosition > previousPosition;
  const toRemove = onScroll ? buttonShowClass : buttonHiddenClass;
  const toAdd = onScroll ? buttonHiddenClass : buttonShowClass;

  if (button) {
    handleElementClass(button, toRemove, toAdd);
  }

  if (badge) {
    handleElementClass(badge, toRemove, toAdd);
  }

  previousPosition = currentPosition;
}
