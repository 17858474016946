import { Card, Checkbox, Title } from '@nimbus-ds/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface VisibilityCardProps {
  show: boolean;
  onChange: ({ name, value }: InterfaceNameBooleanValue) => void;
}

function VisibilityCard({ show, onChange }: VisibilityCardProps) {
  const t = useTranslationCatalog();

  const handleChangeShow = (event) =>
    onChange({ name: 'show', value: event.target.checked });

  return (
    <Card>
      <Card.Header>
        <Title as="h4">{t('products.detail.visibility')}</Title>
      </Card.Header>
      <Checkbox
        label={t('products.detail.showVariantInStore')}
        name="show"
        onChange={handleChangeShow}
        checked={show}
      />
    </Card>
  );
}

export default VisibilityCard;
