import { Box, IconButton, Popover } from '@nimbus-ds/components';
import { EllipsisIcon } from '@nimbus-ds/icons';
import MenuButton, { MenuButtonProps } from '@nimbus-ds/menubutton';
import { useBoolean } from 'commons/hooks';

interface PopoverMenuProps {
  actions: MenuButtonProps[];
}

function PopoverMenu({ actions }: PopoverMenuProps): JSX.Element {
  const [popover, showPopover, hidePopover] = useBoolean(false);
  return (
    <Popover
      content={
        <Box alignItems="flex-start" flexGrow="1">
          {actions.map((action, index) => {
            const { onClick, ...rest } = action;
            const handleClick = () => {
              hidePopover();
              onClick?.();
            };
            return <MenuButton key={index} onClick={handleClick} {...rest} />;
          })}
        </Box>
      }
      visible={popover}
      onVisibility={(current) => (current ? showPopover() : hidePopover())}
      position="bottom-end"
      arrow={false}
      padding="small"
    >
      <IconButton size="2rem" source={<EllipsisIcon />} />
    </Popover>
  );
}

export default PopoverMenu;
