import { useCallback, useMemo, useState } from 'react';
import { ProgressiveDiscount } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { ErrorScreen } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { promotionDefault } from './constants';
import { ScopeValuesInterface } from './types';
import { validationSchema } from './validationSchema';
import { PromotionPage, PromotionsDetailsSkeletons } from '../components';
import { PromotionForm } from '../components/PromotionForm';
import {
  useCreatePromotionMutation,
  usePromotionTypesQuery,
} from '../promotionsApi';

export function NewPromotionPage() {
  const t = useTranslationMarketing('promotions.settingsPage.add');
  const { goBack } = useNavegate();

  const [isExternal, setIsExternal] = useState(false);

  const [createFreeShipping, { status: statusCreate }] =
    useCreatePromotionMutation();

  const {
    data: discountTypes,
    status: statusType,
    refetch: refecchTypes,
  } = usePromotionTypesQuery();

  const discountTypeId = discountTypes ? discountTypes[0].id : '';
  const initialValues = useMemo(
    () => ({
      ...promotionDefault,
      discountTypeId,
    }),
    [discountTypeId],
  );

  const {
    values,
    errors,
    isDirty,
    handleChange,
    setFieldValue,
    handleChangeSelect,
    handleOnSubmit,
  } = useForm({
    initialValues,
    validationSchema,
    onSubmit: createFreeShipping,
  });

  const handleChangeCategories = (scopeValues: ScopeValuesInterface[]) => {
    setFieldValue('categories', scopeValues);
  };

  const handleChangeProducts = (scopeValues: ScopeValuesInterface[]) => {
    setFieldValue('products', scopeValues);
  };

  const handleChangeProgressiveDiscounts = (
    progressiveDiscounts: ProgressiveDiscount[],
  ) => {
    setFieldValue('progressiveDiscounts', progressiveDiscounts);
  };

  const handleChangeIsExternal = useCallback((value: boolean) => {
    setIsExternal(value);
  }, []);

  const saveStatus = toStatusType(statusCreate);
  const loadStatus = toStatusType(statusType);

  useToastStatus({
    error: t('error'),
    success: t('success'),
    status: saveStatus,
    onSuccess: goBack,
  });

  return (
    <PromotionPage
      mode="add"
      isDirty={isDirty}
      isExternal={isExternal}
      saveStatus={saveStatus}
      loadStatus={loadStatus}
      onSave={handleOnSubmit}
    >
      {loadStatus === 'error' && <ErrorScreen onRetry={refecchTypes} />}
      {loadStatus === 'loading' && <PromotionsDetailsSkeletons />}
      {loadStatus === 'success' && (
        <PromotionForm
          mode="add"
          values={values}
          errors={errors}
          isExternal={isExternal}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          onChangeSelect={handleChangeSelect}
          onChangeCategories={handleChangeCategories}
          onChangeProducts={handleChangeProducts}
          onChangeIsExternal={handleChangeIsExternal}
          onChangeProgressiveDiscounts={handleChangeProgressiveDiscounts}
        />
      )}
    </PromotionPage>
  );
}
