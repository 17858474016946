import amplitude from 'amplitude-js';
import isNative from '../isNative';

function logEvent(
  eventName: string,
  eventProperties: Record<string, string>,
  instanceName: string | undefined = undefined,
): void {
  eventProperties['new_admin'] = 'true';
  eventProperties['device'] = isNative() ? 'mobile' : 'desktop';
  amplitude.getInstance(instanceName).logEvent(eventName, eventProperties);
}

export default logEvent;
