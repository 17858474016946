import { ReactNode } from 'react';
import {
  Box,
  Icon,
  IconButton,
  Link,
  Popover,
  Text,
} from '@nimbus-ds/components';
import { EllipsisIcon, TrashIcon } from '@nimbus-ds/icons';
import { OrderEditProduct } from 'domains/Orders/Orders/pages/OrderEditPage/components/Products/types';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import './EditOrderProductMobileActions.scss';

interface EditOrderProductActionsProps {
  product: OrderEditProduct;
  onRemoveProduct: (productId: string, variantId: string) => void;
}

export function EditOrderProductMobileActions({
  product,
  onRemoveProduct,
}: Readonly<EditOrderProductActionsProps>) {
  const t = useTranslationOrders();

  const getAction = (text: string, icon: ReactNode, onClick: () => void) => (
    <div className="stratus--edit-order-product-mobile-actions-item">
      <Link textDecoration="none" onClick={onClick}>
        <Icon source={icon} color="neutral-textHigh" />
        <Text color="neutral-textHigh">{text}</Text>
      </Link>
    </div>
  );

  return (
    <Popover
      content={
        <Box display="flex" flexDirection="column">
          {getAction(t('editOrders.actions.remove'), <TrashIcon />, () =>
            onRemoveProduct(product.productId, product.variantId),
          )}
        </Box>
      }
      padding="small"
    >
      <IconButton source={<EllipsisIcon />} size="2em" />
    </Popover>
  );
}
