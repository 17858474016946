import { ChangeEvent, useEffect } from 'react';
import { Box } from '@nimbus-ds/components';
import { ProgressiveDiscount, PromotionRequestDto } from '@tiendanube/common';
import FeatureFlag from 'App/components/FeatureFlag';
import { PROMOTIONS_DISCOUNT_COMBINATION_TAG } from 'App/featuresFlags';
import { Dates } from 'domains/Marketing/components';
import {
  ConditionsCard,
  DiscountTypeCard,
  NameCard,
  ScopeSelector,
  DiscountCombination,
} from './components';
import { ScopeValuesInterface } from '../../pages/types';
import { usePromotionTypesQuery } from '../../promotionsApi';

export interface PromotionFormProps {
  mode: 'edit' | 'add';
  values: PromotionRequestDto;
  errors: Partial<Record<string, string>>;
  isExternal: boolean;
  setFieldValue: (name: string, value: any) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeCategories: (scopeValues: ScopeValuesInterface[]) => void;
  onChangeProducts: (scopeValues: ScopeValuesInterface[]) => void;
  onChangeIsExternal: (value: boolean) => void;
  onChangeProgressiveDiscounts: (
    progressiveDiscounts: ProgressiveDiscount[],
  ) => void;
}

export function PromotionForm({
  mode,
  values,
  errors,
  isExternal,
  setFieldValue,
  onChange,
  onChangeSelect,
  onChangeCategories,
  onChangeProducts,
  onChangeIsExternal,
  onChangeProgressiveDiscounts,
}: Readonly<PromotionFormProps>) {
  const { data: discountTypes } = usePromotionTypesQuery();

  useEffect(() => {
    const discountTypeSelected = discountTypes?.find(
      (current) => current.id === values.discountTypeId,
    );
    setFieldValue(
      'discountForQuantity',
      discountTypeSelected?.isDiscountForQuantity || false,
    );
    onChangeIsExternal(!!discountTypeSelected?.external);
  }, [discountTypes, onChangeIsExternal, setFieldValue, values.discountTypeId]);

  useEffect(() => {
    if (values.startDate > values.endDate)
      setFieldValue('endDate', values.startDate);
  }, [setFieldValue, values.endDate, values.startDate]);

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <NameCard values={values} onChange={onChange} disabled={isExternal} />
      <DiscountTypeCard
        values={values}
        errors={errors}
        onChangeSelect={onChangeSelect}
        onChangeProgressiveDiscounts={onChangeProgressiveDiscounts}
      />
      {!isExternal && (
        <ConditionsCard>
          <FeatureFlag flag={PROMOTIONS_DISCOUNT_COMBINATION_TAG}>
            <DiscountCombination values={values} onChange={onChange} />
          </FeatureFlag>
          <ScopeSelector
            disabled={mode === 'edit'}
            values={values}
            errors={errors}
            onChange={onChange}
            onChangeCategories={onChangeCategories}
            onChangeProducts={onChangeProducts}
          />
          <Dates values={values} errors={errors} onChange={onChange} />
        </ConditionsCard>
      )}
    </Box>
  );
}
