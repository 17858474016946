import { Box, Text, Link } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { CopyLink, ErrorScreen } from 'commons/components';
import { useGetDomainConfigQuery } from 'domains/Configurations/Domains/domainsApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface DNSServersInfoProps {
  domainId: string;
}

export function DNSServersInfo({ domainId }: Readonly<DNSServersInfoProps>) {
  const t = useTranslationConfigurations('domains.attachModal.step2.dns');
  const { data, isLoading, isError, refetch } =
    useGetDomainConfigQuery(domainId);

  return (
    <Box
      backgroundColor="neutral-surface"
      display="flex"
      flexDirection="column"
      borderRadius="base"
      gap="2"
    >
      <Text>
        <Trans
          t={t}
          i18nKey="copyText"
          components={{
            helpLink: (
              <Link
                as="a"
                appearance="primary"
                href={t('helpLink')}
                target="_blank"
                textDecoration="none"
              >
                {' '}
              </Link>
            ),
          }}
        />
      </Text>
      {isError && (
        <Box padding="4">
          <ErrorScreen description={t('error.title')} onRetry={refetch} />
        </Box>
      )}
      {isLoading ? (
        <Text.Skeleton width="100px" />
      ) : (
        data?.awsDns.map((dns) => (
          <Box
            key={dns}
            backgroundColor="primary-surface"
            width="fit-content"
            padding="1"
            borderRadius="base"
          >
            <CopyLink value={dns} message={t('copySuccess')} text={dns} />
          </Box>
        ))
      )}
    </Box>
  );
}
