import React from 'react';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

type useModalTextsResult = [
  title: string,
  text: React.ReactElement | string,
  confirm: string,
  progress: string,
  error: string,
  success?: string,
];

const TRANSLATE_PREFIX = 'paymentProvider.actionModal';

function useModalTexts(
  isEnableAction: boolean,
  providerName: string,
  isBrCustomPayment: boolean,
  isPayPalMXFreemium: boolean,
): useModalTextsResult {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const title = t(
    isBrCustomPayment
      ? isEnableAction
        ? 'titleEnableCustomPayment'
        : 'titleDisableCustomPayment'
      : isEnableAction
      ? 'titleEnable'
      : 'titleDisable',
    {
      providerName,
    },
  );

  let textKey: string;
  if (isEnableAction) {
    textKey = 'textEnable';
  } else if (isPayPalMXFreemium) {
    textKey = 'textDisablePayPal';
  } else {
    textKey = 'textDisable';
  }

  const text = t(textKey);
  const confirm = t(isEnableAction ? 'confirmEnable' : 'confirmDisable');
  const progress = t(isEnableAction ? 'progressEnable' : 'progressDisable');
  const success = t(isEnableAction ? 'successEnable' : 'successDisable');
  const error = t(isEnableAction ? 'errorEnable' : 'errorDisable');

  return [title, text, confirm, progress, error, success];
}

export default useModalTexts;
