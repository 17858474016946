import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchInvoiceHistoryByPagination as fetchInvoiceHistoryAction,
  getInvoiceHistory,
  getInvoiceHistoryStatus,
} from 'domains/Billing/Invoices/invoicesSlice';

function useInvoiceHistoryWeb() {
  const dispatch = useAppDispatch();
  const invoiceHistory = useSelector(getInvoiceHistory);
  const status = useSelector(getInvoiceHistoryStatus);

  const fetchInvoiceHistory = useCallback(
    (page: number) => {
      dispatch(fetchInvoiceHistoryAction(page));
    },
    [dispatch],
  );

  return {
    status,
    invoiceHistory,
    fetchInvoiceHistory,
  };
}

export default useInvoiceHistoryWeb;
