import { Card, Text, Box, Title } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import LocationText from 'domains/Orders/components/LocationText';

interface CardPickupProps {
  order: OrderDetailsResponseDto;
}

function CardPickupAddress({
  order: {
    fulfillment: { pickupDetail },
  },
}: Readonly<CardPickupProps>) {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  const title = t('cardPickupAddress.title');
  if (!pickupDetail?.locationName) return null;
  const {
    firstName,
    lastName,
    locationName,
    locationAdress,
    locationCity,
    locationProvince,
  } = pickupDetail;

  return (
    <Card title={title}>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h4">{title}</Title>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="2"
        >
          <Box>
            <Text
              fontSize="base"
              lineHeight="base"
              color="neutral-textHigh"
              textAlign="left"
            >
              {`${firstName} ${lastName}`}
            </Text>
          </Box>
          <Box>
            <Text>{`${t('cardDeliveryAddress.address')}`}</Text>
            <Text color="neutral-textHigh">{locationName}</Text>
            <LocationText
              locality={locationAdress}
              city={locationCity}
              province={locationProvince}
            />
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}

export default CardPickupAddress;
