import { useMemo } from 'react';
import { Box, Card, Checkbox, Title } from '@nimbus-ds/components';
import { CurrencyType, OrderDetailsAmountsDto } from '@tiendanube/common';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { SummaryProduct } from './SummaryProduct';
import { SummarySubtotalOld } from './SummarySubtotal';
import { OrderEditProduct } from '../Products';
import './EditOrderSummary.scss';

interface EditOrderSummaryProps {
  readonly orderAmounts: OrderDetailsAmountsDto;
  readonly products: OrderEditProduct[];
  readonly currency: CurrencyType;
  readonly isInsufficientStock?: boolean;
  readonly isOrderEdited?: boolean;
  readonly notifyCustomer: boolean;
  readonly handleNotifyCustomer: () => void;
}

function EditOrderSummaryOld({
  orderAmounts,
  products,
  currency,
  isInsufficientStock,
  isOrderEdited,
  notifyCustomer,
  handleNotifyCustomer,
}: EditOrderSummaryProps) {
  const t = useTranslationOrders();

  const newSubtotal = useMemo(
    () => products.reduce((acc, product) => acc + product.totalPrice, 0),
    [products],
  );

  return (
    <Card padding="none">
      <Box paddingX="4" paddingY="2">
        <Box paddingX="2" paddingBottom="4">
          <Title fontSize="5">{t('editOrders.summary.title')}</Title>
          {products.map((product) => (
            <SummaryProduct
              key={`${product.productId}-${product.variantId}`}
              product={product}
              currency={currency}
            />
          ))}
        </Box>
        <SummarySubtotalOld
          previousTotal={orderAmounts.subtotal}
          newTotal={newSubtotal}
          currency={currency}
          isInsufficientStock={isInsufficientStock}
          isOrderEdited={isOrderEdited}
        />
        <Box
          marginTop="2"
          paddingTop="4"
          paddingBottom="2"
          borderTopWidth="1"
          borderStyle="solid"
          borderColor="neutral-surfaceHighlight"
        >
          <Checkbox
            label={t('editOrders.sendNotification')}
            name="sendNotification"
            checked={notifyCustomer}
            onChange={handleNotifyCustomer}
            disabled={!isOrderEdited}
          />
        </Box>
      </Box>
    </Card>
  );
}

export default EditOrderSummaryOld;
