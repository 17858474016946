import { Text } from '@nimbus-ds/components';

interface DeliveryMethodsItemProps {
  methods: string[];
}

export function DeliveryMethodsItem({
  methods,
}: Readonly<DeliveryMethodsItemProps>) {
  return (
    <>
      {methods.map((method) => (
        <Text lineClamp={5} fontWeight="medium" key={method}>
          {method}
        </Text>
      ))}
    </>
  );
}
