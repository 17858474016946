import { Card, Title } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

export interface InformationCardValuesInterface {
  locationName: string;
}

export interface InformationCardErrorsInterface {
  locationName: string;
}

interface InformationCardProps {
  values: InformationCardValuesInterface;
  errors: Partial<InformationCardErrorsInterface>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function InformationCard({
  values,
  errors,
  onChange,
}: InformationCardProps): JSX.Element {
  const t = useTranslationShipping();
  const title = t('locations.form.informations.title');
  const locationHelpText = t(errors.locationName || '');

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{title}</Title>
      </Card.Header>
      <Card.Body>
        <FormField.Input
          type="text"
          onChange={onChange}
          name="locationName"
          showHelpText={!!locationHelpText && !values.locationName}
          value={values.locationName}
          appearance={
            locationHelpText && !values.locationName ? 'danger' : 'none'
          }
          helpText={locationHelpText}
        />
      </Card.Body>
    </Card>
  );
}

InformationCard.Skeleton = Skeleton;

export default InformationCard;
