import * as Yup from 'yup';
import { emailRegex } from 'commons/utils';

export const account = Yup.object().shape({
  user: Yup.string()
    .required('oca.config.account.errors.user')
    .matches(emailRegex, 'oca.config.account.errors.userInvalid'),
  password: Yup.string().required('oca.config.account.errors.password'),
  numberAccount: Yup.string()
    .required('oca.config.account.errors.numberAccount')
    .matches(
      /^$|\d{6}\/\d{3}/,
      'oca.config.account.errors.numberAccountInvalid',
    ),
});
export const operational = Yup.object().shape({
  door: Yup.string().required('oca.config.operational.errors.door'),
  store: Yup.string().required('oca.config.operational.errors.store'),
  storeToDoor: Yup.string().required(
    'oca.config.operational.errors.storeToDoor',
  ),
  storeToStore: Yup.string().required(
    'oca.config.operational.errors.storeToStore',
  ),
});

export const ocaConfigarationSchemaStep2 = Yup.object().shape({
  account,
  operational,
});
