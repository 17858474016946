import { Table } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export function CouponsListHeader() {
  const t = useTranslationMarketing('coupons.headers');

  return (
    <Table.Head>
      <Table.Row>
        <CustomHeaderCell width={{ xl: '25%', lg: 'auto' }}>
          {t('code')}
        </CustomHeaderCell>
        <CustomHeaderCell>{t('discount')}</CustomHeaderCell>
        <CustomHeaderCell>{t('shipping')}</CustomHeaderCell>
        <CustomHeaderCell>{t('validity')}</CustomHeaderCell>
        <CustomHeaderCell width="60px">{t('uses')}</CustomHeaderCell>
        <CustomHeaderCell width="90px">{t('limits')}</CustomHeaderCell>
        <CustomHeaderCell width="135px">{t('status')}</CustomHeaderCell>
        <CustomHeaderCell width="120px">{t('actions')}</CustomHeaderCell>
      </Table.Row>
    </Table.Head>
  );
}
