import { useCallback, useEffect, useState } from 'react';
import { TagIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Input, Stack, Chip, Text } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import {
  HeaderContent,
  HeaderTop,
  IconSaveLink,
  VirtualInteractiveListSkeleton,
} from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import SelectProductListItem from './SelectProductListItem';
import { SelectProductListProps } from './types';
import useSelectProductsList from './useSelectProductsList';
import SelectProductEmptySearch from '../SelectProductEmptySearch';

interface EmptyOrSearchStateProps {
  title?: string;
  text?: string;
}

function EmptyOrSearchState({ title, text }: EmptyOrSearchStateProps) {
  return title ? (
    <EmptyMessage title={title} text={text} icon={<TagIcon />} />
  ) : (
    <SelectProductEmptySearch />
  );
}

function SelectProductList({
  title,
  subtitle,
  isShow,
  onClose,
  onChange,
  selectedProducts,
  limit,
  trimTitle = false,
  addItemsToTop = false,
  sortBy = null,
  emptyTitle,
  emptyText,
  refreshProducts,
  setRefreshProducts,
}: SelectProductListProps): JSX.Element {
  const t = useTranslationCatalog();
  const [searchValue, setSearchValue] = useState('');
  const {
    products,
    isLoading,
    isInternalServerError,
    isSuccess,
    fetchDraftOrderProducts,
    getMoreDraftOrdersProducts,
    isRefreshing,
  } = useSelectProductsList({
    sortBy,
  });

  useToastStatus({
    error: t('products.selectProductList.internalServerError'),
    status: isInternalServerError ? 'error' : 'idle',
  });

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      fetchDraftOrderProducts(searchValue);
    }, 300);
    setRefreshProducts?.(false);
    return () => clearTimeout(debouncedSearch);
  }, [
    searchValue,
    fetchDraftOrderProducts,
    refreshProducts,
    setRefreshProducts,
  ]);

  const handleOnChange = ({ value }: { value: string }) => {
    setSearchValue(value);
  };

  const handleOnClearSelecteds = () => onChange([]);

  const handleOnSubmit = ({ value }) => {
    setSearchValue(value);
    fetchDraftOrderProducts(value);
  };

  const handleOnSelect = ({ name, checked }) => {
    if (checked) {
      const product = products?.filter((product) => product.id === name);
      onChange(
        addItemsToTop
          ? [...(product || []), ...selectedProducts]
          : [...selectedProducts, ...(product || [])],
      );
    } else {
      onChange(selectedProducts.filter((product) => product.id !== name));
    }
  };

  const onReached = useCallback(() => {
    getMoreDraftOrdersProducts(searchValue);
  }, [getMoreDraftOrdersProducts, searchValue]);

  const headerNavigation = { onClick: onClose };

  const showSubtitleAndSearch = !(
    searchValue === '' &&
    products?.length === 0 &&
    emptyTitle
  );

  return (
    <ModalAside
      hasVirtualizationContent
      isOpen={isShow}
      onDidDismiss={onClose}
      headerTop={
        <HeaderTop
          navigation={headerNavigation}
          actions={<IconSaveLink onClick={onClose} />}
        />
      }
      headerContent={<HeaderContent title={title} />}
    >
      <Stack column align="stretch">
        {showSubtitleAndSearch && (
          <>
            {!!subtitle && (
              <Stack.Item>
                <Text> {subtitle} </Text>
              </Stack.Item>
            )}
            <Stack.Item>
              <Input
                type="search"
                name="product"
                placeholder={t('products.selectProductList.placeholder')}
                value={searchValue}
                onChange={handleOnChange}
                onSubmit={handleOnSubmit}
              />
            </Stack.Item>
          </>
        )}
        {selectedProducts.length > 0 && (
          <Stack.Item>
            <Chip
              id="SelectProductListChip"
              label={`${t('products.selectProductList.selected', {
                count: selectedProducts.length,
              })}`}
              onDismiss={handleOnClearSelecteds}
            />
          </Stack.Item>
        )}
        <Stack.Item>
          {isLoading && <VirtualInteractiveListSkeleton />}
          {isSuccess && searchValue !== '' && products?.length === 0 && (
            <SelectProductEmptySearch />
          )}
          {isSuccess && searchValue === '' && products?.length === 0 && (
            <EmptyOrSearchState title={emptyTitle} text={emptyText} />
          )}
          {isSuccess && products && (
            <SelectProductListItem
              products={products}
              onChange={handleOnSelect}
              onReached={onReached}
              reachedLimit={limit ? selectedProducts.length >= limit : false}
              selectedProducts={selectedProducts}
              isRefreshing={isRefreshing}
              trimTitle={trimTitle}
            />
          )}
        </Stack.Item>
      </Stack>
    </ModalAside>
  );
}

export default SelectProductList;
