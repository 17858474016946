import { Box, Text, Title } from '@nimbus-ds/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import useOnboardingTasks from '../OnboardingTasks/useOnboardingTasks';
import './HeaderPage.scss';

interface TitleWordBreakProps {
  children: React.ReactNode;
}

// We have incorporated this internal component with CSS to introduce
// the functionality of adding a line break to a long word,
// since Nimbus components do not support this property.
function TitleWordBreak({ children }: TitleWordBreakProps) {
  return <div className="stratus--title-word-break">{children}</div>;
}

function HeaderPage() {
  const t = useTranslationDashboard();
  const { isLoading, isSuccess, hasCompleted, hasStartedOnboarding } =
    useOnboardingTasks();
  const { name: storeName } = useStoreInfo();

  const onboardingStarted = t('header.onboardingStarted');
  const onboardingDidNotStart = t('header.onboardingDidNotStart');

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <TitleWordBreak>
        <Title as="h2" fontWeight="regular">
          {t('header.title', { storeName })}
        </Title>
      </TitleWordBreak>
      {isLoading && <Text.Skeleton width="340px" fontSize="highlight" />}
      {isSuccess && !hasCompleted && (
        <TitleWordBreak>
          <Title as="h3" fontWeight="regular">
            {hasStartedOnboarding ? onboardingStarted : onboardingDidNotStart}
          </Title>
        </TitleWordBreak>
      )}
    </Box>
  );
}

export default HeaderPage;
