import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryCreateRequestDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Input,
  InteractiveListPriv,
  InterfaceNameValue,
  Text,
} from '@tiendanube/components';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { Stack } from 'commons/components';
import { useAutoFocus, useToast } from 'commons/hooks';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useCategories } from 'domains/Catalog/Categories/hooks';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingProductDetailCreateCategorySuccess } from 'domains/Catalog/Products/tracking';
import ModalConfirmationCreateCategory, {
  NewCategoryInterface,
} from '../ModalConfirmationCreateCategory';

interface SelectCategoriesProps {
  onChange: (s: string[]) => void;
  selecteds: string[];
}

const optionsMock = [...Array(7)].map(() => ({
  title: '',
  name: '',
  fixed: {
    name: '',
    prefix: '',
  },
  active: false,
}));

function SelectCategories({
  onChange,
  selecteds,
}: SelectCategoriesProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const language = useGetLanguage();
  const [isShowModal, setIsShowModal] = useState(false);
  const { categories, isLoading, createCategory } = useCategories();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const [search, setSearch] = useState('');

  const { ref } = useAutoFocus();

  const sender = useTrackFullCatalog();
  const searchLowerCase = search.toLowerCase();
  const options = isLoading
    ? optionsMock
    : categories
        .filter(
          ({ title }) =>
            !search || title.toLowerCase().includes(searchLowerCase),
        )
        .map(({ title, fixed, id }) => ({
          title,
          fixed,
          name: id,
          active: selecteds.includes(id),
        }));

  const handleOnCloseModal = () => setIsShowModal(false);
  const handleOnOpenModal = () => setIsShowModal(true);

  const onCreateCategory = async (newCategory) => {
    addToastProgress({ label: t('products.detail.creatingCategory') });
    try {
      const categoryResponse = await createCategory(newCategory);
      if (categoryResponse) {
        sender(trackingProductDetailCreateCategorySuccess);
        addToast({
          label: t('products.detail.createCategorySuccess'),
          appearance: 'success',
        });
        onChange([...selecteds, categoryResponse.id]);
      }
    } catch (error) {
      addToast({
        label: t('products.detail.createCategoryError'),
        appearance: 'danger',
      });
    }
    closeToastProgress();
  };

  const handleCreateCategory = ({
    name,
    parent,
    googleShoppingCategory,
    seoTitle,
    seoDescription,
    handle,
  }: NewCategoryInterface): void => {
    const payload: CategoryCreateRequestDto = {
      name: {
        [language]: name,
      },
      google_shopping_category: googleShoppingCategory,
      parent: parent || undefined,
      seo_title: seoTitle[language]
        ? seoTitle
        : { ...seoTitle, [language]: '' },
      seo_description: seoDescription[language]
        ? seoDescription
        : { ...seoDescription, [language]: '' },
      handle: handle[language] ? handle : { ...handle, [language]: '' },
    };

    onCreateCategory(payload);
  };

  const handleOnChange = (categoryId: string) => {
    const newSelecteds = [...selecteds];
    const categoryIdIndex = newSelecteds.findIndex((c) => c === categoryId);
    if (categoryIdIndex >= 0) newSelecteds.splice(categoryIdIndex, 1);
    else newSelecteds.push(categoryId);

    onChange(newSelecteds);
  };

  const handleOnChangeSearch = (e: InterfaceNameValue) => {
    setSearch(e.value);
  };

  return (
    <>
      <Stack innerRef={ref} column align="stretch">
        <Input
          name="categorySearch"
          placeholder={t('products.detail.searchCategory')}
          value={search}
          onChange={handleOnChangeSearch}
        />
        <InteractiveListPriv
          skeleton={isLoading}
          onClickAddItem={handleOnOpenModal}
          addItemLabel={t('products.detail.newCategory')}
        >
          {!isLoading &&
            options.map(({ fixed, name, active }) => (
              <InteractiveListPriv.CheckItem
                key={`option-${name}`}
                name={name}
                title=""
                blockLeft={
                  <Text>
                    <Text.Span appearance="light">{fixed.prefix}</Text.Span>
                    {fixed.title}
                  </Text>
                }
                checked={active}
                onChange={() => handleOnChange(name)}
              />
            ))}
        </InteractiveListPriv>
      </Stack>
      <ModalConfirmationCreateCategory
        isShow={isShowModal}
        onClose={handleOnCloseModal}
        onCreateCategory={handleCreateCategory}
      />
    </>
  );
}

export default SelectCategories;
