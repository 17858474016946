import { logEvent } from 'commons/utils/tracking';
import {
  MENU_PRIMARY_HOME_CLICK,
  DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_PAYMENT_ALL,
  DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_SHIPPING_ALL,
  DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_PACKING,
  DASHBOARD_SELECT_THEME_CLICK,
  DASHBOARD_ADD_PRODUCT_CLICK,
  DASHBOARD_SHIPPING_CLICK,
  DASHBOARD_PAYMENTS_CLICK,
  ONBOARDING_ENP_CLICK,
  DASHBOARD_SELECT_STATISTICS_CLICK,
  DAHSBOARD_ADDITIONAL_SETTINGS_CLICK,
  DASHBOARD_FREE_COURSES_CLICK,
  DASHBOARD_BOOST_STORE_CLICK,
  DASHBOARD_2FA_CLICK,
  DASHBOARD_2FA_WARNING_CLICK,
  DASHBOARD_2FA_LIMITED_ACCESS_CLICK,
  DASHBOARD_BILLING_TRANSACTION_FEES_PAGE_CLICK,
  DASHBOARD_BILLING_TRANSACTION_FEES_PAYMENT_AVAILABLE_CLICK,
  DASHBOARD_BILLING_TX_FEES_PAGE_FROM_REMINDER_MODAL_CLICK,
  DASHBOARD_TRANSACTION_FEES_FREE_NUVEM_PAGO_CLICK,
  DASHBOARD_RECOVERY_CODES_UPDATE_AFTER_LOGIN,
  CLOUDFLARE_SOFTBLOCK_UPDATED_CLICK,
  CLOUDFLARE_SOFTBLOCK_TUTORIAL_CLICK,
  CLOUDFLARE_SOFTBLOCK_SKIP_CLICK,
  ONBOARDING_ENA_CLICK,
} from 'config/trackingEvents';

type EventProperiesType = Record<string, string>;
const emptyProperties = {};

export const trackingMenuPrimaryHome = () => {
  logEvent(MENU_PRIMARY_HOME_CLICK, emptyProperties);
};
export const trackingOrdersFrequentAccessWaitingPaymentAll = () => {
  logEvent(
    DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_PAYMENT_ALL,
    emptyProperties,
  );
};
export const trackingOrdersFrequentAccessWaitingShippingAll = () => {
  logEvent(
    DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_SHIPPING_ALL,
    emptyProperties,
  );
};
export const trackingOrdersFrequentAccessWaitingPacking = () => {
  logEvent(DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_PACKING, emptyProperties);
};
export const trackingSelectThemeClick = () => {
  logEvent(DASHBOARD_SELECT_THEME_CLICK, emptyProperties);
};
export const trackingAddProductClick = () => {
  logEvent(DASHBOARD_ADD_PRODUCT_CLICK, emptyProperties);
};
export const trackingShippingClick = () => {
  logEvent(DASHBOARD_SHIPPING_CLICK, emptyProperties);
};
export const trackingPaymentsClick = () => {
  logEvent(DASHBOARD_PAYMENTS_CLICK, emptyProperties);
};
export const trackingEnpClick = () => {
  logEvent(ONBOARDING_ENP_CLICK, emptyProperties);
};
export const trackingEnaClick = () => {
  logEvent(ONBOARDING_ENA_CLICK, emptyProperties);
};
export const trackingSelectStatisticsClick = () => {
  logEvent(DASHBOARD_SELECT_STATISTICS_CLICK, emptyProperties);
};
export const trackingAdditionalSettingClick = (
  eventProperties: EventProperiesType,
) => {
  logEvent(DAHSBOARD_ADDITIONAL_SETTINGS_CLICK, eventProperties);
};
export const trackingFreeCoursesClick = (
  eventProperties: EventProperiesType,
) => {
  logEvent(DASHBOARD_FREE_COURSES_CLICK, eventProperties);
};
export const trackingBoostStoreClick = (
  eventProperties: EventProperiesType,
) => {
  logEvent(DASHBOARD_BOOST_STORE_CLICK, eventProperties);
};
export const tracking2faClick = () => {
  logEvent(DASHBOARD_2FA_CLICK, emptyProperties);
};
export const tracking2faWarningClick = () => {
  logEvent(DASHBOARD_2FA_WARNING_CLICK, emptyProperties);
};
export const tracking2faLimitedAccessClick = () => {
  logEvent(DASHBOARD_2FA_LIMITED_ACCESS_CLICK, emptyProperties);
};
export const trackingBillingTransactionFeesPageClick = () => {
  logEvent(DASHBOARD_BILLING_TRANSACTION_FEES_PAGE_CLICK, emptyProperties);
};
export const trackingBillingTransactionFeesPaymentAvailableClick = () => {
  logEvent(
    DASHBOARD_BILLING_TRANSACTION_FEES_PAYMENT_AVAILABLE_CLICK,
    emptyProperties,
  );
};
export const trackingBillingTxFeesPageReminderModalClick = () => {
  logEvent(
    DASHBOARD_BILLING_TX_FEES_PAGE_FROM_REMINDER_MODAL_CLICK,
    emptyProperties,
  );
};
export const trackingTransactionFeesFreeNuvemPagoClick = () => {
  logEvent(DASHBOARD_TRANSACTION_FEES_FREE_NUVEM_PAGO_CLICK, emptyProperties);
};

export const trackingRecoveryCodesUpdateAfterLogin = (): void => {
  logEvent(DASHBOARD_RECOVERY_CODES_UPDATE_AFTER_LOGIN, {});
};

export const trackingSoftblockUpdatedClick = (): void => {
  logEvent(CLOUDFLARE_SOFTBLOCK_UPDATED_CLICK, {});
};

export const trackingSoftblockTutorialClick = (): void => {
  logEvent(CLOUDFLARE_SOFTBLOCK_TUTORIAL_CLICK, {});
};

export const trackingSoftblockSkipClick = (): void => {
  logEvent(CLOUDFLARE_SOFTBLOCK_SKIP_CLICK, {});
};
