import { Table, Text } from '@nimbus-ds/components';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import {
  Actions,
  ShowMoreScope,
  StatusTagItem,
} from 'domains/Marketing/Promotions/components';

interface PromotionsListDesktopRowProps {
  promotion: PromotionsItemListResponseDto;
}

export function PromotionsListDesktopRow({
  promotion: { id, name, discountType, scope, scopeType, validity, status },
}: Readonly<PromotionsListDesktopRowProps>) {
  return (
    <Table.Row>
      <Table.Cell>
        <Text lineClamp={1} fontWeight="medium">
          {name}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text lineClamp={1} fontWeight="medium">
          {discountType}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <ShowMoreScope scope={scope} scopeType={scopeType} />
      </Table.Cell>
      <Table.Cell>
        <Text>{validity}</Text>
      </Table.Cell>
      <Table.Cell>
        <StatusTagItem status={status} />
      </Table.Cell>
      <Table.Cell>
        <Actions id={id} status={status} />
      </Table.Cell>
    </Table.Row>
  );
}
