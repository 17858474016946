import { useEffect, useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  CategoryCreateRequestDto,
  CategoryResponseDto,
  CategoryTabedResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useToast } from 'commons/hooks';
import {
  createCategory as createCategoryAction,
  getCategoryOptions as getCategoryOptionsAction,
} from 'domains/Catalog/Categories/categoriesSlice';
import {
  getCategoryOptions,
  getCategoriesStatus,
} from 'domains/Catalog/Categories/categoriesSlice/categoriesSelectors';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface useCategoriesInterface {
  categories: CategoryTabedResponseDto[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isIdle: boolean;
  createCategory: CreateCategoryType;
}

type CreateCategoryType = (
  category: CategoryCreateRequestDto,
) => Promise<CategoryResponseDto | undefined>;

function useCategories(): useCategoriesInterface {
  const dispatch = useAppDispatch();
  const t = useTranslationCatalog();
  const { addToast } = useToast();
  const categories = useSelector(getCategoryOptions);
  const { isIdle, isLoading, isError, isSuccess } =
    useSelector(getCategoriesStatus);

  const fetchCategoryOptions = useCallback(async () => {
    try {
      const result = await dispatch(getCategoryOptionsAction());
      return unwrapResult(result);
    } catch (error) {
      addToast({
        label: t('products.detail.getCategoriesError'),
        appearance: 'danger',
      });
    }
  }, [addToast, dispatch, t]);

  useEffect(() => {
    if (isIdle) {
      fetchCategoryOptions();
    }
  }, [fetchCategoryOptions, isIdle]);

  const createCategory: CreateCategoryType = async (newCategory) => {
    try {
      const result = await dispatch(createCategoryAction(newCategory));
      const { category } = unwrapResult(result);

      return category;
    } catch (error) {
      throw new Error(error);
    }
  };

  return {
    isIdle,
    isLoading,
    isError,
    isSuccess,
    categories,
    createCategory,
  };
}

export default useCategories;
