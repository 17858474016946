import { PromotionResponseDto } from '@tiendanube/common';
import { addMounthsToday, getTodayIsoString } from 'commons/utils/date';

export const promotionDefault: PromotionResponseDto = {
  name: '',
  discountTypeId: '',
  scopeType: 'all',
  categories: [],
  products: [],
  discountForQuantity: false,
  quantity: null,
  discount: null,
  progressiveDiscounts: [{ quantity: 0, discount: 0 }],
  dateType: 'unlimited',
  startDate: getTodayIsoString(),
  endDate: addMounthsToday(1),
  combinesWithOtherDiscounts: true,
};
