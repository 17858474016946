import { createSelector } from '@reduxjs/toolkit';
import {
  FulfillmentOrderForPrintManager,
  OrderFulfillmentsDto,
} from '@tiendanube/common';
import { OrdersFiltersValuesResponseDto } from '@tiendanube/common/src/domains/orders/commons/OrdersFiltersByTypeResponseDto';
import { OrderShippingMethod } from '@tiendanube/common/src/domains/orders/orders/OrderBulkResponseDto';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { InterfaceOrdersSchema } from '../orderSlice';

export const getOrdersState = (state: RootStateType): InterfaceOrdersSchema =>
  state.orders.orders;

export const ordersExportSelector = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.export,
);

const getEntityById = (_: RootStateType, id: string) => id;
const getEntityByIds = (_: RootStateType, ids: string[]) => ids;

export const getOrdersStatus = createSelector(getOrdersState, (orderState) => ({
  isLoading: orderState.status === 'loading',
  isError: orderState.status === 'error',
  isSuccess: orderState.status === 'success',
}));

export const getOrdersStatusSuccess = createSelector(
  getOrdersState,
  (orderState) => orderState.statusSuccess,
);

export const getOrdersIds = createSelector(
  getOrdersState,
  (orders) => orders.ids,
);

export const getBulkOrdersIds = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.bulkOrders.map(({ orderId }) => orderId),
);

export const getFulfillmentOrders = createSimpleSelector(
  [getOrdersState],
  (orders): OrderFulfillmentsDto[] =>
    orders.bulkOrders.length
      ? orders.bulkOrders
      : Object.values(orders.entities).map(
          ({ id: orderId, fulfillmentOrders }) => ({
            orderId,
            fulfillments: fulfillmentOrders.map(
              ({ id, shipping_info, status }) => ({
                fulfillmentId: id,
                status,
                shippingType: shipping_info.shipping_type,
              }),
            ),
            fulfillmentIds: fulfillmentOrders.map(({ id }) => `${id}`),
          }),
        ),
);

export const getFulfillmentOrdersForPrintManager = createSimpleSelector(
  [getOrdersState],
  (orders): FulfillmentOrderForPrintManager[] =>
    Object.values(orders.entities).length > 0
      ? Object.values(orders.entities).flatMap(({ fulfillmentOrders }) =>
          fulfillmentOrders.flatMap(({ id, location }) => ({
            id,
            location: {
              id: location.id,
              name: location.name,
            },
          })),
        )
      : orders.entityDetails.data?.fulfillmentOrders !== undefined
      ? orders.entityDetails.data?.fulfillmentOrders?.flatMap(
          ({ id, assignedLocation }) => ({
            id,
            location: {
              id: assignedLocation.id,
              name: assignedLocation.name,
            },
          }),
        )
      : [],
);

export const getOrdersShippingMethods = createSimpleSelector(
  [getOrdersState],
  (orders): OrderShippingMethod[] =>
    Object.values(orders.entities).map(({ id: orderId, shipping }) => ({
      orderId,
      shipping,
    })),
);

export const getMissingTrackingNumberOrderCount = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.bulkOrdersMissingTracking,
);

export const getOrdersEntities = createSelector(
  getOrdersState,
  (orders) => orders.entities,
);

export const getAppliedFilters = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.appliedFilters,
);

export const getOrdersExportStatus = createSimpleSelector(
  [ordersExportSelector],
  (ordersExport) => ({
    isLoading: ordersExport.status === 'loading',
    isError: ordersExport.status === 'error',
    isSuccess: ordersExport.status === 'success',
  }),
);

export const getOrdersExportTotal = createSimpleSelector(
  [ordersExportSelector],
  (ordersExport) => ordersExport.totalResults,
);

export const getOrderById = createSelector(
  getOrdersEntities,
  getEntityById,
  (ordersEntities, id) => ordersEntities[id],
);

export const getOrdersPagination = createSelector(
  getOrdersState,
  (orders) => orders.pagination,
);

export const getNewOrdersCount = createSelector(
  getOrdersState,
  (orders) => orders.newOrdersCount,
);

export const getOrderDetails = createSelector(
  getOrdersState,
  (orders) => orders.entityDetails,
);

export const getOrdersByIds = createSelector(
  getOrdersEntities,
  getEntityByIds,
  (ordersEntities, ids) =>
    ids.filter((id) => ordersEntities[id]).map((id) => ordersEntities[id]),
);

export const getOrderDetailsWithStatus = createSelector(
  getOrderDetails,
  (orderState) => ({
    isLoading: orderState.status === 'loading',
    isError: orderState.status === 'error',
    orderDetails: orderState.data,
  }),
);

export const getOrdersCount = createSimpleSelector(
  [getOrdersPagination],
  (state) => state.totalResults,
);

export const getTotalResultsOpened = createSimpleSelector(
  [getOrdersState],
  (state) => state.totalResultsOpened,
);

export const getTotalResultsAllOrders = createSimpleSelector(
  [getOrdersState],
  (state) => state.totalAllOrders,
);

export const getUpdateOrdersStatus = createSelector(
  getOrdersState,
  (orders) => orders.updateOrderStatus,
);

export const getTimelineStatus = createSimpleSelector(
  [getOrdersState],
  ({ entityDetails: { timeline } }) =>
    convertStatusTypeToObject(timeline.status),
);

export const getTimeline = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.entityDetails.timeline.data,
);

export const getOrderFulfillmentOrders = createSelector(
  getOrderDetails,
  (orderDetails) => orderDetails.data?.fulfillmentOrders,
);

export const exportOrdersDownloadStatus = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.export.downloadStatus,
);

export const getApplicationFiltersData = createSimpleSelector(
  [getOrdersState],
  (orders): OrdersFiltersValuesResponseDto[] =>
    orders.filtersByType.data?.application ?? [],
);

export const getPaymentMethodsFiltersData = createSimpleSelector(
  [getOrdersState],
  (orders): OrdersFiltersValuesResponseDto[] =>
    orders.filtersByType.data?.paymentMethod ?? [],
);

export const getPaymentProvidersFiltersData = createSimpleSelector(
  [getOrdersState],
  (orders): OrdersFiltersValuesResponseDto[] =>
    orders.filtersByType.data?.paymentProvider ?? [],
);

export const getEditOrderStatus = createSelector(
  getOrdersState,
  (orderState) => ({
    ...convertStatusTypeToObject(orderState.editOrder.status),
    status: orderState.editOrder.status,
  }),
);

export const getBouncedEmails = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.bouncedEmails.data,
);

export const getEditOrderShippingCosts = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.editOrderShippingCosts.data,
);

export const getEditOrderShippingCostsStatus = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.editOrderShippingCosts.statuses,
);

export const getOrdersAggregations = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.aggregations,
);
