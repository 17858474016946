/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Smooch from 'smooch';
import { useGetUserInfo } from 'domains/Auth/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import {
  startSmooch,
  toggleButton,
  onClose as smoochOnClose,
  onReceivedMessage as smoochOnReceivedMessage,
  onDestroy as smoochOnDestroy,
} from './startSmooch';

interface SunshineConversationsProps {
  onCloseModal: () => void;
  onReceivedMessage: () => void;
  onReady: () => void;
  onDestroy: () => void;
}

function SunshineConversations({
  onCloseModal,
  onReceivedMessage,
  onDestroy,
  onReady,
}: SunshineConversationsProps): null {
  const { id: externalId, email, firstName, lastName } = useGetUserInfo();
  const { id: storeId, country: countryCode, url: storeUrl } = useStoreInfo();
  const userName = firstName + ' ' + lastName;
  const { pathname } = useLocation();

  useEffect(() => {
    startSmooch({
      externalId,
      userName,
      email,
      countryCode,
      storeId,
      storeUrl,
      onReady,
    });

    return () => {
      Smooch.destroy();
    };
  }, [externalId, userName, email, countryCode, storeId, storeUrl]);

  useEffect(() => {
    toggleButton(0);
  }, [pathname]);

  useEffect(() => {
    smoochOnClose(onCloseModal);
  }, []);

  useEffect(() => {
    smoochOnReceivedMessage(onReceivedMessage);
  }, []);

  useEffect(() => {
    smoochOnDestroy(onDestroy);
  }, []);

  return null;
}
export default SunshineConversations;
