import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchInvoiceHistoryByScroll as fetchInvoiceHistoryAction,
  getInvoiceHistory,
  getInvoiceHistoryStatus,
  fetchInvoiceHistoryByPagination,
  getInvoiceHistoryUpdateStatus,
  getInvoiceIds,
} from 'domains/Billing/Invoices/invoicesSlice';

function useInvoiceHistoryMobile() {
  const dispatch = useAppDispatch();
  const invoiceHistory = useSelector(getInvoiceHistory);
  const status = useSelector(getInvoiceHistoryStatus);
  const invoiceIds = useSelector(getInvoiceIds);
  const updateStatus = useSelector(getInvoiceHistoryUpdateStatus);

  const fetchMoreInvoiceHistory = useCallback(() => {
    dispatch(fetchInvoiceHistoryAction());
  }, [dispatch]);

  const fetchInvoiceHistory = useCallback(async () => {
    await dispatch(fetchInvoiceHistoryByPagination(1));
  }, [dispatch]);

  return {
    status,
    invoiceIds,
    updateStatus,
    invoiceHistory,
    fetchInvoiceHistory,
    fetchMoreInvoiceHistory,
  };
}

export default useInvoiceHistoryMobile;
