import { memo, useEffect } from 'react';
import { Popover, Stack } from '@tiendanube/components';
import { DownloadIcon, ListIcon, PlusCircleIcon } from '@tiendanube/icons';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import {
  ActionProp,
  ActionsDesktop,
  ApplicationsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import Responsive, { useResponsive } from 'commons/components/Responsive';
import { CAN_LINK_ABOUT_PRODUCTS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useHasPermission } from 'domains/Auth/hooks';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import {
  AlertMetafields,
  ErrorMetafieldAlert,
} from 'domains/Metafields/components';
import { useAppsLinks } from 'domains/PartnersApps/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import {
  ProductResultsDesktop,
  ProductResultsMobile,
  AlertNoWeightAndDimensions,
  ProductListSearch,
  ProductListEmpty,
  BulkActions,
  BulkActionsProvider,
  useBulkActions,
} from './components';
import { AlertEditPrices } from './components/BulkActions/AlertEditPrices';
import { ProductsFiltersType } from '../../productsServices';
import {
  trackingProductAddNewProductClick,
  trackingProductExportImport,
  trackingProductListFilterEvent,
  trackingProductListOrderClick,
} from '../../tracking';
import useProductsList from '../hooks/useProductsList';

function ProductListPageWithTrasnlations(): JSX.Element {
  return (
    <BulkActionsProvider>
      <ProductListPageMemo />
    </BulkActionsProvider>
  );
}

const ProductListPageMemo = memo(ProductListPage);

function ProductListPage(): JSX.Element {
  const t = useTranslationCatalog();
  const language = useTranslationLanguage();
  const { goTo } = useNavegate();
  const { toggleEditMode, editMode, selectedRowsId, showAlertMetafields } =
    useBulkActions();
  const { getAppsLinks } = useAppsLinks();
  const { jobStatus } = useBackgroundJobs('editPricesProducts');
  const { isMobile } = useResponsive();
  const canImportExportProductCsv = useHasPermission(
    'import_export_products_csv',
  );

  const {
    isLoading,
    isSuccess,
    statusSuccess,
    productsIds,
    productsCount,
    filters,
    hasFilters,
    getProductsList,
    removeMetafieldsFilters,
    refreshProductsList,
  } = useProductsList();

  const { fetchLocations } = useLocations();
  const sender = useTrackFullCatalog();

  useEffect(() => {
    if (jobStatus !== 'ready') getProductsList();
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters.page === 1 && jobStatus === 'ready') refreshProductsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobStatus]);

  useEffect(() => {
    if (statusSuccess === 'errorMetafield') {
      removeMetafieldsFilters();
    }
  }, [filters, removeMetafieldsFilters, statusSuccess]);

  const editModeAction = {
    onClick: toggleEditMode,
    children: editMode
      ? `${t('common:editMode.cancel')}`
      : `${t('common:editMode.edit')}`,
  };

  const handleOnClickAdd = () => {
    trackingProductAddNewProductClick();
    goTo('/products/new');
  };
  const handleOnClickImportExport = () => {
    goTo('/products/import/');
    trackingProductExportImport();
  };

  const handleChangeFilters = (newFilters: ProductsFiltersType) => {
    sender(() => {
      trackingProductListFilterEvent(newFilters);
    });
    getProductsList(newFilters);
  };

  const orderProductsAction: ActionProp = {
    children: `${t('products.orderProducts')}`,
    onClick: () => {
      sender(trackingProductListOrderClick);
      goTo('/products/organize');
    },
    icon: ListIcon,
  };
  const exportImportAction: ActionProp = {
    children: `${t('products.importExport')}`,
    onClick: handleOnClickImportExport,
    icon: DownloadIcon,
  };
  const addProductAction: ActionProp = {
    children: t('products.addProduct'),
    icon: PlusCircleIcon,
    appearance: 'primary',
    onClick: handleOnClickAdd,
  };

  const actions: ActionProp[] = canImportExportProductCsv
    ? [orderProductsAction, exportImportAction, addProductAction]
    : [orderProductsAction, addProductAction];

  const renderMenuMobile = (
    <Popover menu={actions} name="dropdownMenu" position="right" />
  );

  const applicationsDesktop: ActionProp[] = getAppsLinks(
    'products',
    'default',
  ).map((app) => ({
    children: app.text,
    onClick: () => window.open(app.url, '_blank'),
  }));

  const helpLink = (
    <HelpLink
      title={t('helpLink.aboutProducts')}
      linkURL={CAN_LINK_ABOUT_PRODUCTS[language]}
      currentViewTracking={t('helpLink.aboutProductsTrackingName')}
      previousValue="product_list"
    />
  );

  if (isSuccess && productsIds.length === 0 && !hasFilters) {
    return (
      <IonPageStratus
        width="medium"
        headerContent={<HeaderContent title={t('products.title')} />}
      >
        <ProductListEmpty />
        {helpLink}
      </IonPageStratus>
    );
  }

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        selectedRowsId.length === 0 && (
          <HeaderTop mainAction={editModeAction} actions={renderMenuMobile} />
        )
      }
      headerContent={
        <HeaderContent
          title={t('products.title')}
          actions={<ActionsDesktop actions={actions} renderActionsAsButtons />}
          applications={
            <ApplicationsDesktop applications={applicationsDesktop} />
          }
        />
      }
    >
      <AlertNoWeightAndDimensions />
      <AlertMetafields
        show={showAlertMetafields}
        ownerResource="product_variant"
      />
      <AlertEditPrices />
      <ErrorMetafieldAlert status={statusSuccess} />
      <BulkActions />
      <Stack column align="stretch" spacing={isMobile ? 'tight' : 'base'}>
        <ProductListSearch
          filters={filters}
          productsCount={productsCount}
          isLoading={isLoading}
          onChange={handleChangeFilters}
        />
        <Responsive
          mobile={
            <ProductResultsMobile
              isEditMode={editMode}
              onToggleEditMode={toggleEditMode}
            />
          }
          desktop={<ProductResultsDesktop />}
        />
      </Stack>
      {helpLink}
    </IonPageStratus>
  );
}

export default memo(ProductListPageWithTrasnlations);
