import { Radio, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { Categories } from 'domains/Marketing/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export function CategoriesSelector({
  categories,
  categoriesType,
  onChange,
  onChangeCategories,
}) {
  const t = useTranslationMarketing('freeShipping.asides.categories');

  return (
    <Stack column spacing="tight" align="flex-start">
      <Text fontWeight="bold">{t('title')}:</Text>
      <Stack spacing="tight">
        <Radio
          id="category-type-1"
          name="categoriesType"
          as="button"
          value="all"
          checked={categoriesType === 'all'}
          label={t('all')}
          onChange={onChange}
        />
        <Radio
          id="category-type-2"
          name="categoriesType"
          as="button"
          value="some"
          checked={categoriesType === 'some'}
          label={t('some')}
          onChange={onChange}
        />
      </Stack>
      {categoriesType === 'some' && (
        <Categories
          categories={categories}
          emptyTitle={t('empty.title')}
          emptyText={t('empty.text')}
          onChange={onChangeCategories}
          showTitle={false}
        />
      )}
    </Stack>
  );
}
