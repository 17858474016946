import { Box, Card, Skeleton, Text } from '@nimbus-ds/components';

export function PromotionsDetailsSkeletons() {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Card>
        <Card.Body>
          <Box display="flex" gap="5" flexDirection="column">
            <Text.Skeleton height="30px" width="80px" />
            <Text.Skeleton fontSize="highlight" width="large" />
          </Box>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Box display="flex" gap="5" flexDirection="column">
            <Text.Skeleton height="30px" width="100px" />
            <Text.Skeleton fontSize="highlight" width="large" />
          </Box>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Box display="flex" gap="5" flexDirection="column">
            <Text.Skeleton height="30px" width="100px" />
            <Text.Skeleton fontSize="highlight" width="180px" />
            <Text.Skeleton fontSize="highlight" width="80px" />
            <Box display="flex" gap="2">
              <Skeleton width="54px" height="28px" borderRadius="4px" />
              <Skeleton width="54px" height="28px" borderRadius="4px" />
              <Skeleton width="54px" height="28px" borderRadius="4px" />
            </Box>
            <Text.Skeleton fontSize="highlight" width="80px" />
            <Box display="flex" gap="2">
              <Skeleton width="60px" height="28px" borderRadius="4px" />
              <Skeleton width="60px" height="28px" borderRadius="4px" />
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}
