import { FreeShippingFormState } from '../types';

export const freeShippingDefault: FreeShippingFormState = {
  methods: [],
  priceMinimum: '',
  categoriesType: 'all',
  categories: [],
  zonesType: 'all',
  zones: [],
  combinesWithOtherDiscounts: true,
};
