import { InterfaceNameValue, Stack } from '@tiendanube/components';
import { CardWithTitle, InputNumber } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

export interface CostAndDaysValuesInterface {
  additionalCost: number;
  additionalDays: number;
}

interface CostAndDaysCardProps extends CostAndDaysValuesInterface {
  onChange: (data: InterfaceNameValue) => void;
}

function CostAndDaysCard({
  additionalCost,
  additionalDays,
  onChange,
}: CostAndDaysCardProps): JSX.Element {
  const t = useTranslationShipping();

  const additionalCostValue = additionalCost !== 0 ? `${additionalCost}` : '';
  const additionalDaysValue = additionalDays !== 0 ? `${additionalDays}` : '';

  return (
    <CardWithTitle
      title={t('oca.config.costAndDays.title')}
      subtitle={t('oca.config.costAndDays.subtitle')}
    >
      <Stack column align="stretch">
        <InputNumber
          type="float"
          name="additionalCost"
          label={t('oca.config.costAndDays.additionalCost')}
          value={additionalCostValue}
          helpText={t('oca.config.costAndDays.additionalCostSubline')}
          onChange={onChange}
        />
        <InputNumber
          type="integer"
          name="additionalDays"
          label={t('oca.config.costAndDays.additionalDays')}
          value={additionalDaysValue}
          helpText={t('oca.config.costAndDays.additionalDaysSubline')}
          onChange={onChange}
        />
      </Stack>
    </CardWithTitle>
  );
}

CostAndDaysCard.Skeleton = Skeleton;

export default CostAndDaysCard;
