import { useCallback, useState } from 'react';
import { Link, Text } from '@nimbus-ds/components';
import { AiFeature } from '@tiendanube/common/src/enums';
import { AiFeedbackModal, Stack } from 'commons/components';
import { useDrawableCanvas } from 'commons/hooks';
import useAiFeedbackFlow from 'commons/hooks/useAiFeedbackFlow';
import useSmartErase from 'domains/Ai/hooks/useSmartErase';
import {
  trackingAiEditImageSmartEraseEraseClick,
  trackingAiEditImageSmartEraseUndoClick,
  trackingAiEditImageSmartEraseUseClick,
} from 'domains/Ai/tracking';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ImageGalleryState } from '../../../../../ImageGallery';
import EditPhotoBaseModal from '../../../EditImageBaseModal';
import { EditedImage } from '../../../EditImageModal';

interface SmartEraseModalProps {
  currentImage: ImageGalleryState;
  onSaveImage: (editedImage: EditedImage) => void;
  isShow?: boolean;
  onClose?: () => void;
}

function SmartEraseModal({
  currentImage,
  onSaveImage,
  isShow = false,
  onClose,
}: Readonly<SmartEraseModalProps>) {
  const t = useTranslationCatalog(
    'products.modal.editPhoto.actions.smartErase.modal',
  );

  const [canvasIsEmpty, setCanvasIsEmpty] = useState(true);

  const { canvasRef, getCanvasAsDataURL, clearCanvas, undoCanvas } =
    useDrawableCanvas();

  const {
    displayedImage,
    handleSmartErase,
    changingBackground,
    unSavedChanges,
    errorOnBackgroundChange,
  } = useSmartErase(currentImage);

  const handleEraseClick = () => {
    handleSmartErase(getCanvasAsDataURL());
    clearCanvas();
    trackingAiEditImageSmartEraseEraseClick();
  };

  const handleUndoClick = () => {
    undoCanvas();
    trackingAiEditImageSmartEraseUndoClick();
  };

  const handleCanvasDraw = useCallback(
    (isEmpty: boolean) => {
      setCanvasIsEmpty(isEmpty);
    },
    [setCanvasIsEmpty],
  );

  const handleOnSubmit = () => {
    if (unSavedChanges && displayedImage.base64) {
      onSaveImage({ base64: displayedImage.base64 });
      trackingAiEditImageSmartEraseUseClick();
    }
    if (onClose) {
      onClose();
    }
  };

  const [
    showAiGenerationFeedbackModal,
    openAiGenerationFeedbackModal,
    closeAiGenerationFeedbackModal,
  ] = useAiFeedbackFlow(AiFeature.AI_IMAGES_SMART_ERASE, onClose);

  const handleOnCloseWithFeedback = () => {
    if (unSavedChanges) {
      openAiGenerationFeedbackModal();
    } else {
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <>
      <EditPhotoBaseModal
        isShow={isShow}
        canvasRef={canvasRef}
        onCanvasDraw={handleCanvasDraw}
        loadingImage={changingBackground}
        displayImageSource={displayedImage}
        onClose={handleOnCloseWithFeedback}
        translationPrefix="products.modal.editPhoto.actions.smartErase.modal"
        afterImageChildren={
          <Stack column align="stretch" spacing="tight">
            <Text color="neutral-textHigh">{t('subtitle')}</Text>
            <Stack justify="flex-start" spacing="tight">
              <Link
                as="button"
                appearance="primary"
                onClick={handleEraseClick}
                disabled={changingBackground || canvasIsEmpty}
              >
                {t('eraseCTA')}
              </Link>
              <Link
                as="button"
                appearance="neutral"
                onClick={handleUndoClick}
                disabled={changingBackground || canvasIsEmpty}
              >
                {t('undoCTA')}
              </Link>
            </Stack>
          </Stack>
        }
        handleOnSave={handleOnSubmit}
        enableSaveButton={
          unSavedChanges && !changingBackground && !errorOnBackgroundChange
        }
      />
      <AiFeedbackModal
        asAside
        show={showAiGenerationFeedbackModal}
        title={t('feedback.title')}
        text={t('feedback.text')}
        feature={AiFeature.AI_IMAGES_SMART_ERASE}
        feedbackParams={{
          imageEditing: {
            oldImageUrl: currentImage.publicUrl!,
            newImageBase64: displayedImage.base64!,
          },
        }}
        onDismiss={() => {
          closeAiGenerationFeedbackModal();
          if (onClose) {
            onClose();
          }
        }}
      />
    </>
  );
}

export default SmartEraseModal;
