import { useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { ErrorState, Label, Stack } from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { useNavegate } from 'App/hooks';
import { Stepper, useStepper } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import useTimeout from 'commons/hooks/useTimeOut';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  ConfigureOcaStep1,
  ConfigureOcaStep1Values,
  ConfigureOcaStep2,
  ConfigureOcaStep2Values,
  ConfigureOcaStep3,
  ConfigureOcaStep3Values,
  SuccessCard,
} from './components';
import { defaultValues, initDirty } from './components/constants';
import useOcaConfiguration from './hooks/useOcaConfiguration/useOcaConfiguration';
import { onDirtyType } from './types';
import { SHIPPING_METHODS_BASE_ROUTE } from '../../shippingRoutes';

interface ConfigureOcaMultiStepProps {
  initialStep?: 1 | 3;
  showStep?: boolean;
}

function ConfigureOcaMultiStep({
  initialStep = 1,
  showStep = false,
}: ConfigureOcaMultiStepProps) {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();
  const { scrollToTop } = useLayoutScroll();
  const { activeStep, setStep } = useStepper(initialStep);
  const goToDeliveryMethodsDelay = useTimeout(() => {
    goTo(SHIPPING_METHODS_BASE_ROUTE);
  }, 100);

  const {
    ocaConfiguration,
    ocaConfigurationLoadStatus,
    ocaConfigurationSaveStatus,
    ocaConfigurationUpdateStatus,
    getOcaConfiguration,
    createOcaConfiguration,
    updateOcaConfiguration,
  } = useOcaConfiguration();

  const initialValues = ocaConfiguration ?? defaultValues;
  const [multiFormData, setMultiFormData] = useState(initialValues);
  const [dirty, setDirty] = useState(initDirty);
  const isCreateMode = initialValues.id === '';
  const {
    originAddress: { street, number, apartment },
  } = multiFormData;
  const door = `${t(
    'oca.config.operational.defaultOriginDoor',
  )} - ${street} ${number} ${apartment}`;
  const labelStep = t('oca.config.step', { step: activeStep });
  const valuesStep1 = useMemo(
    () => ({
      sender: initialValues.sender,
      originAddress: initialValues.originAddress,
    }),
    [initialValues],
  );
  const valuesStep2 = useMemo(
    () => ({
      account: initialValues.account,
      operational: initialValues.operational,
    }),
    [initialValues],
  );
  const valuesStep3 = useMemo(
    () => ({
      additionalCost: initialValues.additionalCost,
      additionalDays: initialValues.additionalDays,
      allowFreeShipping: initialValues.allowFreeShipping,
    }),
    [initialValues],
  );

  const onChangeStep1 = (values: ConfigureOcaStep1Values) => {
    setMultiFormData({ ...multiFormData, ...values });
    setStep(2);
  };
  const onChangeStep2 = (values: ConfigureOcaStep2Values) => {
    setMultiFormData({ ...multiFormData, ...values });
    setStep(3);
  };
  const onChangeStep3 = (values: ConfigureOcaStep3Values) => {
    setMultiFormData({ ...multiFormData, ...values });
    const payload = {
      sender: multiFormData.sender,
      originAddress: multiFormData.originAddress,
      account: multiFormData.account,
      operational: multiFormData.operational,
      ...values,
    };
    if (isCreateMode) {
      createOcaConfiguration(payload);
    } else {
      updateOcaConfiguration(initialValues.id, payload);
    }
  };

  const handleDirty: onDirtyType = useCallback((step, value) => {
    setDirty((current) => ({ ...current, [step]: value }));
  }, []);

  useEffect(() => {
    if (!ocaConfiguration) getOcaConfiguration();
  }, [getOcaConfiguration, ocaConfiguration]);

  useEffect(() => {
    setMultiFormData(initialValues);
    setDirty(initDirty);
  }, [initialValues]);

  useEffect(() => {
    scrollToTop('auto');
  }, [scrollToTop, activeStep]);

  useToastStatus({
    status: ocaConfigurationSaveStatus,
    error: t('oca.config.errorSave.create'),
    onSuccess() {
      setStep(4);
    },
  });

  useToastStatus({
    status: ocaConfigurationUpdateStatus,
    success: t('oca.config.successUpdate'),
    error: t('oca.config.errorSave.update'),
    onSuccess() {
      goToDeliveryMethodsDelay();
    },
  });

  return (
    <>
      {activeStep !== 4 && showStep && (
        <Stack>
          <Label id="step" label={labelStep} appearance="primary" />
        </Stack>
      )}
      <Prompt
        when={dirty.step1 || dirty.step2 || dirty.step3}
        message={t('common:exitEdit.info')}
      />
      {ocaConfigurationLoadStatus.isLoading && (
        <Stepper activeStep={activeStep}>
          <ConfigureOcaStep1.Skeleton />
          <ConfigureOcaStep2.Skeleton />
          <ConfigureOcaStep3.Skeleton />
        </Stepper>
      )}
      {ocaConfigurationLoadStatus.isError && (
        <ErrorState
          title={t('oca.config.errorState.title')}
          action={{
            children: t('oca.config.errorState.retry'),
            onClick: getOcaConfiguration,
          }}
        />
      )}
      {ocaConfigurationLoadStatus.isSuccess && (
        <Stepper activeStep={activeStep}>
          <ConfigureOcaStep1
            values={valuesStep1}
            onChange={onChangeStep1}
            onDirty={handleDirty}
          />
          <ConfigureOcaStep2
            values={valuesStep2}
            zipcode={initialValues.sender.zipcode}
            door={door}
            onChange={onChangeStep2}
            onDirty={handleDirty}
          />
          <ConfigureOcaStep3
            values={valuesStep3}
            isCreateMode={isCreateMode}
            isSaving={
              ocaConfigurationSaveStatus === 'loading' ||
              ocaConfigurationUpdateStatus === 'loading'
            }
            onChange={onChangeStep3}
            onDirty={handleDirty}
          />
          <SuccessCard />
        </Stepper>
      )}
    </>
  );
}

export default ConfigureOcaMultiStep;
