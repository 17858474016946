import { Accordion, Text } from '@nimbus-ds/components';
import { ExternalLink, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface DNSVerificationStepProps {
  step: number;
}

export function DNSVerificationStep({
  step,
}: Readonly<DNSVerificationStepProps>) {
  const t = useTranslationConfigurations('domains.attachModal.verification');

  const index = `${step - 1}`;

  return (
    <Accordion.Item index={index}>
      <Accordion.Header title={`${step}. ${t('title')}`} borderBottom="base" />
      <Accordion.Body>
        <Stack column align="stretch">
          <Text>{t('text')}</Text>
          <ExternalLink
            appearance="primary"
            textDecoration="none"
            href={t('helpLink.href')}
          >
            {t('helpLink.title')}
          </ExternalLink>
        </Stack>
      </Accordion.Body>
    </Accordion.Item>
  );
}
