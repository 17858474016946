import { Box, Button, Card, Text, Title } from '@nimbus-ds/components';
import { ThemesConfigImagesResponseDto } from '@tiendanube/common';
import { useModal } from 'commons/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { IdentityModal } from './IdentityModal';

interface IdentityCardProps {
  images: ThemesConfigImagesResponseDto;
  ogImageStatus: 'enabled' | 'disabled';
}

export function IdentityCard({
  images,
  ogImageStatus,
}: Readonly<IdentityCardProps>) {
  const t = useTranslationOnline('themesPage.identity');
  const [showModal, openModal, closeModal] = useModal();

  const logo = images.logo;

  return (
    <>
      <Card>
        <Card.Header>
          <Title as="h4">{t('title')}</Title>
        </Card.Header>
        <Card.Body>
          {!!logo && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="neutral-surface"
              borderRadius="2"
              padding="2"
              minHeight="95px"
            >
              <img src={logo} alt="Logo" style={{ maxHeight: '80px' }} />
            </Box>
          )}
          {!logo && <Text color="neutral-textLow">{t('body')}</Text>}
        </Card.Body>
        <Card.Footer>
          {logo ? (
            <Button onClick={openModal}>{t('editLogo')}</Button>
          ) : (
            <Button onClick={openModal}>{t('uploadLogo')}</Button>
          )}
        </Card.Footer>
      </Card>
      <IdentityModal
        images={images}
        ogImageStatus={ogImageStatus}
        showModal={showModal}
        onClose={closeModal}
      />
    </>
  );
}
