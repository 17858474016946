import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
  ShippingAppOptionsResponseDto,
  ShippingAppResponseDto,
} from '@tiendanube/common';
import { ErrorScreen } from 'commons/components';
import { useForm, useStorage, useToastStatus } from 'commons/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import { trackingShippingModalities } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import useGetDetailActiveApp from './useGetDetailActiveApp';
import useUpdateDetailActiveApp from './useUpdateDetailActiveApp';
import CarriersForm from '../components/CarriersForm';
import ShippingPage from '../components/ShippingPage';
import NuvemEnvioJadlogModal from '../DeliveryMethodsPage/components/NuvemEnvioJadlogModal';

const mock = {};

function EditCarriersPage() {
  const t = useTranslationShipping();
  const { id } = useParams<{ id: string }>();
  const { id: storeId } = useStoreInfo();
  const { detailActiveApp, fetchDetailActiveApp, isError, isLoading } =
    useGetDetailActiveApp();
  const [shouldShowNuvemEnvioJadlogModal, setShouldShowNuvemEnvioJadlogModal] =
    useStorage('wasNuvemEnvioJadlogModalShown', false);

  const { updateDetailActive, status } = useUpdateDetailActiveApp();

  const sortNuvemEnvio = (
    response: ShippingAppResponseDto,
  ): Record<string, ShippingAppOptionsResponseDto> => {
    if (response.name !== 'Nuvem Envio') return response.options;
    const {
      'ne-jadlog-package': jadlogPackage,
      'ne-jadlog-com': jadlogCom,
      ...otherOptions
    } = response.options;
    return {
      ...(jadlogPackage && { 'ne-jadlog-package': jadlogPackage }),
      ...(jadlogCom && { 'ne-jadlog-com': jadlogCom }),
      ...otherOptions,
    };
  };

  const data = useMemo(() => {
    if (detailActiveApp?.options) {
      return sortNuvemEnvio(detailActiveApp);
    }
    return mock;
  }, [detailActiveApp]);

  const { values, handleOnSubmit, handleChangeGroup } = useForm({
    initialValues: data,
    onSubmit: (data) => {
      if (data) {
        updateDetailActive(id, data);

        if (detailActiveApp?.name) {
          const options = Object.values(data).map((option) => {
            const typedOption = option as ShippingAppOptionsResponseDto;

            return {
              code: typedOption.code,
              isEnabled: typedOption.isEnabled,
            };
          });

          trackingShippingModalities(
            storeId,
            detailActiveApp.name,
            JSON.stringify(options),
          );
        }
      }
    },
  });

  useToastStatus({
    status,
    success: t('carriers.successToast'),
    error: t('carriers.errorToast'),
  });

  const handleOnFetchDetailActiveApp = useCallback(() => {
    fetchDetailActiveApp(id);
  }, [fetchDetailActiveApp, id]);

  useEffect(() => {
    if (detailActiveApp?.id !== id) handleOnFetchDetailActiveApp();
  }, [detailActiveApp, handleOnFetchDetailActiveApp, id]);

  const NeJadlogComExists =
    (!isLoading &&
      detailActiveApp?.options &&
      detailActiveApp?.options['ne-jadlog-com']?.isEnabled === true) ||
    false;
  const NeJadlogPackageExists =
    (!isLoading &&
      detailActiveApp?.options &&
      detailActiveApp?.options['ne-jadlog-package']?.isEnabled === true) ||
    false;

  useEffect(() => {
    if (
      (NeJadlogComExists || NeJadlogPackageExists) &&
      !shouldShowNuvemEnvioJadlogModal
    ) {
      setShouldShowNuvemEnvioJadlogModal(true);
    }
  }, [
    NeJadlogComExists,
    NeJadlogPackageExists,
    setShouldShowNuvemEnvioJadlogModal,
    shouldShowNuvemEnvioJadlogModal,
  ]);

  return (
    <ShippingPage
      isError={isError}
      handleSave={handleOnSubmit}
      title={isLoading ? 'skeleton' : detailActiveApp?.name ?? ''}
      isLoading={isLoading}
      isUpdating={status === 'loading'}
    >
      {isError && (
        <ErrorScreen
          description={t('genericError.text')}
          onRetry={handleOnFetchDetailActiveApp}
        />
      )}
      {isLoading && <CarriersForm.Skeleton />}
      {detailActiveApp && !isLoading && !isError && (
        <CarriersForm
          values={values}
          handleOnChangeGroup={handleChangeGroup}
          isCompatibleWithMultiCD={detailActiveApp.isMultiCDCompatible}
        />
      )}

      {shouldShowNuvemEnvioJadlogModal && <NuvemEnvioJadlogModal />}
    </ShippingPage>
  );
}

export default EditCarriersPage;
