import { Box, Text } from '@nimbus-ds/components';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { PopoverToogle, Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { ShowMoreScope } from 'domains/Marketing/Promotions/components';

type ConditionsProps = Pick<
  PromotionsItemListResponseDto,
  'scope' | 'scopeType' | 'validity'
>;

export function Conditions({
  scope,
  scopeType,
  validity,
}: Readonly<ConditionsProps>) {
  const t = useTranslationMarketing('promotions.conditions');
  return (
    <PopoverToogle
      label={t('label')}
      content={
        <Box flexGrow="1">
          <Stack column align="flex-start" gap="4">
            <Box display="flex" flexDirection="column" gap="2">
              <Text fontWeight="bold">{t('scope')}</Text>
              <ShowMoreScope scope={scope} scopeType={scopeType} />
            </Box>
            <Box display="flex" flexDirection="column" gap="2">
              <Text fontWeight="bold">{t('validity')}</Text>
              <Text>{validity}</Text>
            </Box>
          </Stack>
        </Box>
      }
    />
  );
}
