/* eslint-disable max-statements */
import { useMemo } from 'react';
import {
  OrderDetailsResponseDto,
  OrderFulfillRequestDto,
  OrderFulfillmentUpdateRequestDto,
  StatusEnum,
} from '@tiendanube/common';
import { FulfillmentPreferenceType } from '@tiendanube/common/src/enums';
import { Card, InterfaceLabel, Text, Title } from '@tiendanube/components';
import FeatureFlag from 'App/components/FeatureFlag';
import { NEW_ADMIN_ORDER_DETAILS } from 'App/featuresFlags';
import { Layout, RemarksCard, useResponsive } from 'commons/components';
import { useAsyncFunc, useDocumentTitle } from 'commons/hooks';
import { PICKUP_TYPE } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import {
  CustomerDataCard,
  DateAndDevice,
  DetailsHeader,
  OrderSummaryCard,
} from 'domains/Orders/components';
import getShippingOrderStatus from 'domains/Orders/Orders/utils/getShippingOrderStatus';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import useActionsToast from './useActionsToast';
import { fulfillment } from '../../../OrderListPage/components/commons/Status/status';
import {
  CardBillingAddress,
  CardDeliveryAddress,
  CardPickupAddress,
  CardPayment,
  CardFulfillment,
  CardTrackingPage,
  SectionOrdersMetafields,
  UnclaimedStockAlert,
} from '../../components';
import CardCustomerCustomization from '../CardCustomerCustomization';
import CardFulfillmentOrdersList from '../CardFulfillmentOrdersList';
import CardOrderAndFulfillment from '../CardOrderAndFulfillment';
import { DeliveryAddressValuesType } from '../EditDeliveryAdressModal/EditDeliveryAdressModal';
import TimelineSection from '../TimelineSection';

interface OrderDetailsProps {
  orderDetails: OrderDetailsResponseDto;
  onRemarkOrder: (text: string) => void;
  onPaidOrder: (
    gateway?: string,
    gatewayMethod?: string,
    gatewayName?: string,
  ) => Promise<OrderDetailsResponseDto>;
  onPartiallyPaidOrder: (
    method: string,
    name?: string,
  ) => Promise<OrderDetailsResponseDto>;
  onPackOrder: (
    fulfillments?: OrderFulfillmentUpdateRequestDto[],
  ) => Promise<OrderDetailsResponseDto>;
  onFulfillOrder: (
    orderFulfillRequest: OrderFulfillRequestDto,
  ) => Promise<OrderDetailsResponseDto>;
  onEditDeliveryAddress: (deliveryAddress: DeliveryAddressValuesType) => void;
}

function OrderDetailsPage({
  orderDetails,
  onRemarkOrder,
  onPaidOrder,
  onPartiallyPaidOrder,
  onPackOrder,
  onFulfillOrder,
  onEditDeliveryAddress,
}: OrderDetailsProps): JSX.Element {
  const t = useTranslationOrders();
  const preferenceFulfillmentType = orderDetails.fulfillment.preference.type;
  const { isDesktop } = useResponsive();
  const { onError, onPaidSuccess, onPackSuccess, onFulfillSuccess } =
    useActionsToast(preferenceFulfillmentType);

  const { name } = useStoreInfo();

  const titleDocument = t('orderSummaryCard.title');
  useDocumentTitle(titleDocument);

  const [handlePaidOrder, isMarkingAsPaid] = useAsyncFunc(
    async (gateway?: string, gatewayMethod?: string, gatewayName?: string) =>
      await onPaidOrder(gateway, gatewayMethod, gatewayName),
    onPaidSuccess,
    onError,
  );

  const [handlePartialPayment, isMakingPartialPayment] = useAsyncFunc(
    async (method?: string, name?: string) => {
      if (method) {
        await onPartiallyPaidOrder(method, name);
      }
    },
    onPaidSuccess,
    onError,
  );

  const [handlePackOrder, isPacking] = useAsyncFunc(
    async () => await onPackOrder(),
    onPackSuccess,
    onError,
  );

  const [handleFulfilledOrder, isFulfilling] = useAsyncFunc(
    async (fulfilled?: OrderFulfillRequestDto) => {
      if (fulfilled) await onFulfillOrder(fulfilled);
    },
    onFulfillSuccess,
    onError,
  );

  const handleRemark = async (value: string) => {
    await onRemarkOrder(value);
  };

  const fufillmentStatusLabel: InterfaceLabel = useMemo<InterfaceLabel>(() => {
    const fulfillmentLabel = fulfillment(
      orderDetails.fulfillment.status as StatusEnum,
      preferenceFulfillmentType,
    );

    return {
      ...fulfillmentLabel,
      id: fulfillmentLabel.status,
      label: `${t(
        `status.${getShippingOrderStatus(
          fulfillmentLabel.status,
          orderDetails.isShippablePickup,
        )}`,
      )}`,
    };
  }, [
    orderDetails.fulfillment.status,
    preferenceFulfillmentType,
    orderDetails.isShippablePickup,
    t,
  ]);

  const showUnclaimedStockAlert = orderDetails.products.some(
    (product) =>
      !!product.issues &&
      Object.keys(product.issues).some((issue) => issue === 'unclaimedStock'),
  );
  const unclaimedStockProducts = orderDetails.products
    .filter((p) => p.issues?.unclaimedStock)
    .map((p) => p.title);

  const subject = t('customerDataCard.mailSubject', {
    numberOrder: orderDetails.number,
    storeName: name,
  });
  const body = t('customerDataCard.mailBody', {
    clientName: orderDetails.consumer.name,
  });
  const waText = t('customerDataCard.waText', {
    numberOrder: orderDetails.number,
    storeName: name,
    clientName: orderDetails.consumer.name,
    interpolation: { escapeValue: false },
  });

  const hasFulfillmentOrders: boolean =
    !!orderDetails.fulfillmentOrders &&
    orderDetails.fulfillmentOrders.length > 0;

  const totalProducts = orderDetails.products.reduce(
    (total, product) => total + product.quantity,
    0,
  );
  const cardOrderAndFulfillmentTitle = `${totalProducts} 
  ${t('orderSummaryCard.productInOrder', {
    count: totalProducts,
  })}`;

  const hideNotInformedEmail: boolean = orderDetails.origin === 'pos';

  const isPickup = useMemo(
    () =>
      orderDetails.fulfillment.preference.type === PICKUP_TYPE ||
      orderDetails.isShippablePickup,
    [orderDetails.fulfillment.preference.type, orderDetails.isShippablePickup],
  );

  const isDigital = useMemo(
    () =>
      orderDetails.fulfillment.preference.type ===
      FulfillmentPreferenceType.DELIVERY_DIGITAL,
    [orderDetails.fulfillment.preference.type],
  );

  return (
    <Layout
      mainContent
      right={
        <>
          {isDesktop && (
            <Title type="h2">{`${t('detail.subTitleAside')}`}</Title>
          )}
          <CustomerDataCard
            id={orderDetails.consumer.id}
            name={orderDetails.contact_name}
            email={{
              to: orderDetails.consumer.email,
              subject,
              body,
            }}
            whatsapp={
              orderDetails.contact_phone
                ? {
                    phoneNumber: orderDetails.contact_phone,
                    message: waText,
                  }
                : undefined
            }
            identification={orderDetails.contact_identification}
            businessName={
              orderDetails.billingAddress?.businessName ?? undefined
            }
            tradeName={orderDetails.billingAddress?.tradeName ?? undefined}
            fiscalRegime={
              orderDetails.billingAddress?.fiscalRegime ?? undefined
            }
            invoiceUse={orderDetails.billingAddress?.invoiceUse ?? undefined}
            country={orderDetails.billingAddress?.country ?? undefined}
            stateRegistration={
              orderDetails.billingAddress?.stateRegistration ?? undefined
            }
            businessActivity={
              orderDetails.billingAddress?.businessActivity ?? undefined
            }
            hideNotInformedEmail={hideNotInformedEmail}
            isAnonymized={orderDetails.consumer.isAnonymized}
          />
          {isPickup ? (
            <CardPickupAddress order={orderDetails} />
          ) : (
            orderDetails.fulfillment.preference.deliveryAddress?.street && (
              <CardDeliveryAddress
                order={orderDetails}
                onEditDeliveryAddress={onEditDeliveryAddress}
              />
            )
          )}
          {!!orderDetails.billingAddress?.street &&
            (isDigital ||
              isPickup ||
              !orderDetails?.sameBillingAndShippingAddress) && (
              <CardBillingAddress order={orderDetails} />
            )}
          <TimelineSection order={orderDetails} />
          <CardTrackingPage trackingPageLink={orderDetails.trackingPageLink} />
        </>
      }
      left={
        <>
          <DetailsHeader
            title={t('detail.subTitleMain')}
            right={
              <DateAndDevice
                date={orderDetails.date}
                origin={orderDetails.origin}
                orderOrigin={orderDetails.orderOrigin}
                initiatedBy={orderDetails.initiatedBy}
              />
            }
          />
          <UnclaimedStockAlert
            productNames={unclaimedStockProducts}
            show={showUnclaimedStockAlert}
          />
          <FeatureFlag
            flag={NEW_ADMIN_ORDER_DETAILS}
            renderElse={
              <OrderSummaryCard
                products={orderDetails.products}
                amounts={orderDetails.amounts}
                currency={orderDetails.currency}
                receiptId={orderDetails.id}
              />
            }
          >
            {hasFulfillmentOrders ? (
              <CardFulfillmentOrdersList
                order={orderDetails}
                packOrder={onPackOrder}
                fulfillOrder={onFulfillOrder}
              />
            ) : (
              <CardOrderAndFulfillment
                headerLabel={fufillmentStatusLabel}
                order={orderDetails}
                packOrder={handlePackOrder}
                fulfillOrder={handleFulfilledOrder}
                isLoading={isPacking || isFulfilling}
                products={orderDetails.products}
                title={cardOrderAndFulfillmentTitle}
              />
            )}
          </FeatureFlag>

          <CardPayment
            order={orderDetails}
            paidOrder={handlePaidOrder}
            isMarkingAsPaid={isMarkingAsPaid}
            partiallyPaidOrder={handlePartialPayment}
            isMakingPartialPayment={isMakingPartialPayment}
          />

          <FeatureFlag
            flag={NEW_ADMIN_ORDER_DETAILS}
            renderElse={
              <CardFulfillment
                order={orderDetails}
                packOrder={handlePackOrder}
                fulfillOrder={handleFulfilledOrder}
                urlTrackingCode={orderDetails.urlTrackingCode}
                isLoading={isPacking || isFulfilling}
                headerLabel={fufillmentStatusLabel}
              />
            }
          />
          <SectionOrdersMetafields orderId={orderDetails.id} />
          {!!orderDetails.consumer?.remarks && (
            <Card title={t('cardCustomerRemark.title')}>
              <Text background>{orderDetails.consumer.remarks}</Text>
            </Card>
          )}
          {orderDetails.customerCustomfields && (
            <CardCustomerCustomization
              customerCustomfields={orderDetails.customerCustomfields}
            />
          )}
          <RemarksCard
            title={t('remarksCard.title')}
            errorMessage={t('remarksCard.error')}
            remarks={orderDetails.remarks}
            onRemark={handleRemark}
          />
        </>
      }
    />
  );
}

export default OrderDetailsPage;
