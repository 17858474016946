import { PrinterIcon } from '@nimbus-ds/icons';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import {
  Status as StatusEnum,
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
} from '@tiendanube/common/src/enums';
import { ActionProp } from 'commons/components';
import { useHasPermission } from 'domains/Auth/hooks';
import {
  DocumentType,
  FULFILLMENT_ORDER_ID_IDENTIFIER,
} from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import { useGoToPrintPage } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/hooks';
import { isShippingMethodOca } from '../../components/EditDeliveryAdressModal/utils';

export const useBuildPrintLabelAction = (
  orderDetails: OrderDetailsResponseDto,
  t,
): ActionProp[] => {
  const printLabelAction: ActionProp[] = [];
  const hasPrintShippingLabelPermission = useHasPermission(
    'print_order_shipping_labels',
  );
  const { goToPrintPage } = useGoToPrintPage();

  if (!orderDetails) {
    return printLabelAction;
  }

  const fulfillmentOrders = orderDetails?.fulfillmentOrders || [];

  const isDigital =
    orderDetails.fulfillment?.preference?.type ===
    FulfillmentPreferenceTypeEnum.DELIVERY_DIGITAL;

  if (
    orderDetails?.status === StatusEnum.OPEN &&
    hasPrintShippingLabelPermission
  ) {
    const shouldGenerateOcaTag = () =>
      isShippingMethodOca(orderDetails?.shipping) &&
      orderDetails?.fulfillment?.preference?.deliveryAddress
        ?.hasGeneratedTag === false;
    const isOca = isShippingMethodOca(orderDetails.shipping);

    const shippableFulfillmentOrders = fulfillmentOrders.filter(
      (fulfillmentOrder) =>
        fulfillmentOrder?.shipping?.type !==
        FulfillmentPreferenceTypeEnum.DELIVERY_DIGITAL,
    );

    if (shippableFulfillmentOrders.length > 1 && !isOca && !isDigital) {
      printLabelAction.push(
        ...shippableFulfillmentOrders.map((fulfillmentOrder) => ({
          children: t('detail.printLabelPopover', {
            location: fulfillmentOrder.assignedLocation.name,
          }),
          onClick: () => {
            goToPrintPage({
              ids: [`${FULFILLMENT_ORDER_ID_IDENTIFIER}${fulfillmentOrder.id}`],
              type: DocumentType.LABEL,
            });
          },
          icon: PrinterIcon,
          name: 'printLabel',
        })),
        {
          children: t('detail.printLabelAll'),
          onClick: () => {
            goToPrintPage({
              ids: [orderDetails.id],
              type: DocumentType.LABEL,
            });
          },
          icon: PrinterIcon,
          name: 'printLabel',
        },
      );
    } else if (!isDigital) {
      const [fulfillmentOrder] = shippableFulfillmentOrders;
      printLabelAction.push({
        children: t('detail.printLabel'),
        onClick: () => {
          goToPrintPage({
            ids:
              shippableFulfillmentOrders.length === 1 && !isOca
                ? [`${FULFILLMENT_ORDER_ID_IDENTIFIER}${fulfillmentOrder.id}`]
                : [orderDetails.id],
            type: DocumentType.LABEL,
            hasOca: isOca,
            hasOcaAndNotGeneratedTag: shouldGenerateOcaTag(),
          });
        },
        icon: PrinterIcon,
        appearance: 'primary',
        name: 'printLabel',
      });
    }
  }
  return printLabelAction;
};

export default useBuildPrintLabelAction;
