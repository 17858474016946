import { Icon, Tag, Text } from '@nimbus-ds/components';
import { EditIcon, ExclamationTriangleIcon, TrashIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import {
  ActionIconButton,
  MethodNameAndImage,
  Stack,
} from 'commons/components';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ActiveShippingMethodsListSkeleton from './ActiveShippingMethodsListSkeleton';

export interface ActiveShippingMethodsInterface {
  code: string;
  logo: string;
  name: string;
  id: string;
  isNative: boolean;
  tags: string[];
  enableForStoreFeaturePlan: boolean;
  isMultiCDCompatible: boolean;
}

interface ActiveShippingMethodsListProps {
  activeShippingMethods: ActiveShippingMethodsInterface[];
  onEdit: (method: ActiveShippingMethodsInterface) => void;
  onRemove: (
    method: Pick<ActiveShippingMethodsInterface, 'id' | 'isNative'>,
  ) => void;
}

function ActiveShippingMethodsList({
  activeShippingMethods,
  onEdit,
  onRemove,
}: ActiveShippingMethodsListProps) {
  const t = useTranslationShipping();
  const { locations } = useLocations();

  return (
    <DataList bottomDivider={false}>
      {activeShippingMethods
        .filter((method) => method.enableForStoreFeaturePlan)
        .map((method) => (
          <DataList.Row id={method.id} key={method.id}>
            <Stack justify="space-between">
              <Stack column align="stretch" spacing="tight">
                <MethodNameAndImage name={method.name} image={method.logo} />
                <Stack wrap spacing="tight">
                  {method.tags.map((tag) => (
                    <Tag key={tag}>
                      <Text>{tag}</Text>
                    </Tag>
                  ))}
                </Stack>
              </Stack>
              <Stack spacing="tight" justify="flex-end">
                {!method.code.includes('mercado-envios') && (
                  <ActionIconButton
                    content={t(
                      'deliveryMethods.activeShippingMethods.editButton',
                    )}
                    source={<EditIcon />}
                    onClick={() => onEdit(method)}
                  />
                )}
                {!method.code.includes('nuvem-envio') && (
                  <ActionIconButton
                    content={t(
                      'deliveryMethods.activeShippingMethods.deleteButton',
                    )}
                    source={<TrashIcon />}
                    onClick={() =>
                      onRemove({ id: method.id, isNative: method.isNative })
                    }
                  />
                )}
              </Stack>
            </Stack>
            {!method.isMultiCDCompatible && locations.length > 1 && (
              <Tag appearance="warning">
                <Icon source={<ExclamationTriangleIcon size={12} />} />
                <Text fontSize="caption" color="warning-textLow" lineClamp={1}>
                  {t(
                    'deliveryMethods.activeShippingMethods.isMultiCDIncompatibleAlert',
                  )}
                </Text>
              </Tag>
            )}
          </DataList.Row>
        ))}
    </DataList>
  );
}

ActiveShippingMethodsList.Skeleton = ActiveShippingMethodsListSkeleton;

export default ActiveShippingMethodsList;
