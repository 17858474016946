import { useEffect, useState } from 'react';
import { AiFeature } from '@tiendanube/common/src/enums';
import { AiFeedbackModal } from 'commons/components';
import { useToast } from 'commons/hooks';
import useAiFeedbackFlow from 'commons/hooks/useAiFeedbackFlow';
import useChangeImageBackground from 'domains/Ai/hooks/useChangeImageBackground';
import {
  trackingAiEditImageReplaceBgWithColorUseClick,
  trackingAiEditImageReplaceBgWithColorPickClick,
} from 'domains/Ai/tracking';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ColorPicker } from './components';
import { ImageGalleryState } from '../../../../../ImageGallery';
import EditPhotoBaseModal from '../../../EditImageBaseModal';
import { EditedImage } from '../../../EditImageModal';
import BackgroudRemoveUncertaintyAlert from '../Alerts/BackgroudRemoveUncertaintyAlert';

interface ChangeBackgroundColorModalProps {
  currentImage: ImageGalleryState;
  onSaveImage: (image: EditedImage) => void;
  currentBackgroudColor?: string;
  isShow?: boolean;
  onClose?: () => void;
}

function ChangeBackgroundColorModal({
  currentImage,
  onSaveImage,
  currentBackgroudColor,
  isShow = false,
  onClose,
}: Readonly<ChangeBackgroundColorModalProps>) {
  const { addToast } = useToast();
  const t = useTranslationCatalog('products');

  const {
    errorOnBackgroundChange,
    unSavedChanges,
    displayedImage,
    handleBackgroundColorSelected,
    changingBackground,
    editedImageUncertain,
  } = useChangeImageBackground(currentImage);

  const [colorHasBeenSelected, setColorHasBeenSelected] = useState(false);

  const [currentColor, setCurrentColor] = useState<string | undefined>(
    currentBackgroudColor,
  );

  const handleNewColorIsSelected = (color: string) => {
    setColorHasBeenSelected(true);
    setCurrentColor(color);
    handleBackgroundColorSelected(color);
    trackingAiEditImageReplaceBgWithColorPickClick({ color });
  };

  const handleOnSubmit = () => {
    if (unSavedChanges && displayedImage.base64) {
      onSaveImage({ base64: displayedImage.base64 });
      if (currentColor) {
        trackingAiEditImageReplaceBgWithColorUseClick({ color: currentColor });
      }
    }
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (errorOnBackgroundChange) {
      addToast({
        appearance: 'danger',
        label: t('modal.editPhoto.toasts.error.label'),
      });
    }
  }, [errorOnBackgroundChange, addToast, t]);

  const handleOnClose = () => {
    if (onClose) {
      setColorHasBeenSelected(false);
      onClose();
    }
  };

  const handleOnCloseWithFeedback = () => {
    if (colorHasBeenSelected) {
      openAiGenerationFeedbackModal();
    } else {
      handleOnClose();
    }
  };

  const [
    showAiGenerationFeedbackModal,
    openAiGenerationFeedbackModal,
    closeAiGenerationFeedbackModal,
  ] = useAiFeedbackFlow(AiFeature.AI_IMAGES_REPLACE_BG_COLOR, handleOnClose);

  return (
    <>
      <EditPhotoBaseModal
        isShow={isShow}
        loadingImage={changingBackground}
        displayImageSource={displayedImage}
        onClose={handleOnCloseWithFeedback}
        translationPrefix="products.modal.editPhoto.actions.changeBackgroundColor.modal"
        beforeImageChildren={
          editedImageUncertain ? <BackgroudRemoveUncertaintyAlert /> : undefined
        }
        afterImageChildren={
          <ColorPicker
            initialSelectedColor={currentColor}
            onSelect={handleNewColorIsSelected}
          />
        }
        handleOnSave={handleOnSubmit}
        enableSaveButton={
          unSavedChanges && !changingBackground && !errorOnBackgroundChange
        }
      />
      <AiFeedbackModal
        asAside
        show={showAiGenerationFeedbackModal}
        title={t(
          'modal.editPhoto.actions.changeBackgroundColor.modal.feedback.title',
        )}
        text={t(
          'modal.editPhoto.actions.changeBackgroundColor.modal.feedback.text',
        )}
        feature={AiFeature.AI_IMAGES_REPLACE_BG_COLOR}
        feedbackParams={{
          imageEditing: {
            oldImageUrl: currentImage.publicUrl!,
            newImageBase64: displayedImage.base64!,
          },
        }}
        onDismiss={() => {
          closeAiGenerationFeedbackModal();
          handleOnClose();
        }}
      />
    </>
  );
}

export default ChangeBackgroundColorModal;
