import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { ProgressiveDiscount } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { mergeStatusType, toStatusType } from 'App/rtk';
import { ErrorScreen } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { promotionDefault } from './constants';
import { ScopeValuesInterface } from './types';
import { validationSchema } from './validationSchema';
import { PromotionPage, PromotionsDetailsSkeletons } from '../components';
import { PromotionForm } from '../components/PromotionForm';
import {
  useGetPromotionQuery,
  usePromotionTypesQuery,
  useUpdatePromotionMutation,
} from '../promotionsApi';

export function EditPromotionPage() {
  const t = useTranslationMarketing('promotions.settingsPage.edit');
  const { id } = useParams<{ id: string }>();
  const {
    data,
    status: statusLoadPromotion,
    refetch: refetchPromotions,
  } = useGetPromotionQuery(id);
  const { goBack } = useNavegate();

  const [isExternal, setIsExternal] = useState(false);

  const [updatePromotion, { status: statusUpdate }] =
    useUpdatePromotionMutation();

  const { status: statusLoadType, refetch: refetchTypes } =
    usePromotionTypesQuery();

  const initialValues = data || promotionDefault;

  const {
    values,
    errors,
    isDirty,
    handleChange,
    setFieldValue,
    handleChangeSelect,
    handleOnSubmit,
  } = useForm({
    initialValues,
    validationSchema,
    onSubmit: (data) => {
      updatePromotion({
        id,
        payload: data,
      });
    },
  });

  const handleChangeCategories = (scopeValues: ScopeValuesInterface[]) => {
    setFieldValue('categories', scopeValues);
  };

  const handleChangeProducts = (scopeValues: ScopeValuesInterface[]) => {
    setFieldValue('products', scopeValues);
  };

  const handleChangeProgressiveDiscounts = (
    progressiveDiscounts: ProgressiveDiscount[],
  ) => {
    setFieldValue('progressiveDiscounts', progressiveDiscounts);
  };

  const handleChangeIsExternal = useCallback((value: boolean) => {
    setIsExternal(value);
  }, []);

  const saveStatus = toStatusType(statusUpdate);
  const loadPomotionStatus = toStatusType(statusLoadPromotion);
  const loadTypeStatus = toStatusType(statusLoadType);
  const loadStatus = mergeStatusType([loadPomotionStatus, loadTypeStatus]);

  const refetch = () => {
    if (loadPomotionStatus === 'error') refetchPromotions();
    if (loadTypeStatus === 'error') refetchTypes();
  };

  useToastStatus({
    error: t('error'),
    success: t('success'),
    status: saveStatus,
    onSuccess: goBack,
  });

  return (
    <PromotionPage
      mode="edit"
      isDirty={isDirty}
      isExternal={isExternal}
      saveStatus={saveStatus}
      loadStatus={loadStatus}
      onSave={handleOnSubmit}
    >
      {loadStatus === 'error' && <ErrorScreen onRetry={refetch} />}
      {loadStatus === 'loading' && <PromotionsDetailsSkeletons />}
      {loadStatus === 'success' && (
        <PromotionForm
          mode="edit"
          values={values}
          errors={errors}
          isExternal={isExternal}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          onChangeSelect={handleChangeSelect}
          onChangeCategories={handleChangeCategories}
          onChangeProducts={handleChangeProducts}
          onChangeIsExternal={handleChangeIsExternal}
          onChangeProgressiveDiscounts={handleChangeProgressiveDiscounts}
        />
      )}
    </PromotionPage>
  );
}
