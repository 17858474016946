import { useCallback, useEffect, useState } from 'react';
import { Alert, Title, Text } from '@nimbus-ds/components';
import { AiFeature, AiTone, AiWordCount } from '@tiendanube/common/src/enums';
import ModalAside from 'App/components/lab/ModalAside';
import { AiFeedbackModal, HeaderTop, Stack } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import useAiFeedbackFlow from 'commons/hooks/useAiFeedbackFlow';
import useRegenerateAiContent from 'commons/hooks/useRegenerateAiContent';
import {
  trackingAiGenerateCancelClick,
  trackingAiGenerateGenerateClick,
  trackingAiGenerateRegenerateClick,
  trackingAiGenerateUseContentClick,
} from 'domains/Ai/tracking';
import {
  AiGenerationInstructions,
  AiGenerationResults,
  CheckAIErrorsDisclaimer,
  EditInstructionsCard,
  GeneratingContentAnimation,
  HtmlPreview,
  RegenerateInstructions,
} from './components';

export interface WordCountValues {
  short: number;
  medium: number;
  large: number;
}

export interface GenerateContentParams {
  tone?: AiTone;
  wordCount?: AiWordCount;
}

interface AiGenerationResponseDto {
  run_id: string;
  session_id?: string;
}

export interface AiGenerationResultsModalContentParams {
  generating: boolean;
  hasGeneratedContent: boolean;
}

export interface AiGenerationModalProps<
  ResponseDTO extends AiGenerationResponseDto,
> {
  show?: boolean;
  feature: AiFeature;
  insufficientInfo?: boolean;
  insufficientInfoError?: string;
  generateOnOpen?: boolean;
  showCtas?: boolean;
  disabledGenerateCta?: boolean;
  feedbackTitle: string;
  feedbackText: string;
  feedbackAsAside?: boolean;
  showTone?: boolean;
  wordCountValues?: WordCountValues;
  onDismiss: () => void;
  onGenerateContent: (args: GenerateContentParams) => Promise<ResponseDTO>;
  previewGeneratedContent: (generatedContent: ResponseDTO) => React.ReactNode;
  onUseContent: (generatedContent: ResponseDTO) => void;
  extraControls?: React.ReactNode;
}

function AiGenerationModal<ResponseDTO extends AiGenerationResponseDto>({
  show = false,
  feature,
  insufficientInfo = false,
  insufficientInfoError,
  generateOnOpen = false,
  showCtas = true,
  disabledGenerateCta = false,
  feedbackTitle,
  feedbackText,
  feedbackAsAside = false,
  showTone = false,
  wordCountValues,
  onDismiss,
  onGenerateContent,
  previewGeneratedContent,
  onUseContent,
  extraControls,
}: Readonly<AiGenerationModalProps<ResponseDTO>>) {
  const t = useTranslationCommon();
  const { regenerateContent } = useRegenerateAiContent();

  const [tone, setTone] = useState<AiTone | undefined>();
  const [wordCount, setWordCount] = useState<AiWordCount | undefined>();
  const [showError, setShowError] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [generatedContentList, setGeneratedContentList] = useState<
    ResponseDTO[]
  >([]);
  const [generatedContentIndex, setGeneratedContentIndex] = useState(0);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const [regenerationInstructions, setRegenerationInstructions] = useState('');

  const wordNumber =
    wordCountValues && wordCount ? wordCountValues[wordCount] : undefined;

  const [
    showAiGenerationFeedbackModal,
    openAiGenerationFeedbackModal,
    closeAiGenerationFeedbackModal,
  ] = useAiFeedbackFlow(feature);

  const hasGeneratedContent = generatedContentList.length > 0;
  const generatedContent = hasGeneratedContent
    ? generatedContentList[generatedContentIndex]
    : undefined;

  const run_id = hasGeneratedContent
    ? generatedContentList[generatedContentList.length - 1].run_id
    : undefined;

  const handleGenerateContent = useCallback(async () => {
    setGenerating(true);
    setShowError(false);

    if (!generateOnOpen) {
      trackingAiGenerateGenerateClick({ feature });
    }

    try {
      const newContent = await onGenerateContent({
        tone,
        wordCount,
      });

      setGeneratedContentList([newContent]);
      setGeneratedContentIndex(0);
      setSessionId(newContent.session_id);
    } catch (error) {
      setShowError(true);
    } finally {
      setGenerating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tone,
    wordCount,
    onGenerateContent,
    setGeneratedContentList,
    setGeneratedContentIndex,
    setGenerating,
    setSessionId,
    setShowError,
  ]);

  const handleRegenerateContent = useCallback(async () => {
    setGenerating(true);
    setShowError(false);

    const regenerateAttempt = generatedContentList.length + 1;
    trackingAiGenerateRegenerateClick(
      { feature },
      regenerateAttempt,
      !regenerationInstructions,
    );

    try {
      const newContent = await regenerateContent({
        feature,
        session_id: sessionId!,
        merchant_feedback: regenerationInstructions,
      });

      setGeneratedContentIndex(generatedContentList.length);
      setGeneratedContentList([
        ...generatedContentList,
        newContent as ResponseDTO,
      ]);
      setRegenerationInstructions('');
    } catch (error) {
      setShowError(true);
    } finally {
      setGenerating(false);
    }
  }, [
    feature,
    sessionId,
    regenerationInstructions,
    generatedContentList,
    regenerateContent,
    setGenerating,
    setShowError,
  ]);

  useEffect(() => {
    if (
      generateOnOpen &&
      show &&
      !generating &&
      !hasGeneratedContent &&
      !showError &&
      !insufficientInfo
    ) {
      trackingAiGenerateGenerateClick({ feature });
      handleGenerateContent();
    }
  }, [
    feature,
    generateOnOpen,
    show,
    generating,
    hasGeneratedContent,
    showError,
    insufficientInfo,
    handleGenerateContent,
  ]);

  const handleUseContentClick = () => {
    if (!generatedContent) {
      return;
    }

    trackingAiGenerateUseContentClick({ feature });

    onUseContent(generatedContent);

    setSessionId(undefined);
    setTone(undefined);
    setWordCount(undefined);
    setShowError(false);
    setGenerating(false);
    setGeneratedContentList([]);
    setGeneratedContentIndex(0);
    setRegenerationInstructions('');

    onDismiss();
  };

  const handleCloseModalWithoutUsingGeneratedContent = useCallback(() => {
    trackingAiGenerateCancelClick({ feature });
    onDismiss();
    if (hasGeneratedContent) {
      openAiGenerationFeedbackModal();
    }
  }, [feature, onDismiss, hasGeneratedContent, openAiGenerationFeedbackModal]);

  const handleEditInstructionsClick = () => {
    setSessionId(undefined);
    setShowError(false);
    setGenerating(false);
    setGeneratedContentList([]);
    setGeneratedContentIndex(0);
    setRegenerationInstructions('');
  };

  const handlePreviousGeneratedContentClick = () => {
    setGeneratedContentIndex(generatedContentIndex - 1);
  };

  const handleNextGeneratedContentClick = () => {
    setGeneratedContentIndex(generatedContentIndex + 1);
  };

  return (
    <>
      <ModalAside
        isOpen={show}
        onDidDismiss={handleCloseModalWithoutUsingGeneratedContent}
        headerTop={
          <HeaderTop
            navigation={{
              onClick: handleCloseModalWithoutUsingGeneratedContent,
            }}
          />
        }
        headerContent={
          !generating && (
            <Stack column align="stretch" gap="1">
              <Title as="h2">
                {t(
                  `aiGeneration.resultsModal.${
                    hasGeneratedContent
                      ? 'titleAfterContent'
                      : 'titleBeforeContent'
                  }`,
                )}
              </Title>
              {!hasGeneratedContent && (
                <Text>
                  {t('aiGeneration.resultsModal.subtitleBeforeContent')}
                </Text>
              )}
            </Stack>
          )
        }
      >
        <Stack column align="stretch" spacing="tight">
          {insufficientInfo ? (
            <Alert appearance="warning">{insufficientInfoError}</Alert>
          ) : generating ? (
            <GeneratingContentAnimation />
          ) : (
            <>
              {showError && (
                <Alert appearance="warning">
                  {t('aiGeneration.resultsModal.requestFailedError')}
                </Alert>
              )}

              {!hasGeneratedContent ? (
                <AiGenerationInstructions
                  feature={feature}
                  tone={tone}
                  setTone={setTone}
                  wordCount={wordCount}
                  wordNumber={wordNumber}
                  setWordCount={setWordCount}
                  showTone={showTone}
                  showWordCount={!!wordCountValues}
                  extraControls={extraControls}
                  showCtas={showCtas}
                  disabledGenerateCta={disabledGenerateCta}
                  onGenerateContent={handleGenerateContent}
                  onCancel={onDismiss}
                />
              ) : (
                <>
                  {!!extraControls && (
                    <EditInstructionsCard
                      tone={tone}
                      wordNumber={wordNumber}
                      onClick={handleEditInstructionsClick}
                    />
                  )}

                  {generatedContent && (
                    <AiGenerationResults
                      feature={feature}
                      current={generatedContentIndex + 1}
                      total={generatedContentList.length}
                      onPrevious={handlePreviousGeneratedContentClick}
                      onNext={handleNextGeneratedContentClick}
                      onUseContent={handleUseContentClick}
                    >
                      {previewGeneratedContent(generatedContent)}
                    </AiGenerationResults>
                  )}

                  <RegenerateInstructions
                    feature={feature}
                    regenerationInstructions={regenerationInstructions}
                    onChangeRegenerationInstructions={
                      setRegenerationInstructions
                    }
                    onRegenerateContent={handleRegenerateContent}
                  />

                  <CheckAIErrorsDisclaimer />
                </>
              )}
            </>
          )}
        </Stack>
      </ModalAside>
      <AiFeedbackModal
        show={showAiGenerationFeedbackModal}
        title={feedbackTitle}
        text={feedbackText}
        feedbackParams={{ run_id }}
        asAside={feedbackAsAside}
        feature={feature}
        onDismiss={closeAiGenerationFeedbackModal}
      />
    </>
  );
}

AiGenerationModal.HtmlPreview = HtmlPreview;

export default AiGenerationModal;
