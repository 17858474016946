import { useCallback, useEffect, useMemo, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  EditOrderProductRequestDto,
  EditOrderRequestDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useOrderDetails } from 'domains/Orders/Orders/hooks';
import {
  cleanEditOrder,
  editOrderById,
  getEditOrderStatus,
} from 'domains/Orders/Orders/ordersSlice';
import { useShippingCosts } from './useShippingCosts';
import { OrderEditProduct } from '../components/Products';

export function useEditOrder(id: string) {
  const { orderDetails, isError, isLoading } = useOrderDetails(id);
  const [isOrderEdited, setIsOrderEdited] = useState(false);
  const [editedOrderProducts, setEditedOrderProducts] = useState<
    OrderEditProduct[]
  >(orderDetails?.products ?? []);
  const [editReason, setEditReason] = useState<string | undefined>(undefined);
  const [notifyCustomer, setNotifyCustomer] = useState<boolean>(false);
  const { isLoading: isLoadingEdit, status: editStatus } =
    useSelector(getEditOrderStatus);
  const dispatch = useAppDispatch();
  const { isErrorAny: isShippingCostError } = useShippingCosts(undefined);

  const editOrder = useCallback(
    async (
      updatedOrderProducts: OrderEditProduct[],
      reason?: string,
      sendNotification?: boolean,
    ) => {
      if (!orderDetails) return;

      const toEditOrderRequestProduct = (p: OrderEditProduct) => {
        if (p.editType === 'add') {
          return {
            productId: p.productId,
            variantId: p.variantId,
            quantity: p.quantity,
            type: 'add',
            fulfillmentOrderId: p.fulfillmentOrderId,
          } as EditOrderProductRequestDto;
        }

        const originalProducts =
          orderDetails.fulfillmentOrders?.find(
            (ffoo) => ffoo.id === p.fulfillmentOrderId,
          )?.products ?? orderDetails.products;

        const orderProduct = originalProducts.find(
          (op) => op.variantId === p.variantId,
        );
        if (!orderProduct) {
          // Should never happen
          throw new Error(
            `Product with variantId ${p.variantId} not found for order ${id} when editing the order`,
          );
        }
        const quantityDiff = p.quantity - orderProduct.quantity;

        return {
          productId: p.productId,
          variantId: p.variantId,
          quantity: p.editType !== 'edit' ? p.quantity : Math.abs(quantityDiff),
          type: quantityDiff > 0 ? 'add' : 'remove',
          restoreStock: p.restoreStock,
          fulfillmentOrderId: p.fulfillmentOrderId,
        } as EditOrderProductRequestDto;
      };

      const editRequest: EditOrderRequestDto = {
        products: updatedOrderProducts
          .filter((p) => p.editType !== undefined)
          .map(toEditOrderRequestProduct),
        reason,
        notifyCustomer: sendNotification,
      };
      unwrapResult(await dispatch(editOrderById({ id, editRequest })));
      dispatch(cleanEditOrder());
    },
    [id, orderDetails, dispatch],
  );

  const cleanEditState = useCallback(() => {
    dispatch(cleanEditOrder());
  }, [dispatch]);

  const nonDeletedEditedProducts = useMemo(
    () => editedOrderProducts.filter((p) => p.editType !== 'delete'),
    [editedOrderProducts],
  );

  const noDeletedFulfillments = useMemo(() => {
    if (
      !orderDetails?.fulfillmentOrders ||
      orderDetails.fulfillmentOrders.length === 0
    ) {
      const editedProductsCount = nonDeletedEditedProducts.reduce(
        (acc, p) => acc + p.quantity,
        0,
      );
      return editedProductsCount > 0;
    }

    const editedFFOO = nonDeletedEditedProducts
      .map((product) => product.fulfillmentOrderId)
      .filter((ffoId, idx, ffoIds) => ffoIds.indexOf(ffoId) === idx) // Unique FFO ids
      .filter(Boolean); // Filters out 'undefined' values, but should never happen

    return editedFFOO.length === orderDetails.fulfillmentOrders.length;
  }, [orderDetails?.fulfillmentOrders, nonDeletedEditedProducts]);

  const isValidEdition = useMemo(
    () =>
      !nonDeletedEditedProducts.some(
        (p) => p.maxStock && p.quantity > p.maxStock,
      ) &&
      noDeletedFulfillments &&
      !isShippingCostError,
    [nonDeletedEditedProducts, noDeletedFulfillments, isShippingCostError],
  );

  const editedProductsWithFulfillments: OrderEditProduct[] = useMemo(() => {
    if (!orderDetails?.fulfillmentOrders) {
      return (
        orderDetails?.products.map((p) => ({
          ...p,
          originalQuantity: p.quantity,
        })) ?? []
      );
    } else {
      const ffoProducts = orderDetails.fulfillmentOrders.flatMap((ffo) =>
        ffo.products.map(
          (product) =>
            ({
              ...product,
              originalQuantity: product.quantity,
              fulfillmentOrderId: ffo.id,
              locationId: ffo.assignedLocation.id,
            } as OrderEditProduct),
        ),
      );
      return ffoProducts;
    }
  }, [orderDetails]);

  useEffect(() => {
    setEditedOrderProducts(editedProductsWithFulfillments);

    return () => {
      cleanEditState();
    };
  }, [
    orderDetails,
    setEditedOrderProducts,
    editedProductsWithFulfillments,
    cleanEditState,
  ]);

  return {
    isLoading: isLoading || (!editedOrderProducts.length && !isError),
    isError,
    isLoadingEdit,
    editStatus,
    editedOrderProducts,
    setEditedOrderProducts,
    orderDetails,
    isOrderEdited,
    setIsOrderEdited,
    editReason,
    setEditReason,
    editOrder,
    cleanEditState,
    nonDeletedEditedProducts,
    isValidEdition,
    notifyCustomer,
    setNotifyCustomer,
  };
}
