import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Icon, Label, Tag } from '@nimbus-ds/components';
import { LocationIcon } from '@nimbus-ds/icons';
import {
  FulfillmentOrderAssignedLocationDto,
  ProductsSortByEnum,
} from '@tiendanube/common';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderContent } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import { ProductSearcherConfirm } from './ProductSearcherConfirm';
import { ProductSearcherContent } from './ProductSearcherContent';
import { PreSelectedProductInterface, ProductInterface } from './types';
import './ProductSearcher.scss';

interface ProductSearcherProps<Minimalistic extends boolean> {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (selectedProducts: ProductInterface<Minimalistic>[]) => void;
  preselectedProducts?: PreSelectedProductInterface<Minimalistic>[];
  sortBy?: ProductsSortByEnum | null;
  loadInitial?: boolean;
  onlyPublishedProducts?: boolean;
  location?: FulfillmentOrderAssignedLocationDto;
  requiresShipping?: boolean;
  minimalistic?: boolean;
  selectedProductsLimit?: number;
}

function ProductSearcher<Minimalistic extends boolean>({
  isOpen,
  onClose,
  onSubmit,
  preselectedProducts = [],
  sortBy = null,
  minimalistic = false,
  onlyPublishedProducts = false,
  selectedProductsLimit,
  location,
  requiresShipping,
}: Readonly<ProductSearcherProps<Minimalistic>>) {
  const t = useTranslationOrders();

  // State to track selected products
  const [selectedProducts, setSelectedProducts] = useState<
    ProductInterface<Minimalistic>[]
  >([]);

  // When using the minimalistic mode, the preselected products are used as selected ones instead
  // to enable deselection. Not the prettiest, I know.
  const _preselectedProducts = useMemo(
    () => (minimalistic ? [] : preselectedProducts),
    [minimalistic, preselectedProducts],
  );

  // Handle product selection and deselection
  const handleProductSelect = useCallback(
    (products: ProductInterface<Minimalistic>[]) => {
      setSelectedProducts(products);
    },
    [],
  );

  const handleProductsSubmit = () => {
    onSubmit(selectedProducts);
    setSelectedProducts([]);
  };

  const handleClose = () => {
    onClose();
    setSelectedProducts([]);
  };

  useEffect(() => {
    if (minimalistic && isOpen) {
      setSelectedProducts(
        preselectedProducts as ProductInterface<Minimalistic>[],
      );
    }
  }, [minimalistic, setSelectedProducts, preselectedProducts, isOpen]);

  return (
    <ModalAside
      hasVirtualizationContent
      isOpen={isOpen}
      onDidDismiss={handleClose}
      headerContent={
        <>
          <HeaderContent
            title={
              minimalistic
                ? t('productSearcher.titleSelectProducts')
                : t('productSearcher.titleAddProducts')
            }
          />
          {location && (
            <Box display="flex" marginTop="4">
              <Tag appearance="primary">
                <Icon source={<LocationIcon />} />
                {location.name}
              </Tag>
              {!requiresShipping && (
                <Box marginLeft="2">
                  <Tag appearance="primary">
                    {t('productSearcher.digitalProducts')}
                  </Tag>
                </Box>
              )}
            </Box>
          )}
          {minimalistic && (
            <Box display="flex" marginTop="4">
              <Label>
                {t('productSearcher.subtitleSelectedProductsLimitAlert')}
              </Label>
            </Box>
          )}
        </>
      }
      footer={
        !!selectedProducts.length && (
          <ProductSearcherConfirm
            selectedProducts={selectedProducts}
            minimalistic={minimalistic}
            onCancel={handleClose}
            onConfirm={handleProductsSubmit}
          />
        )
      }
      footerHeight="70px"
    >
      <ProductSearcherContent
        isOpen={isOpen}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        preselectedProducts={_preselectedProducts}
        sortBy={sortBy}
        onlyPublishedProducts={onlyPublishedProducts}
        selectedProductsLimit={selectedProductsLimit}
        location={location}
        requiresShipping={requiresShipping}
        onProductSelect={handleProductSelect}
        minimalistic={minimalistic}
      />
    </ModalAside>
  );
}

export default ProductSearcher;
