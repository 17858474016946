import { Tag, Text } from '@nimbus-ds/components';
import { v4 as uuidv4 } from 'uuid';
import { ShowMoreContent } from 'commons/components';

interface ShowMoreCategoriesProps {
  onClick?: () => void;
  categories: string[];
}

const uniqueId = uuidv4();

export function ShowMoreCategories({
  categories,
  onClick,
}: Readonly<ShowMoreCategoriesProps>) {
  return (
    <ShowMoreContent onClick={onClick}>
      {categories.map((category) => (
        <Tag key={`${uniqueId}-${category}`}>
          <Text lineClamp={1}>{category}</Text>
        </Tag>
      ))}
    </ShowMoreContent>
  );
}
