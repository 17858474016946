import { useState } from 'react';
import { Box, IconButton, Popover, Tooltip } from '@nimbus-ds/components';
import { QuestionCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { ZENDESK_LIVE_CHAT_WIDGET_TAG } from 'App/featuresFlags';
import { useModal } from 'commons/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import { SupportCard } from './components';
import SunshineConversations from '../ExternalServices/SunshineConversations';
import {
  getTotalNoRead,
  openModal as SmoochOpenModal,
  statusIsOk as SmoochStatusIsOk,
} from '../ExternalServices/SunshineConversations/startSmooch';
import './supportChanels.scss';
import WebWidgetLiveChat, {
  openWidget as WebWidgetOpen,
  verifyStatus as WebWidgetStatusIsOk,
} from '../ExternalServices/WebWidgetLiveChat';
import useTopBar from '../Topbar/useTopBar';

export function SupportChanels() {
  const { t } = useTranslation('common');
  const [showChat, openChat, closeChat] = useModal();
  const {
    noReadMessagesCount,
    setNoReadMessagesCount,
    isHelpWidgetOpen,
    toggleHelpWidget,
  } = useTopBar();
  const [chatIsDestroyed, setChatIsDestroyed] = useState<boolean>(false);
  const tags = useGetTags();

  const getChatOpener = () => {
    if (tags.includes(ZENDESK_LIVE_CHAT_WIDGET_TAG)) {
      WebWidgetOpen();
    } else {
      SmoochOpenModal();
    }
  };

  const getChatStatus = () => {
    if (tags.includes(ZENDESK_LIVE_CHAT_WIDGET_TAG)) {
      return WebWidgetStatusIsOk();
    }
    return SmoochStatusIsOk();
  };

  const handleOpenChat = () => {
    if (!chatIsDestroyed && getChatStatus()) {
      openChat();
      toggleHelpWidget();
      getChatOpener();
    }
  };

  const handleChatDestroyed = () => {
    if (showChat) closeChat();

    setChatIsDestroyed(true);
  };

  const handleReceivedMessage = () => {
    setNoReadMessagesCount(getTotalNoRead());
  };

  const handleCloseChat = () => {
    closeChat();
    setNoReadMessagesCount(getTotalNoRead());
  };

  const getChatOnCondition = () => {
    if (tags.includes(ZENDESK_LIVE_CHAT_WIDGET_TAG)) {
      return <WebWidgetLiveChat />;
    }
    return (
      <SunshineConversations
        onCloseModal={handleCloseChat}
        onReady={handleReceivedMessage}
        onDestroy={handleChatDestroyed}
        onReceivedMessage={handleReceivedMessage}
      />
    );
  };

  return (
    <>
      <Popover
        content={
          <Box display="flex" flexDirection="column" width="100%">
            <SupportCard
              onClose={toggleHelpWidget}
              onOpenChat={handleOpenChat}
            />
          </Box>
        }
        padding="none"
        arrow={false}
        position="bottom-end"
        overflow="hidden"
        width="18rem"
        visible={isHelpWidgetOpen}
        onVisibility={toggleHelpWidget}
      >
        <Tooltip
          content={
            noReadMessagesCount > 0
              ? t('topbar.unreadMessages', {
                  unread: noReadMessagesCount,
                })
              : t('topbar.help')
          }
          arrow={false}
        >
          <Box position="relative">
            <IconButton
              size="2.125rem"
              source={<QuestionCircleIcon size={18} />}
              backgroundColor="transparent"
              borderColor={{
                xs: 'transparent',
                hover: 'neutral-interactiveHover',
              }}
              data-style="icon-button-override"
              as="div"
              id="support-chanel"
            />
            {noReadMessagesCount > 0 && (
              <Box
                position="absolute"
                top="4px"
                right="4px"
                backgroundColor="primary-interactive"
                borderRadius="full"
                width="6px"
                height="6px"
              />
            )}
          </Box>
        </Tooltip>
      </Popover>
      {getChatOnCondition()}
    </>
  );
}
