import { v4 as uuidv4 } from 'uuid';
import { CategoryNodeIdType, CategoryNodeType } from './types';

export const ID_ROOT = '0';

export const newEmptyNode = (parentId?: CategoryNodeIdType) => ({
  id: `new-${uuidv4()}`,
  parent: parentId || ID_ROOT,
  expanded: true,
  droppable: true,
  text: '',
});

export const decendents = (
  tree: CategoryNodeType[],
  id: CategoryNodeIdType,
) => {
  const children = tree.filter((current) => current.parent === id);
  const subChildren: CategoryNodeType[] = [];
  children.forEach((current) =>
    subChildren.push(...decendents(tree, current.id)),
  );
  return [...children, ...subChildren];
};

export const idDecendents = (
  tree: CategoryNodeType[],
  id: CategoryNodeIdType,
) => decendents(tree, id).map((current) => current.id);

export const hasSavedDecendents = (
  tree: CategoryNodeType[],
  id: CategoryNodeIdType,
) =>
  idDecendents(tree, id).some(
    (current) => !(current as string).startsWith('new-'),
  );

//for the validation of emojis that are not part of the Unicode BMP:
const emojiNotAllowedRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

export const hasEmojisAllowed = (value: string) =>
  !value.match(emojiNotAllowedRegex);

export const findEmojiNotAllowed = (value: CategoryNodeType[]) =>
  value.some((current) => !hasEmojisAllowed(current.text));

export const validateEmojis = (value: string | undefined) => {
  if (!value) return true;
  return hasEmojisAllowed(value);
};
