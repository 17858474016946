import { useMemo } from 'react';
import { useGetTags } from 'domains/Auth/hooks';

function useIsProductsFilterEnabled() {
  const tags = useGetTags();
  const hasProductsFilterTag = useMemo(
    () => tags.includes('new-admin-orders-products-filter'),
    [tags],
  );

  const isEnabled = useMemo(() => hasProductsFilterTag, [hasProductsFilterTag]);

  return isEnabled;
}

export default useIsProductsFilterEnabled;
