import {
  StoreDomainDto,
  CreateStoreDomainDto,
  DomainConfigDto,
  UpdateDomainStatusRequestDto,
  UpdateDomainStatusResponseDto,
  UpdateOurStoreDomainDto,
  DomainToUpdateDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  domainsEndpoints,
  domainsTags,
  BASE_DOMAINS_URL,
  DOMAIN_CONFIG_URL,
  DELETE_DOMAIN_URL,
  SET_PRIMARY_DOMAIN_URL,
  UPDATE_OUR_DOMAIN_URL,
  UPDATE_DOMAIN_STATUS_URL,
  GET_DOMAIN_TO_UPDATE_URL,
  SET_DOMAIN_TO_NOTIFY_URL,
} from './config';

export const domainsApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [domainsTags.domainsList],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [domainsEndpoints.getDomains]: builder.query<StoreDomainDto[], void>({
        query: () => ({
          method: 'GET',
          url: BASE_DOMAINS_URL,
        }),
        providesTags: [domainsTags.domainsList],
      }),
      [domainsEndpoints.createDomain]: builder.mutation<
        StoreDomainDto[],
        CreateStoreDomainDto
      >({
        query: (newDomain) => ({
          method: 'POST',
          url: BASE_DOMAINS_URL,
          data: newDomain,
        }),
        invalidatesTags: (_, error) => (error ? [] : [domainsTags.domainsList]),
      }),
      [domainsEndpoints.deleteDomain]: builder.mutation<void, string>({
        query: (id) => ({
          method: 'DELETE',
          url: getUrlWithParams(DELETE_DOMAIN_URL, { id }),
        }),
        invalidatesTags: (_, error) => (error ? [] : [domainsTags.domainsList]),
      }),
      [domainsEndpoints.setPrimaryDomain]: builder.mutation<
        StoreDomainDto,
        string
      >({
        query: (id) => ({
          method: 'POST',
          url: getUrlWithParams(SET_PRIMARY_DOMAIN_URL, { id }),
        }),
        invalidatesTags: (_, error) => (error ? [] : [domainsTags.domainsList]),
      }),
      [domainsEndpoints.updateOurDomain]: builder.mutation<
        { success: boolean; new_url: string; redirectUrl: string },
        UpdateOurStoreDomainDto
      >({
        query: (updatedDomain) => ({
          method: 'PUT',
          url: UPDATE_OUR_DOMAIN_URL,
          data: updatedDomain,
        }),
        invalidatesTags: (_, error) => (error ? [] : [domainsTags.domainsList]),
      }),
      [domainsEndpoints.getDomainConfig]: builder.query<
        DomainConfigDto,
        string
      >({
        query: (id) => ({
          method: 'GET',
          url: getUrlWithParams(DOMAIN_CONFIG_URL, { id }),
        }),
      }),
      [domainsEndpoints.updateDomainStatus]: builder.mutation<
        UpdateDomainStatusResponseDto[],
        UpdateDomainStatusRequestDto
      >({
        query: (payload) => ({
          method: 'PATCH',
          url: UPDATE_DOMAIN_STATUS_URL,
          data: payload,
        }),
        invalidatesTags: (_, error) => (error ? [domainsTags.domainsList] : []),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          dispatch(
            cirrusApi.util.updateQueryData(
              domainsEndpoints.getDomains as never,
              undefined as never,
              (domains: StoreDomainDto[]) =>
                domains.map((domain) => {
                  const updatedStatus = data.find(
                    (updated) => updated.id === domain.id,
                  );
                  if (updatedStatus)
                    return {
                      ...domain,
                      status: {
                        domain: updatedStatus.domain,
                        ssl: updatedStatus.ssl,
                      },
                    };
                  return domain;
                }),
            ),
          );
        },
      }),
      [domainsEndpoints.getDomainSoftBlock]: builder.query<
        DomainToUpdateDto | null,
        void
      >({
        query: () => ({
          method: 'GET',
          url: GET_DOMAIN_TO_UPDATE_URL,
        }),
        providesTags: [domainsTags.domainsList],
      }),
      [domainsEndpoints.setDomainToNotify]: builder.mutation<void, void>({
        query: () => ({
          method: 'POST',
          url: SET_DOMAIN_TO_NOTIFY_URL,
        }),
      }),
    }),
  });

export const {
  useGetDomainsQuery,
  useCreateDomainMutation,
  useDeleteDomainMutation,
  useSetPrimaryDomainMutation,
  useUpdateOurDomainMutation,
  useGetDomainConfigQuery,
  useUpdateDomainStatusMutation,
  useGetDomainSoftBlockQuery,
  useLazyGetDomainSoftBlockQuery,
  useSetDomainToNotifyMutation,
} = domainsApi;
