import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  fetchTotalAllOrders,
  getTotalResultsAllOrders,
} from '../../ordersSlice';

function useTotalOrders() {
  const dispatch = useAppDispatch();
  const { validateRoles } = useRoles();
  const hasOrderRole = validateRoles('orders');

  const totalAllOrders = useSelector(getTotalResultsAllOrders);

  const getTotalAllOrders = useCallback(() => {
    if (hasOrderRole) dispatch(fetchTotalAllOrders());
  }, [dispatch, hasOrderRole]);

  const hasOrder = totalAllOrders > 0;

  useEffect(() => {
    if (!hasOrder) getTotalAllOrders();
  }, [getTotalAllOrders, hasOrder]);

  return {
    hasOrder,
  };
}

export default useTotalOrders;
