import { Box, Chip, Text, Alert } from '@nimbus-ds/components';
import { SelectOrEditLink } from 'commons/components';
import { useModal } from 'commons/hooks';
import {
  SelectProductList,
  SelectedProductInterface,
} from 'domains/Catalog/Products/components';
import { getProductsMaped, getSelectedProductsMaped } from './utils';

export interface ProductsInterface {
  id: string;
  description: string;
}

interface ProductsProps {
  products: ProductsInterface[];
  title: string;
  subtitle: string;
  requiredText: string;
  emptyTitle: string;
  emptyText: string;
  showTitle?: boolean;
  onChange: (categories: ProductsInterface[]) => void;
  selectLinkText?: string;
  editLinkText?: string;
  disabled?: boolean;
}

export function Products({
  products,
  title,
  subtitle,
  requiredText,
  emptyTitle,
  emptyText,
  showTitle = true,
  onChange,
  selectLinkText,
  editLinkText,
  disabled = false,
}: Readonly<ProductsProps>): JSX.Element {
  const [isShowModal, openModal, closeModal] = useModal();
  const isEmpty = !products.length;

  const handleOnChange = (selecteds: SelectedProductInterface[]) => {
    onChange(getProductsMaped(selecteds));
  };

  return (
    <>
      {showTitle && <Text fontWeight="bold">{title}</Text>}
      {!isEmpty && (
        <Box display="flex" gap="2" flexWrap="wrap">
          {products.map(({ id, description }) => (
            <Chip
              key={id}
              text={description}
              removable={!disabled}
              onClick={() =>
                onChange(products.filter((current) => current.id !== id))
              }
            />
          ))}
        </Box>
      )}

      {!disabled && (
        <>
          <SelectOrEditLink
            isEdit={!isEmpty}
            onSelect={openModal}
            selectLinkText={selectLinkText}
            editLinkText={editLinkText}
          />
          {!!requiredText && <Alert appearance="danger">{requiredText}</Alert>}
          <SelectProductList
            isShow={isShowModal}
            title={title}
            subtitle={subtitle}
            emptyTitle={emptyTitle}
            emptyText={emptyText}
            addItemsToTop
            onClose={closeModal}
            selectedProducts={getSelectedProductsMaped(products)}
            onChange={handleOnChange}
          />
        </>
      )}
    </>
  );
}
