import { Spinner } from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import ModalAside from 'App/components/lab/ModalAside';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  Stack,
  useResponsive,
} from 'commons/components';
import { DrawableCanvasRef } from 'commons/hooks/useDrawableCanvas';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import './EditImageBaseModal.scss';
import DrawableImage from './components/DrawableImage';

const MODAL_ASIDE_BREAKPOINT = 540;
const IMAGE_MAX_HEIGHT = 400;
const IMAGE_MAX_WIDTH_DESKTOP = 474;
const IMAGE_MAX_WIDTH_MOBILE = 400;

// Base 64 is used to display edited images, otherwise the src is used
export interface DisplayImageSrc {
  base64?: string;
  src: string;
}
interface EditImageBaseModalProps {
  isShow: boolean;
  canvasRef?: DrawableCanvasRef;
  onCanvasDraw?: (isEmpty: boolean) => void;
  displayImageSource: DisplayImageSrc;
  translationPrefix: string;
  afterImageChildren: React.ReactNode;
  beforeImageChildren?: React.ReactNode;
  onHeaderActionClick?: () => void;
  onClose?: () => void;
  handleOnSave?: () => void;
  handleOnCancel?: () => void;
  enableSaveButton?: boolean;
  fullWidthChild?: boolean;
  loadingImage?: boolean;
}

function EditImageBaseModal({
  isShow,
  canvasRef,
  onCanvasDraw,
  displayImageSource,
  onClose,
  translationPrefix,
  afterImageChildren,
  beforeImageChildren,
  onHeaderActionClick,
  handleOnSave,
  handleOnCancel,
  enableSaveButton = false,
  fullWidthChild = false,
  loadingImage = false,
}: Readonly<EditImageBaseModalProps>) {
  const t = useTranslationCatalog(translationPrefix);
  const { isMobile } = useResponsive(MODAL_ASIDE_BREAKPOINT);

  const hasHeaderAction = !!onHeaderActionClick;
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };
  const onCancel = handleOnCancel || handleOnClose;
  const headerAction = hasHeaderAction
    ? {
        icon: CheckCircleIcon,
        children: t('confirm'),
        onClick: onHeaderActionClick,
      }
    : undefined;
  const imageSrc = displayImageSource?.base64 ?? displayImageSource.src;
  const onSave = () => {
    if (handleOnSave) {
      handleOnSave();
    }
  };

  return (
    <ModalAside
      hasVirtualizationContent
      fluid
      isOpen={isShow}
      onDidDismiss={handleOnClose}
      headerTop={
        <HeaderTop
          navigation={{ onClick: handleOnClose }}
          mainAction={headerAction}
        />
      }
      headerContent={
        <Stack column spacing="tight" align="flex-start">
          <HeaderContent title={t('title')} />
          {beforeImageChildren}
        </Stack>
      }
      extraHeaderGap
    >
      <Stack column align="stretch">
        <div className="stratus--edit-photo-base-modal-img_stack_item">
          <div
            className={
              'stratus--edit-photo-base-modal-img_container ' +
              (loadingImage ? 'stratus--edit-photo-base-modal-img-loading' : '')
            }
          >
            <Stack justify="center" gap="none">
              {canvasRef ? (
                <DrawableImage
                  canvasRef={canvasRef}
                  src={imageSrc}
                  maxWidth={
                    isMobile ? IMAGE_MAX_WIDTH_MOBILE : IMAGE_MAX_WIDTH_DESKTOP
                  }
                  maxHeight={IMAGE_MAX_HEIGHT}
                  disabled={loadingImage}
                  onDraw={onCanvasDraw}
                />
              ) : (
                <img
                  alt=""
                  className="stratus--edit-photo-base-modal-img"
                  src={imageSrc}
                />
              )}
            </Stack>
            {loadingImage && (
              <div className="stratus--loading-spinner-container">
                <Spinner color="primary-interactive" size="medium" />
              </div>
            )}
          </div>
        </div>
        <Stack column align="stretch">
          {fullWidthChild ? (
            afterImageChildren
          ) : (
            <div className="stratus--edit-photo-base-modal-children_container">
              {afterImageChildren}
            </div>
          )}
          <div className="stratus--edit-photo-base-modal-children_container">
            {handleOnSave && (
              <CancelAndSaveButtons
                saveText={t('confirm')}
                isDisabled={!enableSaveButton}
                onSave={onSave}
                onCancel={onCancel}
              />
            )}
          </div>
        </Stack>
      </Stack>
    </ModalAside>
  );
}

export default EditImageBaseModal;
