import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { useToastStatus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  ConfirmScopeFreeShippingModal,
  FreeShippingForm,
  FreeShippingPage,
} from '../../components';
import { useCreateFreeShippingMutation } from '../../freeShippingApi';
import { useFreeShippingForm } from '../../hooks';
import { FreeShippingFormState } from '../../types';
import { payloadDigest } from '../../utils';
import { freeShippingDefault } from '../constants';

export function NewFreeShippingPage() {
  const t = useTranslationMarketing('freeShipping.settingsPage');
  const { goBack } = useNavegate();

  const [createFreeShipping, { status }] = useCreateFreeShippingMutation();

  const handleConfirm = (data: FreeShippingFormState) => {
    createFreeShipping(payloadDigest(data));
  };

  const {
    values,
    errors,
    isDirty,
    showConfirmModal,
    handleChangeMethods,
    handleChangeCategories,
    handleChangeZones,
    handleChange,
    handleOnSubmit,
    closeConfirmModal,
  } = useFreeShippingForm({
    initialValues: freeShippingDefault,
    onSubmit: handleConfirm,
  });

  const createStatus = toStatusType(status);

  useToastStatus({
    error: t('error'),
    success: t('success'),
    status: createStatus,
    onSuccess: goBack,
  });

  return (
    <FreeShippingPage
      values={values}
      status={createStatus}
      isDirty={isDirty}
      onSave={handleOnSubmit}
    >
      <FreeShippingForm
        values={values}
        errors={errors}
        onChange={handleChange}
        onChangeMethods={handleChangeMethods}
        onChangeCategories={handleChangeCategories}
        onChangeZones={handleChangeZones}
      />
      <ConfirmScopeFreeShippingModal
        values={values}
        show={showConfirmModal}
        onClose={closeConfirmModal}
        onConfirm={handleConfirm}
      />
    </FreeShippingPage>
  );
}
