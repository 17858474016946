import { Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import {
  StatusTagItem,
  Actions,
} from 'domains/Marketing/Promotions/components';
import { Conditions } from './Conditions';

interface PromotionsListMobileRowProps {
  promotion: PromotionsItemListResponseDto;
  index: number;
}

export function PromotionsListMobileRow({
  promotion: { id, name, discountType, status, scope, scopeType, validity },
  index,
}: Readonly<PromotionsListMobileRowProps>) {
  return (
    <DataList.Row backgroundColor="transparent" topDivider={index !== 0}>
      <Stack column align="stretch" gap="3">
        <Stack justify="space-between" align="center">
          <Stack.Item>
            <Text fontWeight="medium">{name}</Text>
            <Text fontWeight="medium">{discountType}</Text>
          </Stack.Item>
          <Actions status={status} id={id} />
        </Stack>
        <StatusTagItem status={status} />
        <Conditions scope={scope} scopeType={scopeType} validity={validity} />
      </Stack>
    </DataList.Row>
  );
}
