import { Box, Text } from '@nimbus-ds/components';
import { CurrencyType } from '@tiendanube/common';
import { CurrencyPrice } from 'commons/components';
import { OrderEditProduct } from '../../Products';

interface SummaryProductProps {
  readonly product: OrderEditProduct;
  readonly currency: CurrencyType;
}

function SummaryProduct({
  product,
  currency,
}: SummaryProductProps): JSX.Element {
  return (
    <Box display="flex" flexDirection="row" paddingTop="1" paddingBottom="1">
      <Box flex="2" alignItems="center">
        <Text color="neutral-textHigh">{product.title}</Text>
        <Text color="neutral-textLow" fontSize="caption">
          {product.variants}
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        flex="1"
        paddingLeft="2"
      >
        <Box paddingRight="2">
          <Text fontSize="base">{product.quantity}x</Text>
        </Box>
        <CurrencyPrice
          price={product.totalPrice}
          currency={currency}
          appearance="light"
          size="base"
        />
      </Box>
    </Box>
  );
}

export default SummaryProduct;
