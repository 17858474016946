import { ReactNode } from 'react';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { Prompt } from 'react-router';
import { useNavegate } from 'App/hooks';
import {
  CancelAndConfirmButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import { StatusType } from 'commons/types';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { FreeShippingHelpLink } from './FreeShippingHelpLink';
import { SelectorType, FreeShippingFormState } from '../types';

const checkScope = (type: SelectorType, scope: unknown[]) =>
  (type === 'some' && scope.length) || type === 'all';

interface FreeShippingPageProps {
  values: FreeShippingFormState;
  children: ReactNode;
  status: StatusType;
  isDirty: boolean;
  showButtons?: 'skeletons' | 'normal' | 'hide';
  onSave: () => void;
}

export function FreeShippingPage({
  values: { categoriesType, categories, zonesType, zones },
  children,
  status,
  isDirty,
  showButtons = 'normal',
  onSave,
}: Readonly<FreeShippingPageProps>) {
  const { goBack } = useNavegate();
  const tCommon = useTranslationCommon('exitEdit');
  const t = useTranslationMarketing('freeShipping.settingsPage');
  const isSaving = status === 'loading';
  const saveText = isSaving ? t('saving') : t('save');

  const categoriesOk = checkScope(categoriesType, categories);
  const zonesOk = checkScope(zonesType, zones);
  const scopesOk = categoriesOk && zonesOk;

  const submitDisabled = (!scopesOk && isDirty) || !isDirty || isSaving;

  const headerAction = {
    onClick: onSave,
    children: saveText,
    icon: CheckCircleIcon,
    spinner: isSaving,
    disabled: submitDisabled,
  };

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop navigation={{ onClick: goBack }} mainAction={headerAction} />
      }
      headerContent={<HeaderContent title={t('title')} />}
    >
      {children}
      <Prompt
        when={isDirty && status !== 'success'}
        message={tCommon('info')}
      />
      {showButtons === 'normal' && (
        <CancelAndConfirmButtons
          isLoading={isSaving}
          confirmText={saveText}
          isConfirmDisabled={submitDisabled}
          isCancelDisabled={isSaving}
          onCancel={goBack}
          onConfirm={onSave}
        />
      )}
      {showButtons === 'skeletons' && <CancelAndConfirmButtons.Skeleton />}
      {showButtons !== 'hide' && <FreeShippingHelpLink />}
    </IonPageStratus>
  );
}
