import { useMemo } from 'react';
import { Box } from '@nimbus-ds/components';
import { EyeOffIcon } from '@nimbus-ds/icons';
import { InteractiveList } from '@nimbus-ds/patterns';
import { Label } from '@tiendanube/components';
import { ImageItemStratus, useResponsive } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { VariantList } from './VariantList';
import { ProductInterface, SelectedProductInterface } from '../../types';
import { VariantStockPrice } from '../VariantStockPrice';

interface ProductListItemProps<Minimalistic extends boolean> {
  variants: ProductInterface<false>[];
  selectedVariants: SelectedProductInterface[];
  minimalistic?: boolean;
  selectedVariantsLimitReached?: boolean;
  onChange: (
    selectedProducts: ProductInterface<Minimalistic>[],
    checked: boolean,
  ) => void;
}

function ProductListItem<Minimalistic extends boolean>({
  variants,
  onChange,
  selectedVariants,
  minimalistic,
  selectedVariantsLimitReached,
}: Readonly<ProductListItemProps<Minimalistic>>) {
  const t = useTranslationOrders();
  const { isMobile } = useResponsive();

  const productId = variants[0].productId;
  const productName = variants[0].name;
  const productImageUrl = variants[0].imageUrl;
  const isHidden = !variants[0].visible;

  const isChecked = useMemo(
    () =>
      selectedVariants.length > 0 &&
      selectedVariants.length ===
        variants.filter((v) => minimalistic || v.stock !== 0).length,
    [selectedVariants, variants, minimalistic],
  );

  const isIndeterminate = useMemo(
    () => selectedVariants.filter((v) => !v.isPreselected).length > 0,
    [selectedVariants],
  );

  const isDisabled = useMemo(
    () =>
      (!minimalistic &&
        ((variants.length === 1 &&
          (variants[0].stock === 0 || selectedVariants[0]?.isPreselected)) ||
          (variants.length > 1 &&
            selectedVariants.filter((sv) => sv.isPreselected).length ===
              variants.filter((v) => v.stock !== 0).length))) ||
      (selectedVariantsLimitReached && !isChecked && !isIndeterminate),
    [
      variants,
      selectedVariants,
      selectedVariantsLimitReached,
      isChecked,
      isIndeterminate,
      minimalistic,
    ],
  );

  const handleOnChange = (checked: boolean) => {
    const variantsToModify = checked
      ? variants.filter(
          (v) =>
            (minimalistic || v.stock === null || v.stock > 0) &&
            !selectedVariants.find((sv) => sv.variantId === v.variantId),
        )
      : variants.filter((v) =>
          selectedVariants
            .filter((sv) => !sv.isPreselected)
            .find((sv) => sv.variantId === v.variantId),
        );

    onChange(variantsToModify, checked);
  };

  return (
    <InteractiveList.CheckboxItem
      title=""
      showTitle={false}
      topDivider={false}
      checkbox={{
        name: 'checkbox-element',
        onChange: ({ currentTarget }) => handleOnChange(currentTarget.checked),
        indeterminate: isIndeterminate,
        checked: isChecked,
        disabled: isDisabled,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box flex="3">
          <ImageItemStratus
            id={productId}
            title={productName}
            imageUrl={productImageUrl}
            imageWidth="56px"
            appearance="default"
            titleAppearance="neutral-textLow"
            titleSize="base"
            bold
          >
            {isHidden && (
              <Box paddingY="1">
                <Label
                  id={productId}
                  label={t('productSearcher.hiddenItem')}
                  appearance="warning"
                  icon={EyeOffIcon}
                />
              </Box>
            )}
            {variants.length > 1 && (
              <VariantList
                variants={variants}
                selectedVariants={selectedVariants}
                minimalistic={minimalistic}
                selectedVariantsLimitReached={selectedVariantsLimitReached}
                onChange={onChange}
              />
            )}
          </ImageItemStratus>
        </Box>
        {!minimalistic && variants.length === 1 && (
          <Box flex={isMobile ? '3' : '2'} paddingLeft="2">
            <VariantStockPrice variant={variants[0]} />
          </Box>
        )}
      </Box>
    </InteractiveList.CheckboxItem>
  );
}

export default ProductListItem;
