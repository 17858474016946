import { Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { LanguageInterface } from 'App/i18n';
import { CancelAndSaveButtons } from 'commons/components';
import { useForm } from 'commons/hooks';
import { useGetAllLanguages } from 'domains/Auth/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { categorySchema } from './categorySchema';
import GoogleShoppingCategories from '../GoogleShoppingCategories';
import InformationCategoryCard from '../InformationCategoryCard/InformationCategoryCard';
import SeoCategories from '../SeoCategories';

export interface NewCategoryInterface {
  name: string;
  parent: string;
  googleShoppingCategory: string;
  seoTitle: LanguageInterface;
  seoDescription: LanguageInterface;
  handle: LanguageInterface;
}

interface ModalConfirmationCreateCategoryProps {
  isShow: boolean;
  onClose: () => void;
  onCreateCategory: (newCategory: NewCategoryInterface) => void;
}

const initialState: NewCategoryInterface = {
  name: '',
  parent: '',
  googleShoppingCategory: '',
  seoTitle: {},
  seoDescription: {},
  handle: {},
};

function ModalConfirmationCreateCategory({
  isShow,
  onClose,
  onCreateCategory,
}: ModalConfirmationCreateCategoryProps): JSX.Element {
  const languages = useGetAllLanguages();

  const {
    values,
    errors,
    handleOnChange,
    handleOnSubmit,
    setFieldValue,
    resetValues,
  } = useForm<NewCategoryInterface, Record<string, string>>({
    initialValues: initialState,
    validationSchema: categorySchema(languages),
    onSubmit: (values) => {
      onCreateCategory({
        name: values.name.trim(),
        googleShoppingCategory: values.googleShoppingCategory,
        parent: values.parent,
        seoTitle: values.seoTitle,
        seoDescription: values.seoDescription,
        handle: values.handle,
      });
      handleOnClose();
    },
  });

  const t = useTranslationCatalog();

  const handleOnClose = () => {
    resetValues();
    onClose();
  };

  const handleOnChangeGoogleShoppingAndSeoCategories = ({
    name,
    value,
  }: {
    name: string;
    value: string | LanguageInterface;
  }) => {
    setFieldValue(name, value);
  };
  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={handleOnClose}
      headerContent={t('products.detail.newCategory')}
    >
      <Stack column align="stretch" spacing="loose">
        <InformationCategoryCard
          values={values}
          errors={errors}
          handleOnChange={handleOnChange}
        />
        <GoogleShoppingCategories
          googleShoppingCategory={values.googleShoppingCategory}
          handleChange={handleOnChangeGoogleShoppingAndSeoCategories}
          errors={errors}
        />
        <SeoCategories
          seoTitle={values.seoTitle}
          seoDescription={values.seoDescription}
          handle={values.handle}
          handleChange={handleOnChangeGoogleShoppingAndSeoCategories}
          errors={errors}
        />
        <CancelAndSaveButtons
          saveText={t('products.detail.createCategory')}
          cancelText={t('products.detail.cancel')}
          onCancel={handleOnClose}
          onSave={handleOnSubmit}
        />
      </Stack>
    </ModalAside>
  );
}

export default ModalConfirmationCreateCategory;
