import { ChangeEvent, useMemo, useState } from 'react';
import { Icon, Input, Title } from '@nimbus-ds/components';
import { SearchIcon, TagIcon } from '@nimbus-ds/icons';
import { InteractiveList, EmptyMessage } from '@nimbus-ds/patterns';
import { CategoryTabedResponseDto } from '@tiendanube/common';
import { Text } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import {
  ErrorScreen,
  InteractiveListRightCheckbox,
  Repeater,
  Stack,
} from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import { useGetCategoriesOptionsQuery } from 'domains/Catalog/Categories/categoriesApi';
import { CategoriesInterface } from './Categories';
import { useTranslationMarketing } from '../hook';

interface CategoriesCheckedInterface extends CategoryTabedResponseDto {
  checked: boolean;
}

interface CategoriesModalAsideProps {
  isOpen: boolean;
  selected: CategoriesInterface[];
  title: string;
  subtitle?: string;
  emptyTitle: string;
  emptyText: string;
  onChange: (methods: CategoriesInterface[]) => void;
  onClose: () => void;
}

const skeleton = () => (
  <InteractiveListRightCheckbox.Skeleton avoidHorizontalPadding />
);

export function CategoriesModalAside({
  isOpen,
  selected,
  title,
  subtitle,
  emptyTitle,
  emptyText,
  onChange,
  onClose,
}: Readonly<CategoriesModalAsideProps>) {
  const { ref } = useAutoFocus();
  const t = useTranslationMarketing('common');
  const { data, isLoading, isError, isSuccess, refetch } =
    useGetCategoriesOptionsQuery(undefined, { skip: !isOpen });

  const [search, setSearch] = useState('');
  const searchLowerCase = search.toLowerCase();
  const categoriesStore: CategoriesCheckedInterface[] = useMemo(
    () =>
      (data || [])
        .filter(
          ({ title }) =>
            !search || title.toLowerCase().includes(searchLowerCase),
        )
        .map((currentData) => ({
          ...currentData,
          checked: selected.some((current) => current.id === currentData.id),
        })),
    [data, search, searchLowerCase, selected],
  );

  const handleCheckedCategory = (
    category: CategoriesInterface,
    checked: boolean,
  ) => {
    if (checked) {
      onChange([...selected, category]);
    } else {
      onChange(selected.filter((current) => current.id !== category.id));
    }
  };

  const handleOnChangeSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const isEmpty = isSuccess && categoriesStore.length === 0 && !search;

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerContent={<Title>{title}</Title>}
    >
      <Stack innerRef={ref} column align="stretch">
        {isError && <ErrorScreen onRetry={refetch} />}
        {isEmpty && (
          <EmptyMessage
            title={emptyTitle}
            text={emptyText}
            icon={<TagIcon />}
          />
        )}
        {isLoading && (
          <InteractiveList>
            <Repeater times={10} item={skeleton} />
          </InteractiveList>
        )}
        {isSuccess && !isEmpty && (
          <>
            {!!subtitle && (
              <Stack.Item>
                <Text> {subtitle} </Text>
              </Stack.Item>
            )}
            <Input
              value={search}
              onChange={handleOnChangeSearch}
              append={<Icon source={<SearchIcon size="small" />} />}
              appendPosition="start"
              placeholder={t('search')}
            />
            <InteractiveList>
              {categoriesStore.map(({ id, fixed, checked }) => {
                const title = fixed ? `${fixed.prefix}${fixed.title}` : '';
                const handleChange = () => {
                  handleCheckedCategory(
                    {
                      id,
                      description: title,
                    },
                    !checked,
                  );
                };
                return (
                  <InteractiveListRightCheckbox
                    key={id}
                    title={title}
                    avoidHorizontalPadding
                    checkbox={{
                      name: `method_${id}`,
                      checked: checked,
                      onChange: handleChange,
                    }}
                  />
                );
              })}
            </InteractiveList>
          </>
        )}
      </Stack>
    </ModalAside>
  );
}
