import { useCallback, useMemo } from 'react';
import { CouponsResponseDto } from '@tiendanube/common';
import {
  CouponValuesInterface,
  initialValues as defaultInitialValues,
} from 'domains/Marketing/Coupons/components/CouponForm';
import { Scope } from 'domains/Marketing/enums';

function useEditCouponPage(coupon?: CouponsResponseDto) {
  const initialValues: CouponValuesInterface = useMemo(() => {
    if (!coupon) return defaultInitialValues;
    return {
      ...defaultInitialValues,
      ...{
        couponCode: coupon.code,
        active: coupon.status !== 'disabled',
        discountType: coupon.type,
        valueAbsolute: coupon.value.toString(),
        valuePercentage: coupon.value.toString(),
        includeShipping: coupon.includeShipping,
      },
      ...(coupon.limited?.startDate &&
        coupon.limited?.endDate && {
          dateType: 'limited',
          startDate: coupon.limited.startDate.split('/').reverse().join('-'),
          endDate: coupon.limited.endDate.split('/').reverse().join('-'),
        }),
      ...(coupon.limited?.maxUses && {
        limitedType: 'limited',
        maxUses: coupon.limited.maxUses.toString(),
      }),
      ...(coupon.limited?.categoriesV2 &&
        coupon.limited.categoriesV2.length > 0 && {
          categories: coupon.limited.categoriesV2.map((category) => ({
            id: category.id.toString(),
            description: category.name,
          })),
          scopeType: Scope.CATEGORIES,
        }),
      ...(coupon.limited?.products &&
        coupon.limited.products.length > 0 && {
          products: coupon.limited?.products.map((product) => ({
            id: product.id.toString(),
            description: product.name,
          })),
          scopeType: Scope.PRODUCTS,
        }),
      ...(coupon.limited?.minPrice && {
        minPrice: coupon.limited.minPrice.toString(),
      }),
      ...(coupon.limited?.clientLimitationType && {
        clientLimitationType: coupon.limited.clientLimitationType,
      }),
      ...(coupon.limited?.combinesWithOtherDiscounts !== undefined && {
        combinesWithOtherDiscounts: coupon.limited.combinesWithOtherDiscounts,
      }),
      ...(coupon.limited?.maxUsesPerClient && {
        maxUsesPerClient: coupon.limited.maxUsesPerClient.toString(),
      }),
    };
  }, [coupon]);

  const changedValues = useCallback(
    (newValues: CouponValuesInterface): Partial<CouponValuesInterface> => {
      if (newValues.scopeType !== Scope.CATEGORIES) {
        newValues.categories = [];
      }
      if (newValues.scopeType !== Scope.PRODUCTS) {
        newValues.products = [];
      }

      const diff: Partial<CouponValuesInterface> = Object.keys(
        initialValues,
      ).reduce((accum, key) => {
        if (['categories', 'products'].includes(key)) {
          if (
            newValues[key] !== initialValues[key] &&
            (newValues[key].length > 0 || initialValues[key].length > 0)
          ) {
            accum[key] = newValues[key];
          }
        } else if (newValues[key] !== initialValues[key]) {
          accum[key] = newValues[key];
        }
        return accum;
      }, {});

      if (diff.valueAbsolute || diff.valuePercentage || diff.discountType) {
        diff.discountType = newValues.discountType;
        diff.valueAbsolute = newValues.valueAbsolute;
        diff.valuePercentage = newValues.valuePercentage;
      }
      if (diff.dateType || diff.startDate || diff.endDate) {
        diff.dateType = newValues.dateType;
        diff.startDate = newValues.startDate;
        diff.endDate = newValues.endDate;
      }
      if (diff.limitedType === 'unlimited') {
        diff.maxUses = '0';
      }

      return diff;
    },
    [initialValues],
  );

  return { initialValues, changedValues };
}

export default useEditCouponPage;
