import { useNavegate } from 'App/hooks';
import useCoupons from 'domains/Marketing/Coupons/hooks/useCoupons';
import { trackingAccessEditCouponPage } from 'domains/Marketing/Coupons/tracking';
import { CustomAction } from './CustomAction';

export function Actions({ id, status }) {
  const { activateCoupon, removeCoupon, deactivateCoupon } = useCoupons();
  const { goTo } = useNavegate();

  const showActivate = status === 'disabled';
  const showDeactivate = status === 'enabled';

  const handleActionActivate = () => activateCoupon(id);
  const handleActionDeactivate = () => deactivateCoupon(id);
  const handleActionDelete = () => removeCoupon(id);
  const handleActionEdit = () => {
    trackingAccessEditCouponPage(id);
    goTo(`/coupons/${id}`);
    return Promise.resolve();
  };

  return (
    <>
      <CustomAction action="edit" onAction={handleActionEdit} />
      {showActivate && (
        <CustomAction action="activate" onAction={handleActionActivate} />
      )}
      {showDeactivate && (
        <CustomAction action="deactivate" onAction={handleActionDeactivate} />
      )}
      <CustomAction action="delete" onAction={handleActionDelete} />
    </>
  );
}
