import { useEffect, useState } from 'react';
import { AiFeature } from '@tiendanube/common/src/enums';
import { AiFeedbackModal } from 'commons/components';
import { useToast } from 'commons/hooks';
import useAiFeedbackFlow from 'commons/hooks/useAiFeedbackFlow';
import useChangeImageBackground from 'domains/Ai/hooks/useChangeImageBackground';
import {
  trackingAiEditImageReplaceBgWithImageUseClick,
  trackingAiEditImageReplaceBgWithImagePickClick,
} from 'domains/Ai/tracking';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import BackgroundGallery from './components/BackgroundGallery';
import { BackgroundGalleryElement } from './components/backgrounds';
import { ImageGalleryState } from '../../../../../ImageGallery';
import EditPhotoBaseModal from '../../../EditImageBaseModal';
import { EditedImage } from '../../../EditImageModal';
import BackgroudRemoveUncertaintyAlert from '../Alerts/BackgroudRemoveUncertaintyAlert';

interface ChangeBackgroundImageModalProps {
  isShow?: boolean;
  currentImage: ImageGalleryState;
  onClose?: () => void;
  onSaveImage: (image: EditedImage) => void;
}

function ChangeBackgroundImageModal({
  isShow = false,
  currentImage,
  onClose,
  onSaveImage,
}: Readonly<ChangeBackgroundImageModalProps>) {
  const { addToast } = useToast();
  const t = useTranslationCatalog('products');

  const {
    errorOnBackgroundChange,
    unSavedChanges,
    displayedImage,
    handleBackgroundImageSelected,
    changingBackground,
    editedImageUncertain,
  } = useChangeImageBackground(currentImage);

  const [selectedBackground, setSelectedBackground] = useState<
    BackgroundGalleryElement | undefined
  >();

  const [imageHasBeenSelected, setImageHasBeenSelected] = useState(false);

  const handleOnSubmit = () => {
    if (unSavedChanges && displayedImage.base64) {
      onSaveImage({ base64: displayedImage.base64 });
      if (selectedBackground) {
        trackingAiEditImageReplaceBgWithImageUseClick({
          selectedImageId: selectedBackground.id,
        });
      }
    }
    if (onClose) {
      onClose();
    }
  };

  const onSelectBackground = (background: BackgroundGalleryElement) => {
    setImageHasBeenSelected(true);
    handleBackgroundImageSelected(background);
    setSelectedBackground(background);
    trackingAiEditImageReplaceBgWithImagePickClick({
      selectedImageId: background.id,
    });
  };

  useEffect(() => {
    if (errorOnBackgroundChange) {
      addToast({
        appearance: 'danger',
        label: t('modal.editPhoto.toasts.error.label'),
      });
    }
  }, [addToast, errorOnBackgroundChange, t]);

  const handleOnClose = () => {
    if (onClose) {
      setImageHasBeenSelected(false);
      onClose();
    }
  };

  const handleOnCloseWithFeedback = () => {
    if (imageHasBeenSelected) {
      openAiGenerationFeedbackModal();
    } else {
      handleOnClose();
    }
  };

  const [
    showAiGenerationFeedbackModal,
    openAiGenerationFeedbackModal,
    closeAiGenerationFeedbackModal,
  ] = useAiFeedbackFlow(AiFeature.AI_IMAGES_REPLACE_BG_IMAGE, handleOnClose);

  return (
    <>
      <EditPhotoBaseModal
        isShow={isShow}
        loadingImage={changingBackground}
        displayImageSource={displayedImage}
        onClose={handleOnCloseWithFeedback}
        translationPrefix="products.modal.editPhoto.actions.changeBackgroundImage.modal"
        beforeImageChildren={
          editedImageUncertain ? <BackgroudRemoveUncertaintyAlert /> : undefined
        }
        afterImageChildren={
          <BackgroundGallery
            selectedBackground={selectedBackground}
            onSelect={onSelectBackground}
          />
        }
        handleOnSave={handleOnSubmit}
        enableSaveButton={
          unSavedChanges && !changingBackground && !errorOnBackgroundChange
        }
      />
      <AiFeedbackModal
        asAside
        show={showAiGenerationFeedbackModal}
        title={t(
          'modal.editPhoto.actions.changeBackgroundImage.modal.feedback.title',
        )}
        text={t(
          'modal.editPhoto.actions.changeBackgroundImage.modal.feedback.text',
        )}
        feature={AiFeature.AI_IMAGES_REPLACE_BG_IMAGE}
        feedbackParams={{
          imageEditing: {
            oldImageUrl: currentImage.publicUrl!,
            newImageBase64: displayedImage.base64!,
          },
        }}
        onDismiss={() => {
          closeAiGenerationFeedbackModal();
          handleOnClose();
        }}
      />
    </>
  );
}

export default ChangeBackgroundImageModal;
