import { Box, Button, Card, Title } from '@nimbus-ds/components';
import { useModal } from 'commons/hooks';
import {
  trackingThemeLayoutChange,
  trackingThemeLayoutDraftCreation,
} from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import ImageSrc from './images/not_theme_draft.png';
import { NewDraftModal } from './NewDraftModal';
import { useGoToThemesStore } from '../../hooks';
import './DraftCard.scss';

interface DraftAdProps {
  firstDraft?: boolean;
}

export function DraftAd({ firstDraft = true }: Readonly<DraftAdProps>) {
  const t = useTranslationOnline('themesPage.draft');
  const [showModal, openModal, closeModal] = useModal();

  const handleGoToThemesStore = useGoToThemesStore();

  const handleOpenModal = () => {
    trackingThemeLayoutDraftCreation();
    openModal();
  };

  const handleClickTryNewDesign = async () => {
    trackingThemeLayoutChange();
    await handleGoToThemesStore();
  };

  const actionLabel = firstDraft ? t('createDraft') : t('switchDraft');
  const action = firstDraft ? handleOpenModal : handleClickTryNewDesign;

  return (
    <>
      <Card padding="none">
        <Card.Header>
          <div className="draft-card-img-container">
            <img className="draft-card-img" alt="image" src={ImageSrc} />
          </div>
        </Card.Header>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          paddingBottom="4"
          paddingLeft="4"
          paddingRight="4"
          gap="2"
          alignItems={{ md: 'center', xs: 'flex-start' }}
          justifyContent="space-between"
        >
          <Title as="h4">{t('title')}</Title>
          <Button appearance="neutral" onClick={action}>
            {actionLabel}
          </Button>
        </Box>
      </Card>
      <NewDraftModal showModal={showModal} onClose={closeModal} />
    </>
  );
}
