export const BASE_DOMAINS_URL = '/v1/domains';

export const DOMAIN_CONFIG_URL = `${BASE_DOMAINS_URL}/:id/config`;
export const SET_PRIMARY_DOMAIN_URL = `${BASE_DOMAINS_URL}/:id/primary`;
export const UPDATE_OUR_DOMAIN_URL = `${BASE_DOMAINS_URL}/our_domain`;
export const DELETE_DOMAIN_URL = `${BASE_DOMAINS_URL}/:id`;
export const UPDATE_DOMAIN_STATUS_URL = `${BASE_DOMAINS_URL}/status`;
export const GET_DOMAIN_TO_UPDATE_URL = `${BASE_DOMAINS_URL}/check-migration`;
export const SET_DOMAIN_TO_NOTIFY_URL = `${BASE_DOMAINS_URL}/notify-migration`;

export const domainsEndpoints = {
  getDomains: 'getDomains',
  createDomain: 'createDomain',
  deleteDomain: 'deleteDomain',
  updateOurDomain: 'updateOurDomain',
  getDomainConfig: 'getDomainConfig',
  setPrimaryDomain: 'setPrimaryDomain',
  updateDomainStatus: 'updateDomainStatus',
  getDomainSoftBlock: 'getDomainSoftBlock',
  setDomainToNotify: 'setDomainToNotify',
} as const;

export const domainsTags = {
  domainsList: 'domainsList',
} as const;
