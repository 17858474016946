import { useMemo } from 'react';
import { FEATURE_EDIT_ORDER } from 'App/features';
import {
  useGetTags,
  useStatusFeatureByKeyWithDefaultValue,
} from 'domains/Auth/hooks';

function useIsEditOrdersEnabled() {
  const tags = useGetTags();
  const hasEditOrderTag = useMemo(
    () => tags.includes('new-admin-order-edit'),
    [tags],
  );

  const { isAvailable } = useStatusFeatureByKeyWithDefaultValue(
    FEATURE_EDIT_ORDER,
    0,
  );

  const isEnabled = useMemo(
    () => isAvailable && hasEditOrderTag,
    [isAvailable, hasEditOrderTag],
  );

  return isEnabled;
}

export default useIsEditOrdersEnabled;
