import { ReactNode } from 'react';
import { InterfaceButton, InterfaceLabel, Stack } from '@tiendanube/components';
import { useResponsive } from 'commons/components/Responsive';
import { useDocumentTitle } from 'commons/hooks';
import { Labels, Pagination, Subtitle, Title } from './components';

export interface HeaderProps {
  title?: string;
  documentTitle?: string;
  subtitle?: string;
  labels?: (InterfaceLabel | 'skeleton')[];
  menuMobile?: ReactNode;
  actions?: ReactNode;
  applications?: ReactNode;
  headerNavigation?: Pick<InterfaceButton, 'children' | 'onClick'>;

  paginationPrevious?: () => void;
  paginationNext?: () => void;
}

function HeaderContent({
  title,
  documentTitle = '',
  subtitle,
  labels,
  actions,
  applications,
  paginationPrevious,
  paginationNext,
}: HeaderProps): JSX.Element {
  const { isDesktop } = useResponsive();
  useDocumentTitle(documentTitle || title);

  return (
    <>
      <Stack spacing="tight">
        <Stack.Item fill>{!!title && <Title text={title} />}</Stack.Item>
        {(paginationPrevious || paginationNext) && (
          <Stack.Item>
            <Pagination
              paginationPrevious={paginationPrevious}
              paginationNext={paginationNext}
            />
          </Stack.Item>
        )}
        {!!applications && isDesktop && <Stack.Item>{applications}</Stack.Item>}
        {!!actions && isDesktop && <Stack.Item>{actions}</Stack.Item>}
      </Stack>
      {!!subtitle && <Subtitle text={subtitle} />}
      {labels && <Labels>{labels}</Labels>}
    </>
  );
}

export default HeaderContent;
