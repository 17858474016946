import { useEffect } from 'react';
import { Alert, Link, Text } from '@nimbus-ds/components';
import { useQuery } from 'App/hooks';
import { useBoolean, useToastStatus } from 'commons/hooks';
import { useGetUserInfo } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import EmailVerifiedSuccessAlert from './EmailVerifiedSuccess';
import useEmailVerifiedAlert from './useEmailVerifiedAlert';

function EmailVerifiedAlert() {
  const verifyUserConfirmation = useQuery().get('userConfirmed');
  const { userConfirmed, email } = useGetUserInfo();
  const [showAlert, openAlert, closeAlert] = useBoolean();
  const { status, forwardConfirmationEmail } = useEmailVerifiedAlert();
  const t = useTranslationDashboard('email-not-confirmed');
  const showEmailNotConfirmedAlert =
    userConfirmed !== undefined && !userConfirmed && showAlert;

  useToastStatus({
    status,
    success: t('success'),
    error: t('error'),
  });

  useEffect(() => {
    if (!userConfirmed && !verifyUserConfirmation) openAlert();
  }, [openAlert, userConfirmed, verifyUserConfirmation]);

  if (verifyUserConfirmation) return <EmailVerifiedSuccessAlert />;

  return (
    <Alert
      appearance="primary"
      show={showEmailNotConfirmedAlert}
      title={t('title')}
      onRemove={closeAlert}
    >
      <Text>{t('description', { email })}</Text>
      <Link
        as="button"
        disabled={status === 'loading'}
        onClick={forwardConfirmationEmail}
      >
        {t('cta')}
      </Link>
    </Alert>
  );
}

export default EmailVerifiedAlert;
