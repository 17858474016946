import { useEffect, useState } from 'react';
import { Box, Card, Thumbnail, Title, Text, Link } from '@nimbus-ds/components';
import { Link as LinkRoute, generatePath } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import { BlockWithTitle } from 'commons/components';
import { useStorage } from 'commons/hooks';
import { useGetCountry, useGetStoreInfo } from 'domains/Auth/hooks';
import { useGetEcommerceNaPraticaQuery } from 'domains/Dashboard/dashboardApi';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingEnpClick } from 'domains/Dashboard/tracking';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import ImageSrc from './enp-image.png';
import useOnboardingTasks from '../OnboardingTasks/useOnboardingTasks';

const ECOMMERCE_APP_ID = '1006';

function EcommerceNaPraticaCard() {
  const t = useTranslationDashboard('improveStore.e-commerce');
  const country = useGetCountry();
  const { id } = useGetStoreInfo();
  const [hasInstalled, setHasInstalled, loaded] = useStorage(
    `ecommerce-has-installed-${id}`,
    false,
  );
  const [skip, setSkip] = useState(true);
  const { data } = useGetEcommerceNaPraticaQuery(undefined, { skip });
  const { hasCompleted } = useOnboardingTasks();
  const shouldFetch =
    loaded && !hasCompleted && country === Country.BR && !hasInstalled;

  useEffect(() => {
    if (data?.hasInstalled) setHasInstalled(true);
  }, [data?.hasInstalled, setHasInstalled]);

  useEffect(() => {
    setSkip(!shouldFetch);
  }, [shouldFetch]);

  if (hasCompleted || !data || data.hasInstalled) return null;

  return (
    <ShowByCountry allowedCountries={[Country.BR]}>
      <BlockWithTitle title={t('titleCard')}>
        <Card>
          <Card.Body>
            <Box
              display="flex"
              flexDirection={{
                lg: 'row',
                md: 'row',
                xs: 'column',
              }}
              gap="4"
            >
              <Box
                minWidth={{
                  md: '215px',
                }}
                maxWidth={{
                  lg: '237px',
                }}
              >
                <Thumbnail aspectRatio="16/9" alt="image" src={ImageSrc} />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap="4"
                justifyContent="center"
                maxWidth="703px"
              >
                <Box display="flex" flexDirection="column" gap="2">
                  <Title as="h3">{t('title')}</Title>
                  <Text>{t('description')}</Text>
                </Box>
                <Link
                  textDecoration="none"
                  as={LinkRoute}
                  to={generatePath(partnersAppsRoutes.authorization, {
                    id: ECOMMERCE_APP_ID,
                  })}
                  appearance="primary"
                  onClick={trackingEnpClick}
                >
                  {t('linkText')}
                </Link>
              </Box>
            </Box>
          </Card.Body>
        </Card>
      </BlockWithTitle>
    </ShowByCountry>
  );
}

export default EcommerceNaPraticaCard;
