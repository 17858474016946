import { useSelector } from 'react-redux';
import { getInfoStore } from 'domains/Auth/authSlice/authSelectors';

export interface StoreInfo {
  id: string;
  name: string;
  url: string;
  language: string;
  currency: string;
  country: string;
  createdAt: string;
  partnerId?: number;
  hasFtp: boolean;
  npsElegible: boolean;
  isTrial: boolean;
  currentTheme: string;
  currentSegment: string;
  successPortfolio: boolean;
}

function useStoreInfo(): StoreInfo {
  return useSelector(getInfoStore);
}

export default useStoreInfo;
