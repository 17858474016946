export const DASHBOARD_BASE_URL = '/v1/dashboard';
export const DASHBOARD_FIRST_SALE_URL = `${DASHBOARD_BASE_URL}/first-sale`;
export const DASHBOARD_ONBOARDING_TASKS_URL = `${DASHBOARD_BASE_URL}/onboarding-tasks`;
export const DASHBOARD_ONBOARDING_TASK_PAYMENT_METHODS_URL = `${DASHBOARD_ONBOARDING_TASKS_URL}/setup-payments`;
export const DASHBOARD_USERS_FORWARD_CONFIRMATION_EMAIL_URL = `${DASHBOARD_BASE_URL}/users/forward-confirmation-email`;
export const DASHBOARD_DISMISS_CLOUDFLARE_BLOCK_URL = `${DASHBOARD_BASE_URL}/cloudflare-migration/dismiss-cloudflare-block`;
export const DASHBOARD_CLOUDFLARE_MIGRATION_CHECK = `${DASHBOARD_BASE_URL}/cloudflare-migration/check-success`;
export const DASHBOARD_MESSAGES_URL = `${DASHBOARD_BASE_URL}/messages`;
export const DASHBOARD_BILLING_MESSAGES_URL = `${DASHBOARD_BASE_URL}/trial-messages`;
export const DASHBOARD_BILLING_PLANS_URL = `${DASHBOARD_BASE_URL}/billing-plans`;
export const DASHBOARD_PAYMENTS_MESSAGES_URL = `${DASHBOARD_MESSAGES_URL}/payments`;
export const DASHBOARD_PAYMENTS_MESSAGES_PAGSEGURO_URL = `${DASHBOARD_PAYMENTS_MESSAGES_URL}/pagseguro`;
export const DASHBOARD_PAYMENTS_MESSAGES_MERCADOPAGO_URL = `${DASHBOARD_PAYMENTS_MESSAGES_URL}/mercadopago`;
export const DASHBOARD_SOCIAL_MESSAGES_URL = `${DASHBOARD_MESSAGES_URL}/social`;
export const DASHBOARD_SOCIAL_MESSAGES_FACEBOOK_CONNECTION_URL = `${DASHBOARD_SOCIAL_MESSAGES_URL}/facebook-connection`;
