import { Suspense, useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import RequiresForcedUpdate from 'App/components/RequiresForcedUpdate';
import RouteByCountry from 'App/components/RouteByCountry';
// Domains Routes
import { FEATURE_ABANDONED_CARTS, FEATURE_DISCOUNTS } from 'App/features';
import useShowByFeature from 'App/hooks/useShowByFeature';
import Account, { accountRoutes } from 'domains/Account';
import { CanceledStorePage } from 'domains/Account/pages';
import Auth from 'domains/Auth';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  useAdminDisabled,
  useCanEnterAdmin,
  useTransactionFeeAdminDisabled,
} from 'domains/Auth/hooks';
import BillingPlans from 'domains/Billing/BillingPlans';
import Checkout from 'domains/Billing/Checkout';
import { CHECKOUT_BASE_ROUTE } from 'domains/Billing/Checkout/checkoutRoutes';
import BillingCoupons from 'domains/Billing/Coupons';
import Invoices from 'domains/Billing/Invoices';
import TransactionFees from 'domains/Billing/TransactionFees';
import { TRANSACTION_FEES_ROUTES } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import { Products, Categories, Inventory } from 'domains/Catalog';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import { useDomainSoftBlock } from 'domains/Configurations/Domains/hooks';
import {
  DomainSoftBlockPage,
  DomainsPageWithSoftBlock,
} from 'domains/Configurations/Domains/pages';
import { ExternalCodes } from 'domains/Configurations/ExternalCodes';
import I18n from 'domains/Configurations/I18n';
import { Notifications } from 'domains/Configurations/Notifications';
import SeoRedirects from 'domains/Configurations/SeoRedirects';
import { seoRedirectsRoutes } from 'domains/Configurations/SeoRedirects/seoRedirectsRoutes';
import { SettingsCheckoutPage } from 'domains/Configurations/SettingsCheckout';
import { WhatsappPage } from 'domains/Configurations/Whatsapp';
import Customers from 'domains/Customers';
import { useGetHasAccessToCustomers } from 'domains/Customers/hooks';
import Messages from 'domains/Customers/Messages';
import { MESSAGES_ROUTES } from 'domains/Customers/Messages/messagesRoutes';
import Dashboard, {
  DASHBOARD_BASE_PATH,
  dashboardRoutes,
  useDisabledAdminForRoles,
} from 'domains/Dashboard';
import useCloudflareBlocked from 'domains/Dashboard/hooks/useCloudflareBlocked';
import CloudflareBlockPage from 'domains/Dashboard/pages/CloudflareBlock';
import { marketingRoutes } from 'domains/Marketing';
import Coupons from 'domains/Marketing/Coupons';
import FreeShipping, {
  FREE_SHIPPING_ROUTES,
} from 'domains/Marketing/FreeShipping';
import MarketingApps from 'domains/Marketing/MarketingApps';
import MarketingAutomation from 'domains/Marketing/MarketingAutomation';
import Promotions from 'domains/Marketing/Promotions';
import NubeKeyboard from 'domains/NubeKeyboard';
import { ContentPages, CONTENT_PAGES_ROUTES } from 'domains/Online';
import { InfoMessage, infoMessageRoutes } from 'domains/Online/InfoMessage';
import Menus from 'domains/Online/Menus';
import { MENUS_ROUTES } from 'domains/Online/Menus/menusRoutes';
import {
  ContactInfoPage,
  PasswordProtectedPage,
  ThemesPage,
} from 'domains/Online/Themes/pages';
import { THEMES_ROUTES } from 'domains/Online/Themes/themesRoutes';
import Orders from 'domains/Orders';
import AbandonedCarts from 'domains/Orders/AbandonedCart';
import DraftOrders from 'domains/Orders/DraftOrders';
import PartnersApps from 'domains/PartnersApps';
import NuvemAppPage from 'domains/PartnersApps/pages/NuvemAppPage';
import Payments from 'domains/Payments/Payments';
import { PAYMENTS_BASE_ROUTE } from 'domains/Payments/paymentsRoutes';
import { salesChannelsRoutes } from 'domains/SalesChannels';
import SalesChannels from 'domains/SalesChannels/SalesChannels';
import DeliveryMethods from 'domains/Shipping/DeliveryMethods';
import { SHIPPING_METHODS_BASE_ROUTE } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import Locations from 'domains/Shipping/Locations';
import Statistics, { useHasAccessToStats } from 'domains/Statistics';
import { STATS_ROUTES } from 'domains/Statistics/statisticsRoutes';
import AppLayout from '../components/AppLayout';
import Menu from '../components/Menu';
import { NavTabsMobile } from '../components/Navtabs';
import PrivateRoutes from '../components/PrivateRoute';

function RoutesMobile() {
  const { validateRoles } = useRoles();
  const isAdminDisabled = useAdminDisabled();
  const { scrollToTop } = useLayoutScroll();
  const { listen } = useHistory();
  const canEnterAdmin = useCanEnterAdmin();
  const isTransactionFeeAdminDisabled = useTransactionFeeAdminDisabled();
  const { cloudflareBlocked, hardBlock, dismissCloudflareBlock } =
    useCloudflareBlocked();
  const isDisabledAdminForRoles = useDisabledAdminForRoles();
  const hasAccessToCustomers = useGetHasAccessToCustomers();
  const hasAbandonedCartsFeature = useShowByFeature(FEATURE_ABANDONED_CARTS);
  const hasDiscountFeature = useShowByFeature(FEATURE_DISCOUNTS);
  const hasAccessToStats = useHasAccessToStats();
  const hasNuvemPagoRole = validateRoles('nuvem_pago');
  const { domainSoftBlock } = useDomainSoftBlock();

  useLayoutEffect(() => {
    const unlisten = listen((_, action) => {
      if (action !== 'POP') scrollToTop();
    });
    return () => {
      unlisten();
    };
  }, [scrollToTop, listen]);

  return (
    <Switch>
      <Route path="/auth">
        <Auth />
      </Route>
      <PrivateRoutes path="/">
        <RequiresForcedUpdate>
          <Switch>
            {cloudflareBlocked && (
              <Route path="*">
                <CloudflareBlockPage
                  onDismiss={dismissCloudflareBlock}
                  hardBlock={hardBlock}
                />
              </Route>
            )}

            <Route path="/cloudflare-block">
              <CloudflareBlockPage
                onDismiss={dismissCloudflareBlock}
                isBlocked={cloudflareBlocked}
                hardBlock={hardBlock}
              />
            </Route>

            <AppLayout
              menu={<Menu device="mobile" />}
              navtabs={<NavTabsMobile />}
            >
              <Switch>
                {isAdminDisabled && !canEnterAdmin && (
                  <Switch>
                    <Route path={CHECKOUT_BASE_ROUTE}>
                      <Suspense fallback={null}>
                        <Checkout />
                      </Suspense>
                    </Route>
                    <Route path="*">
                      <Suspense fallback={null}>
                        <CanceledStorePage />
                      </Suspense>
                    </Route>
                  </Switch>
                )}

                {domainSoftBlock && (
                  <Route path="*">
                    <DomainSoftBlockPage />
                  </Route>
                )}

                <Route path={DASHBOARD_BASE_PATH}>
                  <Dashboard />
                </Route>

                {isTransactionFeeAdminDisabled && (
                  <Switch>
                    <Route path="/account">
                      <Suspense fallback={null}>
                        {validateRoles('payments_and_subscriptions') ? (
                          <>
                            <Account />
                            <BillingPlans />
                            <BillingCoupons />
                            <Invoices />
                            <TransactionFees />
                            <Checkout />
                          </>
                        ) : (
                          <>
                            <Account />
                            <Invoices />
                          </>
                        )}
                      </Suspense>
                    </Route>
                    <Redirect from="/" to={dashboardRoutes.dashboard} exact />
                    <Redirect
                      from="*"
                      to={
                        isDisabledAdminForRoles
                          ? dashboardRoutes.dashboard
                          : TRANSACTION_FEES_ROUTES.transactionFees
                      }
                      exact
                    />
                  </Switch>
                )}

                <Route path="/nube-keyboard">
                  <Suspense fallback={null}>
                    <NubeKeyboard />
                  </Suspense>
                </Route>

                {validateRoles('design') && (
                  <Route path="/blog">
                    <Suspense fallback={null}>
                      <NuvemAppPage code="blog" />
                    </Suspense>
                  </Route>
                )}

                {validateRoles('products') && (
                  <Route path="/products">
                    <Suspense fallback={null}>
                      <Products />
                    </Suspense>
                  </Route>
                )}

                {validateRoles('products') && (
                  <Route path="/inventory">
                    <Suspense fallback={null}>
                      <Inventory />
                    </Suspense>
                  </Route>
                )}

                {validateRoles('products') && (
                  <Route path="/categories">
                    <Suspense fallback={null}>
                      <Categories />
                    </Suspense>
                  </Route>
                )}

                {hasAccessToCustomers && (
                  <Route path="/customers">
                    <Suspense fallback={null}>
                      <Customers />
                    </Suspense>
                  </Route>
                )}

                {hasAccessToCustomers && (
                  <Route path={MESSAGES_ROUTES.messages}>
                    <Suspense fallback={null}>
                      <Messages />
                    </Suspense>
                  </Route>
                )}

                {validateRoles('orders') && (
                  <Route path="/orders">
                    <Suspense fallback={null}>
                      <Orders />
                    </Suspense>
                  </Route>
                )}

                {validateRoles('draft_orders') && (
                  <Route path="/draft-orders">
                    <Suspense fallback={null}>
                      <DraftOrders />
                    </Suspense>
                  </Route>
                )}

                {validateRoles('abandoned_carts') &&
                  hasAbandonedCartsFeature && (
                    <Route path="/abandoned-carts">
                      <Suspense fallback={null}>
                        <AbandonedCarts />
                      </Suspense>
                    </Route>
                  )}

                {validateRoles(['full', 'marketing']) && (
                  <Route path={FREE_SHIPPING_ROUTES.freeShippingList}>
                    <Suspense fallback={null}>
                      <FreeShipping />
                    </Suspense>
                  </Route>
                )}

                {validateRoles(['full', 'marketing']) && (
                  <Route path="/promotions">
                    <Suspense fallback={null}>
                      <Promotions />
                    </Suspense>
                  </Route>
                )}

                {validateRoles(['full', 'marketing']) && hasDiscountFeature && (
                  <Route path="/coupons">
                    <Suspense fallback={null}>
                      <Coupons />
                    </Suspense>
                  </Route>
                )}

                {validateRoles(['full', 'marketing']) && (
                  <Route path={marketingRoutes.featuredApplications}>
                    <MarketingApps />
                  </Route>
                )}

                {validateRoles(['full', 'marketing']) && (
                  <Route path={marketingRoutes.marketingAutomation}>
                    <Suspense fallback={null}>
                      <MarketingAutomation />
                    </Suspense>
                  </Route>
                )}

                {validateRoles('seo_redirects') && (
                  <Route path={seoRedirectsRoutes.redirects}>
                    <SeoRedirects />
                  </Route>
                )}

                {validateRoles(['full', 'locations']) && (
                  <Route path="/settings/locations">
                    <Suspense fallback={null}>
                      <Locations />
                    </Suspense>
                  </Route>
                )}

                {validateRoles('payments') && (
                  <Route path={PAYMENTS_BASE_ROUTE}>
                    <Suspense fallback={null}>
                      <Payments />
                    </Suspense>
                  </Route>
                )}

                <Route path="/settings/metafields">
                  <Suspense fallback={null}>
                    {validateRoles('full') && (
                      <NuvemAppPage code="metafields" />
                    )}
                  </Suspense>
                </Route>

                {validateRoles('full') && (
                  <Route path="/social/meta">
                    <Suspense fallback={null}>
                      <NuvemAppPage code="social-meta" />
                    </Suspense>
                  </Route>
                )}

                <Route path="/filters">
                  <NuvemAppPage code="filter-settings" />
                </Route>

                <Route exact path={THEMES_ROUTES.contactInfo}>
                  <ContactInfoPage />
                </Route>

                {validateRoles('preferences_checkout') && (
                  <Route exact path={CONFIGURATIONS_ROUTES.settingsCheckout}>
                    <SettingsCheckoutPage />
                  </Route>
                )}

                {validateRoles('preferences_advanced') && (
                  <Route exact path={CONFIGURATIONS_ROUTES.externalCodes}>
                    <Suspense fallback={null}>
                      <ExternalCodes />
                    </Suspense>
                  </Route>
                )}

                <Route path={CONTENT_PAGES_ROUTES.pagesList}>
                  <ContentPages />
                </Route>

                <Route exact path={THEMES_ROUTES.passwordProtected}>
                  <PasswordProtectedPage />
                </Route>

                <Route exact path={THEMES_ROUTES.themes}>
                  <ThemesPage />
                </Route>

                {validateRoles(['full', 'shipping']) && (
                  <Route path={SHIPPING_METHODS_BASE_ROUTE}>
                    <DeliveryMethods />
                  </Route>
                )}

                <Route path={salesChannelsRoutes.salesChannels}>
                  <SalesChannels />
                </Route>

                {validateRoles('domains') && (
                  <Route path={CONFIGURATIONS_ROUTES.domains}>
                    <DomainsPageWithSoftBlock />
                  </Route>
                )}

                <Route path={MENUS_ROUTES.menus}>
                  <Menus />
                </Route>

                <Route path="/account">
                  <Suspense fallback={null}>
                    <Switch>
                      {validateRoles('payments_and_subscriptions') ? (
                        <>
                          <Account />
                          <BillingPlans />
                          <BillingCoupons />
                          <Invoices />
                          <TransactionFees />
                          <Checkout />
                        </>
                      ) : (
                        <>
                          <Account />
                          <Invoices />
                        </>
                      )}
                    </Switch>
                  </Suspense>
                </Route>

                <Route path="/apps">
                  <Suspense fallback={null}>
                    <PartnersApps />
                  </Suspense>
                </Route>

                {hasNuvemPagoRole && (
                  <RouteByCountry
                    allowedCountries={[Country.AR, Country.BR]}
                    path="/nuvempago"
                  >
                    <Suspense fallback={null}>
                      <NuvemAppPage code="nuvempago-commons" />
                    </Suspense>
                  </RouteByCountry>
                )}

                <Route path="/chat">
                  <Suspense fallback={null}>
                    <NuvemAppPage code="nuvemchat" />
                  </Suspense>
                </Route>

                {hasNuvemPagoRole && (
                  <RouteByCountry
                    allowedCountries={[Country.BR]}
                    path="/lending"
                  >
                    <Suspense fallback={null}>
                      <NuvemAppPage code="lending" />
                    </Suspense>
                  </RouteByCountry>
                )}

                {hasAccessToStats && (
                  <Route path={STATS_ROUTES.stats}>
                    <Statistics />
                  </Route>
                )}
                <Route path={CONFIGURATIONS_ROUTES.whatsapp} exact>
                  <Suspense fallback={null}>
                    <WhatsappPage />
                  </Suspense>
                </Route>

                <Route path={infoMessageRoutes.infoMessage} exact>
                  <Suspense fallback={null}>
                    <InfoMessage />
                  </Suspense>
                </Route>

                {validateRoles('i18n') && (
                  <Route path={CONFIGURATIONS_ROUTES.i18n} exact>
                    <Suspense fallback={null}>
                      <I18n />
                    </Suspense>
                  </Route>
                )}

                <Route path={CONFIGURATIONS_ROUTES.users}>
                  <Redirect to={accountRoutes.users} />
                </Route>

                {validateRoles('emails') && (
                  <Route path={CONFIGURATIONS_ROUTES.notifications}>
                    <Suspense fallback={null}>
                      <Notifications />
                    </Suspense>
                  </Route>
                )}

                <Redirect from="*" to={DASHBOARD_BASE_PATH} exact />
              </Switch>
            </AppLayout>
          </Switch>
        </RequiresForcedUpdate>
      </PrivateRoutes>
    </Switch>
  );
}

export default RoutesMobile;
