import dayjs from 'dayjs';
import * as Yup from 'yup';
import { ValidationError } from 'yup';

const emptySelectionCategories = 'categories.required';
const emptySelectionProducts = 'products.required';
const emptyValueError = 'emptyValueError';
const discountMaxError = 'discountMaxError';

export const validationSchema = Yup.object().shape({
  categories: Yup.array().when('scopeType', {
    is: 'categories',
    then: Yup.array().min(1, emptySelectionCategories),
  }),
  products: Yup.array().when('scopeType', {
    is: 'products',
    then: Yup.array().min(1, emptySelectionProducts),
  }),
  progressiveDiscounts: Yup.mixed().when('discountForQuantity', {
    is: true,
    then: Yup.array()
      .of(
        Yup.object({
          quantity: Yup.number()
            .typeError(emptyValueError)
            .integer(emptyValueError)
            .min(1, emptyValueError),
          discount: Yup.number()
            .typeError(emptyValueError)
            .integer(emptyValueError)
            .min(1, emptyValueError)
            .max(100, discountMaxError),
        }),
      )
      .test(function (value) {
        if (!value) return true;
        const quantities = value.map((item) => item.quantity);
        const discounts = value.map((item) => item.discount);

        const findDuplicatesIndexes = (arr) => {
          const seen = new Set();
          const duplicates = new Set();
          arr.forEach((item, i) => {
            const value =
              typeof item === 'object'
                ? JSON.stringify(item, Object.keys(item).sort())
                : item;
            if (seen.has(value)) {
              duplicates.add(i);
            } else {
              seen.add(value);
            }
          });
          return duplicates;
        };

        const duplicatePromotionIndexes = findDuplicatesIndexes(value);
        const duplicateQuantitiesIndexes = findDuplicatesIndexes(quantities);
        const duplicateDiscountsIndexes = findDuplicatesIndexes(discounts);

        duplicatePromotionIndexes.forEach((i) => {
          duplicateQuantitiesIndexes.delete(i);
          duplicateDiscountsIndexes.delete(i);
        });

        const errors: ValidationError[] = [];

        duplicatePromotionIndexes.forEach((index) => {
          errors.push(
            this.createError({
              path: `${this.path}[${index}]`,
              message: 'repeatedProgressiveDiscountError',
            }),
          );
        });

        duplicateQuantitiesIndexes.forEach((index) => {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].quantity`,
              message: 'repeatedValueError',
            }),
          );
        });

        duplicateDiscountsIndexes.forEach((index) => {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].discount`,
              message: 'repeatedValueError',
            }),
          );
        });

        if (errors.length > 0) {
          return new ValidationError(errors);
        }

        return true;
      }),
  }),
  startDate: Yup.string().when('dateType', {
    is: 'limited',
    then: Yup.string().test(
      'formatStartDate',
      'coupons.couponForm.errors.startDate.format',
      (value) => dayjs(value, 'YYYY-MM-DD').isValid(),
    ),
  }),
  endDate: Yup.string().when('dateType', {
    is: 'limited',
    then: Yup.string().test(
      'formatEndDate',
      'coupons.couponForm.errors.endDate.format',
      (value) => dayjs(value, 'YYYY-MM-DD').isValid(),
    ),
  }),
});
