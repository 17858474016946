import React from 'react';
import { Tag, Text } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import { AiIcon } from 'commons/components/GenerateWithAiLink';
import { useModal } from 'commons/hooks';

interface ModalInteractiveListActionProps {
  cta: string;
  onOpen?: () => void;
  onClose?: () => void;
  modalChildren: React.ReactNode;
  aIPoweredAction?: boolean;
  isBeta?: boolean;
}

interface ModalChildProps {
  isShow: boolean;
  onOpen: () => void;
  onClose: () => void;
}

function ModalInteractiveListAction({
  cta,
  onOpen,
  onClose,
  modalChildren,
  aIPoweredAction = false,
  isBeta = false,
}: Readonly<ModalInteractiveListActionProps>) {
  const [isShow, openModal, closeModal] = useModal();
  const handleOnOpen = () => {
    if (onOpen) {
      onOpen();
    }
    openModal();
  };
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    closeModal();
  };

  return (
    <InteractiveList.ButtonItem
      title=""
      showTitle={false}
      iconButton={{
        onClick: handleOnOpen,
      }}
    >
      <Stack align="center" spacing="tight">
        {aIPoweredAction && <AiIcon />}
        <Stack.Item fill>
          <Text color="neutral-textHigh">{cta}</Text>
        </Stack.Item>
        {isBeta && <Tag appearance="primary">Beta</Tag>}
      </Stack>
      {React.isValidElement<ModalChildProps>(modalChildren) &&
        React.cloneElement(modalChildren, {
          isShow,
          onClose: handleOnClose,
        })}
    </InteractiveList.ButtonItem>
  );
}

export default ModalInteractiveListAction;
