import { Box, Chip } from '@nimbus-ds/components';
import { FreeShippingZoneDto } from '@tiendanube/common';
import { SelectOrEditLink } from 'commons/components';
import { useModal } from 'commons/hooks';
import { DeliveryZonesModalAside } from '../../DeliveryZonesModalAside';

interface DeliveryZonesProps {
  zones: FreeShippingZoneDto[];
  onChange: (methods: FreeShippingZoneDto[]) => void;
}

export function DeliveryZones({
  zones,
  onChange,
}: Readonly<DeliveryZonesProps>) {
  const [isShowModal, openModal, closeModal] = useModal();
  const isEmpty = !zones.length;
  const hasChilds = length > 0;

  return (
    <>
      {!isEmpty && (
        <Box display="flex" gap="2" flexWrap="wrap" marginTop="2">
          {zones.map(({ id, description, subzones: { length } }) => {
            const childs = hasChilds ? `(${length})` : '';
            return (
              <Chip
                key={`${id}`}
                text={`${description} ${childs}`}
                removable
                onClick={() => {
                  onChange(zones.filter((zone) => zone.id !== id));
                }}
              />
            );
          })}
        </Box>
      )}
      <Box marginTop="2">
        <SelectOrEditLink isEdit={!isEmpty} onSelect={openModal} />
      </Box>
      <DeliveryZonesModalAside
        isOpen={isShowModal}
        selected={zones}
        onClose={closeModal}
        onChange={onChange}
      />
    </>
  );
}
