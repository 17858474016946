export const DRAFT_ORDERS = 'new-admin-draft-orders';
export const ABANDONED_CARTS = 'new-admin-abandoned-carts';
export const ORDERS_SHOW_CARD_STATISTICS_STATUS =
  'new-admin-orders-show-card-statistics-status';
export const SHIPPING_MULTICD = 'new-admin-multicd';
export const SHIPPING_PRINT_OCA = 'new-admin-print-oca';
export const SHIPPING_LOCATION_TAG = 'new-admin-shipping-location-tag';
export const APP_DEV_EXPERIMENTAL = 'new-admin-app-dev-experimental';
export const PRODUCT_DESCRIPTION_EDITOR_ENABLED =
  'new-admin-product-description-editor-enabled';
export const NEW_ADMIN_ORDER_DETAILS = 'new-admin-order-detail';

export const CATALOG_INVENTORY = 'new-admin-multicd-inventory';
export const CATALOG_LAZY_VARIANTS = 'new-admin-catalog-lazy-variants';
export const CATALOG_ENABLE_INF_STOCK_MODAL =
  'new-admin-catalog-enable-inf-stock-modal';
export const CATALOG_ALERT_NO_WEIGHT_AND_DIMENSIONS =
  'new-admin-catalog-alert-no-weight-and-dimensions';
export const CATALOG_LIGHT_VIDEO_URL_VALIDATION =
  'new-admin-catalog-light-video-url-validation';
export const CATALOG_SHOW_VARIANT_ID = 'new-admin-catalog-show-variant-id';
export const CATALOG_FULL_EVENTS = 'new-admin-catalog-full-events';

export const NEW_ADMIN_METAFIELDS_CUSTOMER_FILTER =
  'new-admin-metafields-customer-filter';
export const NEW_ADMIN_EVENT_CATEGORIES = 'new-admin-catalog-event-categories';
export const NEW_ADMIN_EVENT_EDIT_VARIANT_STOCK =
  'new-admin-catalog-event-edit-variant-stock';

export const NUVEMPAGO_ENABLED = 'nuvempago';

export const NUVEMENVIO_ADMIN_BLOCK = 'nuvem-envio-admin-blocking';

export const MIGRATION_PRODUCT = 'new-admin-web-product';
export const MIGRATION_PRODUCTS = 'new-admin-web-products';
export const MIGRATION_PRODUCTS_FORCE = 'new-admin-web-products-all';
export const MIGRATION_ORDERS = 'new-admin-web-orders';
export const MIGRATION_ORDER = 'new-admin-web-order';
export const MIGRATION_ABANDONED_CARTS = 'new-admin-web-abandoned_list';
export const MIGRATION_ORDERS_EXPORT = 'new-admin-web-orders_download';
export const MIGRATION_SOCIAL_NETWORKS = 'new-admin-web-social_networks';
export const MIGRATION_ORDERS_EXPORT_FORCE = 'new-admin-web-orders-export';
export const MIGRATION_ABANDONED_CARTS_FORCE = 'new-admin-web-abandoned-carts';
export const MIGRATION_ORDER_DETAIL_FORCE = 'new-admin-web-order-detail';
export const MIGRATION_ORDERS_LIST_FORCE = 'new-admin-web-order-list';

export const MIGRATION_ENABLE_ALL_MIGRATED_PAGES =
  'new-admin-enable-all-migrated-pages';
export const PAY_ORDER_NEW_CHECKOUT_TAG = 'billing-new-checkout-pay-order';
export const TRANSACTION_FEES_NEW_CHECKOUT_TAG =
  'billing-new-checkout-transaction-fees';

//TAGS TO FORCE REDIRECT TO OLD ADMIN
export const MIGRATION_OLD_COUPONS = 'new-admin-old-discount_list';
export const MIGRATION_OLD_DRAFT_ORDERS = 'new-admin-old-draft_orders';
export const MIGRATION_OLD_CUSTOMERS = 'new-admin-old-customers';
export const MIGRATION_OLD_MESSAGES = 'new-admin-old-contacts';
export const MIGRATION_OLD_CATEGORIES = 'new-admin-old-categories';
export const MIGRATION_OLD_PRODUCTS_IMPORT = 'new-admin-old-product_import';
export const MIGRATION_OLD_PRODUCT_ORDER = 'new-admin-old-product_order';
export const MIGRATION_OLD_PASSWORD_PROTECTED =
  'new-admin-old-password_protected';
export const MIGRATION_OLD_MARKETING_APPS = 'new-admin-old-marketing_apps';
export const MIGRATION_OLD_SALES_CHANNELS_APPS =
  'new-admin-old-sales_channel_apps';
export const MIGRATION_OLD_CONTENT_PAGES = 'new-admin-old-pages';
export const MIGRATION_OLD_I18N = 'new-admin-old-i18n';
export const MIGRATION_OLD_MENUS = 'new-admin-old-navigation';
export const MIGRATION_OLD_FREE_SHIPPING = 'new-admin-old-get_free_shipping';
export const MIGRATION_OLD_PROMOTIONS = 'new-admin-old-promotions_list';
export const MIGRATION_OLD_CONTENT_SETTINGS_CHECKOUT =
  'new-admin-old-preferences_checkout';
export const MIGRATION_OLD_USERS = 'new-admin-old-users';
export const MIGRATION_OLD_PREFERENCES_ADVANCED =
  'new-admin-old-preferences_advanced';
export const MIGRATION_OLD_NOTIFICATIONS = 'new-admin-old-notifications';
export const MIGRATION_OLD_MARKETPLACES_APPS = 'new-admin-old-meli_apps';
export const MIGRATION_OLD_DASHBOARD = 'new-admin-old-home';
export const MIGRATION_OLD_SEO_REDIRECTS = 'new-admin-old-seo_redirects';
export const MIGRATION_OLD_WHATSAPP = 'new-admin-old-whatsapp';
export const MIGRATION_OLD_THEMES = 'new-admin-old-themes';
export const MIGRATION_OLD_INFO_MESSAGE = 'new-admin-old-emergency';
export const MIGRATION_OLD_DOMAINS = 'new-admin-old-domains';
export const MIGRATION_OLD_APPS = 'new-admin-old-apps';
export const MIGRATION_OLD_FACEBOOK = 'new-admin-old-facebook';
export const MIGRATION_OLD_INSTAGRAM = 'new-admin-old-instagram';
export const MIGRATION_OLD_PAYMENTS = 'new-admin-old-payments';

//TAGS FROM DOMAINS
export const SOCIAL_GOOGLE_ADMIN = 'new-google-admin';
export const SOCIAL_TIKTOK_ADMIN = 'new-tiktok-admin';
export const ENABLED_HAS_NO_CATEGORIES = 'api-enable-has-no-categories';
export const SUNSET_MELI_SYNC = 'sunset-meli-sync';
export const ORDERS_ALLOW_PRODUCT_NAME_FILTER = 'allow-product-name-filter';
export const USER_PASSWORD_CHANGE_REQUIRED_ENABLED =
  'account-takeover-user-passwords-audit-enabled';
export const TOTAL_PAID_ORDER_DETAIL = 'new-admin-total-paid-order-detail';
export const DEFAULT_LAST_30_DAYS = 'default-last-30-days';
export const AUTO_SETTING_NUVEM_PAGO = 'nuvempago-new-cohort-activated';
export const NEW_ADMIN_MULTIPLOS_ENVIOS = 'new-admin-multiplos-envios';
export const MUST_REFRESH_MERCADOPAGO_TOKEN = 'must-refresh-mercadopago-token';
export const PAGSEGURO_REVOKED_PERMISSIONS = 'pagseguro-revoked-permissions';
export const MERCADOPAGO_NOT_VERIFIED = 'mercadopago-not-verified';
export const MULTI_LOCATION = 'new-admin-multicd-multi-location';
export const MANUAL_TX_FEE_PAYMENT_TAG =
  'billing-manual-tx-fee-until-next-month';
export const QUITO_FACEBOOK_V2 = 'quito-fbe-v2';
export const SOCIAL_DISABLED_TRACKING_CODES = 'social-disable-tracking-codes';
export const SMART_PAYMENT_ADMIN = 'smart-payment-admin';
export const ONLINE_CURRENT_THEME_SURVEY = 'online-current-theme-survey';
export const ZENDESK_LIVE_CHAT_WIDGET_TAG = 'zendesk-live-chat-widget';
export const MARKETING_AUTOMATION_ENABLED = 'new-admin-marketing-automation';
export const PHISHING_SUSPECT_TAG = 'phishing-suspect';
export const BE_APP_SUBSCRIPTION_ENABLED =
  'billing-engine-app-subscription-enabled';
export const UPDATE_APP_NOTIFICATIONS_ALERT_TAG =
  'update-app-notifications-alert';
export const EXCLUDE_NEW_PERMISSIONS_TAG = 'new-admin-exclude-new-permissions';
export const SHOW_DELETED_COUPONS_TAG = 'new-admin-show-deleted-coupons';
export const UPSELL_FLOW_AB_TEST_TAG = 'new-admin-upsell-flow-ab-test-a';
export const NON_FREEMIUM_PLANS_PAGE_TAG = 'billing-non-freemium-plans-page';
export const AI_PRODUCT_IMAGE_EDIT_ENABLED = 'ai-product-image-edit-enabled';
export const AI_PRODUCT_IMAGE_SMART_ERASE_ENABLED =
  'ai-product-image-smart-erase-enabled';
export const AI_GOOGLE_SHOPPING_CATEGORY_SUGGESTED_ENABLED =
  'ai-google-shopping-category-suggested-enabled';
export const CATALOG_EXTENDED_CATEGORY_DESCRIPTION =
  'new-admin-catalog-extended-category-description';
export const PROMOTIONS_DISCOUNT_COMBINATION_TAG =
  'new-admin-promotions-discount-combination';

// Tags for Blog
export const BLOG_ENABLED = 'blog-enabled';

export const POS_MOBILE_ENABLED = 'new-admin-pos-mobile-enabled';
