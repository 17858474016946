import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Select,
  InterfaceSelectOptionGroup,
  InterfaceNameValue,
} from '@tiendanube/components';
import { useCategories } from 'domains/Catalog/Categories/hooks';

interface OnChangeValue {
  name: string;
  value: string;
}

interface CategoriesProps {
  value: string;
  onChange: ({ name, value }: OnChangeValue) => void;
}
function Categories({ value, onChange }: CategoriesProps): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);

  const { categories } = useCategories();

  const options = [
    { value: '', label: t(`products.filters.categoryId.all`) },
    {
      value: 'has_no_categories',
      label: t(`products.filters.categoryId.hasNoCategories`),
    },
  ];

  const labelOptions: InterfaceSelectOptionGroup[] = options.concat(
    categories.map((category) => ({
      value: category.id,
      label: category.title,
    })),
  );

  const handleChange = (data: InterfaceNameValue) => {
    onChange(data as OnChangeValue);
  };

  return (
    <Select
      label={t('products.filter.categoryId')}
      name="categoryId"
      value={value}
      options={labelOptions}
      onChange={handleChange}
    />
  );
}

export default Categories;
